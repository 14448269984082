export default {
    "name": "UserAccountConfigurationSettings",
    "kind": "HoudiniQuery",
    "hash": "e3c55a80b983890b11a2c8e1098a2100afa9772bb7a4e5ebdf1b9d31c9f8c596",

    "raw": `query UserAccountConfigurationSettings {
  changePasswordPermission: getGlobalSetting(
    lookup: {name: "Administrators can set other users' passwords", settingType: HIDDEN, category: "Security", defaultValue: "False"}
  ) {
    value
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "changePasswordPermission": {
                "type": "SettingResult",
                "keyRaw": "changePasswordPermission(lookup: {name: \"Administrators can set other users' passwords\", settingType: HIDDEN, category: \"Security\", defaultValue: \"False\"})",

                "selection": {
                    "fields": {
                        "value": {
                            "type": "String",
                            "keyRaw": "value",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=55e951bf0de3c692d518f0382d6a39f1b99938b4102db7dfba9e5d2b45270e44";