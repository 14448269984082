import type { AppContext, Mediator } from 'types/common'
import type { WritableDeep } from 'type-fest'
import {
	DocumentStatus$options,
	OutcomeOrNone$options,
	WoGetOptionRestrictions$result,
	WoGetOptionRestrictionsStore,
	WorkOrder$result,
	WorkOrderData$result,
	type GetDocumentsByAssociation$result,
	GetDocumentsByAssociationStore,
	type AnalysisOptionChoices$result,
	type ChangeEventType$options,
} from '$houdini'
import type { MetaSampleAttachment, Sample, WorkOrder } from '../work-order'
import type { Writable } from 'svelte/store'

import component from './Edit.svelte'
import { graphql, DocumentStatus, GetOptionRestrictionInput, DocumentLookupInput } from '$houdini'
import showErrorAndRedirect from 'utility/show-error-and-redirect'
import makeSettingValueStore from 'stores/setting-value'
import { stringToBoolean } from '@isoftdata/utility-string'
import { v4 as uuid } from '@lukeed/uuid'
import { makeGroupedEntityLoader } from 'utility/grouped-entity-loader'
import { getSession } from 'stores/session'
import { writable } from 'svelte/store'
import { unique } from 'utility/unique'
import { dequal } from 'dequal/lite'
import userLocalWritable from '@isoftdata/svelte-store-user-local-writable'

export default ({ mediator, stateRouter, hasPermission, fileBaseUrl, i18next: { t: translate } }: AppContext) => {
	stateRouter.addState({
		name: 'app.work-order.edit',
		route: 'edit/:workOrderId',
		querystringParameters: ['workOrderId'],
		defaultParameters: {
			workOrderId: null,
			// Selected tab in the detail view - samples or changelog
			selectedTab: 'SAMPLES',
		},
		template: component,
		canLeaveState(svelte) {
			//@ts-expect-error
			return svelte.canLeaveState()
		},
		async resolve(_data, parameters) {
			const session = getSession()
			let plantId = session.siteId

			const workOrderId = parameters.workOrderId && parameters.workOrderId !== 'null' ? parameters.workOrderId : null

			let workOrder: WritableDeep<WorkOrder$result['workOrder']>

			const { data: woTypePlantData } = await plantWoTypeQuery.fetch({
				variables: {
					pagination: {
						pageSize: 0,
					},
				},
				policy: 'CacheOrNetwork',
			})

			if (!woTypePlantData) {
				throw showErrorAndRedirect(mediator, 'Error Loading Work Order Data', 'No data was returned from the server')
			}

			const workOrderTypes = woTypePlantData.workOrderTypes.data
			const plants = woTypePlantData.plants.data.filter(({ id }) => hasPermission('WORK_ORDERS_CAN_VIEW', id))

			const lastWoTypeStore = userLocalWritable<number>(session.userAccountId, 'lastWorkOrderType')

			// Load the WO
			if (workOrderId) {
				const { data } = await woQuery.fetch({ variables: { workOrderId } })
				if (!data) {
					throw showErrorAndRedirect(mediator, 'Error Loading Work Order', `No data was returned from the server given WO #${workOrderId}`)
				}
				workOrder = data.workOrder
				plantId = workOrder.plant.id
			} else {
				const lastWoType = lastWoTypeStore.get()
				const workOrderType = lastWoType ? workOrderTypes.find(type => type.id === lastWoType) : workOrderTypes[0]
				workOrder = {
					assignedToGroup: null,
					dateCreated: new Date().toISOString(),
					documentStatus: DocumentStatus.OPEN,
					due: null,
					favorite: false,
					id: 0,
					instructions: '',
					internalNotes: '',
					scheduled: new Date().toISOString(),
					title: '',
					verificationDue: null,
					verifiedOn: null,
					plant: plants.find(plant => plant.id === plantId)!,
					productBatch: null,
					samples: [],
					verifiedByUser: null,
					workOrderType: workOrderType ?? workOrderTypes[0],
				}
			}

			// Load the data for dropdowns, etc.
			const [{ data: bigData }, { data: settingsData }] = await Promise.all([
				dataQuery.fetch({
					variables: {
						pagination: {
							pageSize: 0,
						},
						productBatchesFilter: {
							plantIds: [plantId],
							statuses: ['ACTIVE'],
						},
						groupsFilter: {
							workerGroup: true,
						},
						analysesFilter: {
							plantIds: [plantId],
							type: 'TESTING',
						},
						productsFilter: {
							plantIds: [plantId],
						},
						locationsFilter: {
							plantIds: [plantId],
							testableOnly: true,
						},
						userFilter: {
							authorizedPlants: {
								ids: [plantId],
							},
						},
						viewAsPlantId: plantId.toString(),
					},
					// This data probably won't change that often. Go ahead and cache it.
					policy: 'CacheOrNetwork',
				}),
				settingsQuery.fetch(),
			])

			if (!bigData || !settingsData?.session?.user) {
				throw showErrorAndRedirect(mediator, 'Error Loading Work Order Data', 'No data was returned from the server')
			}

			const workOrderStatuses: Array<DocumentStatus$options> = Object.values(DocumentStatus)

			// Set the value to the one from the list so it properly shows as selected on load
			workOrder.workOrderType = woTypePlantData.workOrderTypes.data.find(type => type.id === workOrder.workOrderType.id)!
			workOrder.productBatch = workOrder.productBatch ? bigData.productBatches.data.find(batch => batch.id === workOrder.productBatch?.id)! : null

			function makeFakeSampleValues(options: Array<AnalysisOption>, sampleId = 0): WorkOrder$result['workOrder']['samples'][number]['sampleValues'] {
				return options.map(option => {
					return {
						id: 0,
						sampleId,
						result: '',
						resultStatus: 'NOT_CALCULATED' as const,
						analysisOption: option,
						defaultValue: null,
						filledOut: '',
						lastModified: null,
						createdInvestigationId: null,
					}
				})
			}
			// Get a map of options to show for each analysis - note that this may be different than the list of currently-active options
			const optionsPerAnalysisMap = workOrder.samples.reduce((map: Record<number, Record<number, AnalysisOption>>, sample) => {
				if (!map[sample.analysis.id]) {
					map[sample.analysis.id] = {}
				}
				const analysis = bigData.analyses.data.find(analysis => analysis.id === sample.analysis.id)
				if (analysis) {
					analysis.options.forEach(option => {
						if (option.active && !map[sample.analysis.id][option.id]) {
							map[sample.analysis.id][option.id] = option
						}
					})
				}
				sample.sampleValues.forEach(sv => {
					if (!map[sample.analysis.id][sv.analysisOption.id]) {
						map[sample.analysis.id][sv.analysisOption.id] = sv.analysisOption
					}
				})
				return map
			}, {})
			// Use the map of "visible" options to ensure that there are sampleValues for each option
			const computedWorkOrder: WorkOrder = {
				...workOrder,
				samples: workOrder.samples.map(sample => {
					const options = Object.values(optionsPerAnalysisMap[sample.analysis.id])
					if (sample.sampleValues.length !== options.length) {
						// Need to create "Fake" sample values for any absent/additional options
						const sampleValuesMap = new Map(makeFakeSampleValues(options, sample.id).map(sv => [sv.analysisOption.id, sv]))
						sample.sampleValues.forEach(sv => sampleValuesMap.set(sv.analysisOption.id, sv))
						sample.sampleValues = Array.from(sampleValuesMap.values()).sort((a, b) => a.analysisOption.rank - b.analysisOption.rank)
					} else {
						// womp womp can't sort sampleValues by option rank in the api
						sample.sampleValues.sort((a, b) => a.analysisOption.rank - b.analysisOption.rank)
					}
					const sampleUuid = uuid()
					return {
						...sample,
						uuid: sampleUuid,
						attachments: sample.attachments.map(
							(attachment): MetaSampleAttachment => ({
								File: undefined,
								sampleId: sample.id,
								sampleUuid,
								mimeType: attachment.file.mimeType,
								name: attachment.file.name,
								path: `${fileBaseUrl}${attachment.file.path}`,
								public: attachment.public,
								rank: attachment.rank,
								size: attachment.file.size,
								uuid: uuid(),
								fileId: attachment.fileId,
							})
						),
					}
				}),
			}

			const analysisPrintQuantities: Record<number, { 'Work Order Tags': number; 'Work Order Testing Tags': number }> = bigData.analyses.data.reduce((acc, analysis) => {
				acc[analysis.id] = {
					'Work Order Tags': analysis.sampleTagPrintQuantity,
					'Work Order Testing Tags': analysis.testingTagPrintQuantity,
				}
				return acc
			}, {})

			const { restrictionsInput, documentInput } = workOrder.samples.reduce(
				(acc, sample) => {
					sample.sampleValues.forEach(sv => {
						acc.restrictionsInput.push({
							analysisOptionId: sv.analysisOption.id,
							plantId: sample.plant?.id ?? plantId,
							productId: sample.product?.id ?? null,
							locationId: sample.location?.id ?? null,
						})
						acc.documentInput.push({
							analysisId: sample.analysis.id,
							analysisOptionId: sv.analysisOption.id,
							plantId: sample.plant?.id ?? plantId,
							productId: sample.product?.id ?? null,
							severityClassId: sample.location?.severityClass?.id ?? null,
						})
					})
					return acc
				},
				{ restrictionsInput: new Array<GetOptionRestrictionInput>(), documentInput: new Array<DocumentLookupInput>() }
			)

			// Keep these as two separate query stores so we can fetch them independently later.
			const [{ data: restrictionsData }, { data: documentData }] = await Promise.all([
				restrictionsQuery.fetch({
					variables: {
						getOptionRestrictionsInput: unique(restrictionsInput, dequal),
					},
				}),
				documentsQuery.fetch({
					variables: {
						lookups: unique(documentInput, dequal),
					},
				}),
			])

			const changelogStatuses: Array<ChangeEventType$options> = []
			if (settingsData.session.user.changeLogShowDeleted.value) {
				changelogStatuses.push('DELETE')
			}
			if (settingsData.session.user.changeLogShowCreated.value) {
				changelogStatuses.push('INSERT')
			}
			if (settingsData.session.user.changeLogShowUpdated.value) {
				changelogStatuses.push('UPDATE')
			}

			return {
				selectedTab: parameters.selectedTab,
				lastWorkOrderType: lastWoTypeStore,
				workOrder: computedWorkOrder,
				initialDocumentStatus: computedWorkOrder.documentStatus,
				initialSampleStatuses: computedWorkOrder.samples.reduce((map: Record<string, DocumentStatus$options>, sample) => {
					map[sample.uuid] = sample.status
					return map
				}, {}),
				restrictionTree: new RestrictionTree(restrictionsData, mediator, translate),
				documentTree: new DocumentTree(documentData, mediator, translate),
				initialWoStatus: workOrder.documentStatus,
				intitialSampleStatuses: workOrder.samples.reduce((map: Record<number, DocumentStatus$options>, sample) => {
					map[sample.id] = sample.status
					return map
				}, {}),
				workOrderStatuses,
				workOrderTypes,
				plants,
				workerGroups: bigData.groups.data,
				analysesLoader: makeGroupedEntityLoader(bigData.analyses.data, workOrder.plant.id, getAnalysesForPlant),
				productsLoader: makeGroupedEntityLoader(bigData.products.data, workOrder.plant.id, getProductsForPlant),
				productBatchesLoader: makeGroupedEntityLoader(bigData.productBatches.data, plantId, getProductBatchesForPlant),
				locationsLoader: makeGroupedEntityLoader(bigData.locations.data, workOrder.plant.id, getLocationsForPlant),
				choicesLoader: makeGroupedEntityLoader<AnalysisOptionChoice>([], 0, getChoicesForOption),
				analysisPrintQuantities,
				userAccounts: bigData.userAccounts.data,
				changelogStatuses,
				samplesPerPage: parseInt(settingsData.session.user.samplesPerPage.value, 10),
				changeLogShowHistoricalValues: stringToBoolean(settingsData.session.user.changeLogShowHistoricalValues.value),
				showOptions: makeSettingValueStore<boolean>({
					category: 'Sampling',
					settingType: 'INTERFACE_HISTORY',
					name: 'Show analysis options and values in sample detail',
					scope: 'USER',
					initialValue: stringToBoolean(settingsData.session.user.showOptions.value),
				}),
				showCollectionDetail: makeSettingValueStore<boolean>({
					category: 'Sampling',
					settingType: 'INTERFACE_HISTORY',
					name: 'Show collection information in sample detail',
					scope: 'USER',
					initialValue: stringToBoolean(settingsData.session.user.showCollectionDetail.value),
				}),
				showTestingDetail: makeSettingValueStore<boolean>({
					category: 'Sampling',
					settingType: 'INTERFACE_HISTORY',
					name: 'Show testing information in sample detail',
					scope: 'USER',
					initialValue: stringToBoolean(settingsData.session.user.showTestingDetail.value),
				}),
				showModifiedIcons: makeSettingValueStore<boolean>({
					category: 'Sampling',
					settingType: 'INTERFACE_HISTORY',
					name: 'Show icon for any values modified from their initial version',
					scope: 'USER',
					initialValue: stringToBoolean(settingsData.session.user.showModifiedIcons.value),
				}),
				showWorkOrderDetail: makeSettingValueStore<boolean>({
					category: 'Sampling',
					settingType: 'INTERFACE_HISTORY',
					name: 'Show work order information in sample detail',
					scope: 'USER',
					initialValue: stringToBoolean(settingsData.session.user.showWorkOrderDetail.value),
				}),
				showOnlyApplicableThresholds: makeSettingValueStore<boolean>({
					category: 'Work Orders',
					settingType: 'INTERFACE_HISTORY',
					name: 'Test Thresholds: Show only applicable thresholds',
					scope: 'USER',
					initialValue: stringToBoolean(settingsData.session.user.showOnlyApplicableThresholds.value),
				}),
				allowShowThresholdsTable: stringToBoolean(settingsData.allowShowThresholdsTable.value),
			}
		},
	})
}

export type AnalysisOption = WorkOrderData$result['analyses']['data'][number]['options'][number]
export type AnalysisOptionChoice = AnalysisOptionChoices$result['analysisOptionChoices']['data'][number]

async function getAnalysesForPlant(plantId: number) {
	const { data } = await analysesQuery.fetch({
		variables: {
			analysesFilter: {
				plantIds: [plantId],
			},
			pagination: {
				pageSize: 0,
			},
			viewAsPlantId: plantId.toString(),
		},
	})
	if (!data) {
		throw new Error('No data was returned from the server')
	}
	return data.analyses.data
}

async function getLocationsForPlant(plantId: number) {
	const { data } = await locationsQuery.fetch({
		variables: {
			locationsFilter: {
				plantIds: [plantId],
			},
			pagination: {
				pageSize: 0,
			},
		},
	})
	if (!data) {
		throw new Error('No data was returned from the server')
	}
	return data.locations.data
}

async function getProductsForPlant(plantId: number) {
	const { data } = await productsQuery.fetch({
		variables: {
			productsFilter: {
				plantIds: [plantId],
			},
			pagination: {
				pageSize: 0,
			},
		},
	})
	if (!data) {
		throw new Error('No data was returned from the server')
	}
	return data.products.data
}

async function getProductBatchesForPlant(plantId: number) {
	const { data } = await productBatchesQuery.fetch({
		variables: {
			productBatchesFilter: {
				plantIds: [plantId],
				statuses: ['ACTIVE'],
			},
			pagination: {
				pageSize: 0,
			},
		},
	})
	if (!data) {
		throw new Error('No data was returned from the server')
	}
	return data.productBatches.data.filter(batch => batch.product)
}

async function getChoicesForOption(optionId: number): Promise<AnalysisOptionChoice[]> {
	// Fetch choices for any/all batches; the OptionValueInput will filter them out as necessary
	const { data } = await choicesQuery.fetch({
		variables: {
			filter: {
				optionId,
			},
			pagination: {
				pageSize: 0,
			},
		},
	})
	if (!data) {
		throw new Error('No data was returned from the server')
	}
	return data.analysisOptionChoices.data
}

const woQuery = graphql(`
	query WorkOrder($workOrderId: ID!) {
		workOrder(id: $workOrderId) {
			assignedToGroup {
				id
				name
			}
			dateCreated
			documentStatus
			due
			favorite
			id
			instructions
			internalNotes
			scheduled
			title
			verificationDue
			verifiedOn
			plant {
				id
				code
				name
			}
			productBatch {
				id
				name
				product {
					...WoProductData
				}
				location {
					...WoLocationData
				}
			}
			samples {
				id
				plant {
					id
					code
					name
				}
				analysis {
					id
					name
					requireAuthentication
				}
				product {
					...WoProductData
				}
				location {
					...WoLocationData
				}
				testingComments: findings
				samplingComments: comments
				status
				tagNumber
				scheduled
				due
				performed
				collectedBy {
					id
					fullName
				} # Performed by
				incubationBegan # Testing Began
				incubationEnded # Testing Ended
				investigationId
				platesReadBy {
					id
					fullName
				} # Tested by
				sampleValues {
					id
					sampleId
					result
					resultStatus
					filledOut
					defaultValue
					lastModified
					createdInvestigationId
					analysisOption {
						...WoAnalysisOptionData
					}
				}
				attachments {
					fileId
					public
					rank
					file {
						created
						mimeType
						name
						path
						size
					}
				}
			}
			verifiedByUser {
				id
				fullName
			}
			workOrderType {
				...WorkOrderTypeData
			}
		}
	}
`)

const plantWoTypeQuery = graphql(`
	query WorkOrderTypesAndPlants($pagination: PaginatedInput) {
		workOrderTypes(pagination: $pagination) {
			data {
				...WorkOrderTypeData
			}
		}
		plants(pagination: $pagination) {
			data {
				id
				code
				name
			}
		}
	}
`)

const dataQuery = graphql(`
	query WorkOrderData(
		$pagination: PaginatedInput
		$productBatchesFilter: ProductBatchFilter
		$groupsFilter: GroupFilter
		$analysesFilter: AnalysisFilter
		$productsFilter: ProductFilter
		$locationsFilter: LocationFilter
		$viewAsPlantId: ID!
		$userFilter: UserFilter
	) {
		productBatches(pagination: $pagination, filter: $productBatchesFilter) {
			data {
				id
				name
				product {
					...WoProductData
				}
				location {
					...WoLocationData
				}
				plantId
				status
				end
				start
			}
		}
		groups(pagination: $pagination, filter: $groupsFilter) {
			data {
				id
				name
			}
		}
		analyses(filter: $analysesFilter, pagination: $pagination) {
			data {
				...WoAnalysisData
				sampleTagPrintQuantity(viewAsPlantId: $viewAsPlantId)
				testingTagPrintQuantity(viewAsPlantId: $viewAsPlantId)
			}
		}
		products(filter: $productsFilter, pagination: $pagination) {
			data {
				...WoProductData
			}
		}
		locations(filter: $locationsFilter, pagination: $pagination) {
			data {
				...WoLocationData
			}
		}
		userAccounts(filter: $userFilter, pagination: $pagination) {
			data {
				id
				name
			}
		}
	}
`)

// separate from dataQuery to avoid caching
const settingsQuery = graphql(`
	query WorkOrderSettings {
		session {
			user {
				showOptions: getUserSetting(lookup: { category: "Sampling", settingType: INTERFACE_HISTORY, name: "Show analysis options and values in sample detail", defaultValue: "True" }) {
					value
				}
				showCollectionDetail: getUserSetting(lookup: { category: "Sampling", settingType: INTERFACE_HISTORY, name: "Show collection information in sample detail", defaultValue: "True" }) {
					value
				}
				showTestingDetail: getUserSetting(lookup: { category: "Sampling", settingType: INTERFACE_HISTORY, name: "Show testing information in sample detail", defaultValue: "True" }) {
					value
				}
				showWorkOrderDetail: getUserSetting(lookup: { category: "Sampling", settingType: INTERFACE_HISTORY, name: "Show work order information in sample detail", defaultValue: "False" }) {
					value
				}
				showModifiedIcons: getUserSetting(
					lookup: { category: "Sampling", settingType: INTERFACE_HISTORY, name: "Show icon for any values modified from their initial version", defaultValue: "True" }
				) {
					value
				}
				changeLogShowDeleted: getUserSetting(lookup: { category: "Work Orders", settingType: INTERFACE_HISTORY, name: "Change log: show deleted records", defaultValue: "True" }) {
					value
				}
				changeLogShowCreated: getUserSetting(lookup: { category: "Work Orders", settingType: INTERFACE_HISTORY, name: "Change log: show inserted records", defaultValue: "True" }) {
					value
				}
				changeLogShowUpdated: getUserSetting(lookup: { category: "Work Orders", settingType: INTERFACE_HISTORY, name: "Change log: show updated records", defaultValue: "True" }) {
					value
				}
				samplesPerPage: getUserSetting(lookup: { category: "Work Orders", settingType: INTERFACE_HISTORY, name: "Work order history per page", defaultValue: "100" }) {
					value
				}
				changeLogShowHistoricalValues: getUserSetting(
					lookup: { category: "Work Orders", settingType: INTERFACE_HISTORY, name: "Change log: show historical unmodified fields", defaultValue: "False" }
				) {
					value
				}
				showOnlyApplicableThresholds: getUserSetting(
					lookup: { category: "Work Orders", name: "Test Thresholds: Show only applicable thresholds", defaultValue: "True", settingType: INTERFACE_HISTORY }
				) {
					value
				}
			}
		}
		allowShowThresholdsTable: getGlobalSetting(lookup: { category: "Scanner", name: "showthresholds", settingType: INTERFACE_PREFERENCE, defaultValue: "False" }) {
			value
		}
	}
`)

const restrictionsQuery = graphql(`
	query WoGetOptionRestrictions($getOptionRestrictionsInput: [GetOptionRestrictionInput!]!) {
		getOptionRestrictions(options: $getOptionRestrictionsInput) {
			analysisOptionId
			locationId
			plantId
			productId
			restriction
		}
	}
`)

const documentsQuery = graphql(`
	query GetDocumentsByAssociation($lookups: [DocumentLookupInput!]!) {
		getDocumentsByAssociation(lookups: $lookups) {
			lookup {
				analysisId
				analysisOptionId
				plantId
				productId
				severityClassId
			}
			version {
				file {
					mimeType
					path
					name
				}
			}
		}
	}
`)

const analysesQuery = graphql(`
	query WoAnalyses($analysesFilter: AnalysisFilter, $pagination: PaginatedInput, $viewAsPlantId: ID!) {
		analyses(filter: $analysesFilter, pagination: $pagination) {
			data {
				...WoAnalysisData
				sampleTagPrintQuantity(viewAsPlantId: $viewAsPlantId)
				testingTagPrintQuantity(viewAsPlantId: $viewAsPlantId)
			}
		}
	}
`)

const locationsQuery = graphql(`
	query WoLocations($locationsFilter: LocationFilter, $pagination: PaginatedInput) {
		locations(filter: $locationsFilter, pagination: $pagination) {
			data {
				...WoLocationData
			}
		}
	}
`)

const productsQuery = graphql(`
	query WoProducts($productsFilter: ProductFilter, $pagination: PaginatedInput) {
		products(filter: $productsFilter, pagination: $pagination) {
			data {
				...WoProductData
			}
		}
	}
`)

const productBatchesQuery = graphql(`
	query WoProductBatches($productBatchesFilter: ProductBatchFilter, $pagination: PaginatedInput) {
		productBatches(filter: $productBatchesFilter, pagination: $pagination) {
			data {
				...WoProductBatchData
			}
		}
	}
`)

const choicesQuery = graphql(`
	query AnalysisOptionChoices($filter: AnalysisOptionChoiceFilter, $pagination: PaginatedInput) {
		analysisOptionChoices(filter: $filter, pagination: $pagination) {
			data {
				active
				choice
				constraint
				boundaryType
				severityClassId
				plantId
				productId
				productBatchId
				requiredAnalysisOptionId
				requiredConstraint
				requiredChoice
				id
				analysisOptionId
				severityClass {
					id
					default
				}
			}
		}
	}
`)

graphql(`
	fragment WoLocationData on Location {
		id
		location
		description
		plantId
		severityClass {
			id
			default
		}
		attachmentCount(fileType: IMAGE)
	}
`)

graphql(`
	fragment WoProductData on Product {
		id
		name
		productType
		attachmentCount(fileType: IMAGE)
	}
`)
// used to load analyses list, not the analysis for a specific sample
graphql(`
	fragment WoAnalysisData on Analysis {
		id
		name
		requireAuthentication
		options {
			...WoAnalysisOptionData
		}
	}
`)
// used on workOrder.sample.sampleValues.analysisOption and in WoAnalysisData
// Not fetching requiredToPerform/Close since that's all handled by restrictions
graphql(`
	fragment WoAnalysisOptionData on AnalysisOption {
		active
		id
		option
		valueType
		thresholdType
		rank
		defaultType
		defaultValue
		unit
	}
`)

graphql(`
	fragment WorkOrderTypeData on WorkOrderType {
		daysTillDue
		daysTillVerificationDue
		defaultAnalysisId
		defaultGroupId
		defaultReport
		defaultVerificationGroupId
		id
		inUseAtPlantIDs
		name
		productBatchRequired
		showDue
		showLocation
		showLocationDescription
		showProduct
		showSamplingDetail
		showTestingDetail
		titleRequired
		verificationRequired
		visibleGroupId
	}
`)

graphql(`
	fragment WoProductBatchData on ProductBatch {
		id
		name
		product {
			...WoProductData
		}
		location {
			...WoLocationData
		}
		plantId
		status
		end
		start
	}
`)
export type DocumentVersion = GetDocumentsByAssociation$result['getDocumentsByAssociation'][number]['version']
/** plantId -> analysisId -> productId -> severityClassId -> analysisOptionId */
export type DocumentTreeMap = Record<number, Record<number, Record<number, Record<number, Record<number, DocumentVersion>>>>>
export class DocumentTree {
	public constructor(documents: GetDocumentsByAssociation$result | null, mediator: Mediator, translate: AppContext['i18next']['t']) {
		this.documentTree = {}
		this.documentTreeStore = writable(this.documentTree)
		this.#set(documents?.getDocumentsByAssociation ?? [])
		this.subscribe = this.documentTreeStore.subscribe
		this.queryStore = new GetDocumentsByAssociationStore()
		this.mediator = mediator
		this.translate = translate
	}

	private documentTree: DocumentTreeMap
	private documentTreeStore: Writable<DocumentTreeMap>
	private queryStore: GetDocumentsByAssociationStore
	private mediator: Mediator
	private translate: AppContext['i18next']['t']

	public subscribe: Writable<DocumentTreeMap>['subscribe']

	/**
	 * Get the restriction for a given analysis option, plant, product, and location from the tree.
	 * If the input is not found in the tree, returns 'NONE', so make sure you called fetch first if you want to ensure you have the latest data.
	 */
	public get(options: DocumentLookupInput): DocumentVersion | null {
		return this.#get(options)
	}

	#get(options: DocumentLookupInput): DocumentVersion | null {
		const { analysisId, analysisOptionId, plantId, productId, severityClassId } = options
		return this.documentTree[plantId ?? -1]?.[analysisId ?? -1]?.[productId ?? -1]?.[severityClassId ?? -1]?.[analysisOptionId ?? -1] ?? null
	}

	#set(values: GetDocumentsByAssociation$result['getDocumentsByAssociation']) {
		values.forEach(({ lookup: { analysisId, analysisOptionId, plantId, productId, severityClassId }, version }) => {
			plantId ??= -1
			analysisId ??= -1
			productId ??= -1
			severityClassId ??= -1
			analysisOptionId ??= -1

			if (!this.documentTree[plantId]) {
				this.documentTree[plantId] = {}
			}

			if (!this.documentTree[plantId][analysisId]) {
				this.documentTree[plantId][analysisId] = {}
			}

			if (!this.documentTree[plantId][analysisId][productId]) {
				this.documentTree[plantId][analysisId][productId] = {}
			}

			if (!this.documentTree[plantId][analysisId][productId][severityClassId]) {
				this.documentTree[plantId][analysisId][productId][severityClassId] = {}
			}

			if (!this.documentTree[plantId][analysisId][productId][severityClassId][analysisOptionId]) {
				this.documentTree[plantId][analysisId][productId][severityClassId][analysisOptionId] = version
			}
		})
		this.documentTreeStore.set(this.documentTree)
	}
	/**
	 * Fetch the document for a given input and store it in the tree
	 */
	public async fetch(options: DocumentLookupInput | Array<DocumentLookupInput>) {
		if (!Array.isArray(options)) {
			options = [options]
		}
		const existingDocuments = options.map(o => ({
			...o,
			document: this.#get(o),
		}))

		const lookups = existingDocuments
			.filter(({ document }) => !document)
			.map(({ analysisId, analysisOptionId, plantId, productId, severityClassId }) => ({
				analysisId,
				analysisOptionId,
				plantId,
				productId,
				severityClassId,
			}))

		if (lookups.length) {
			const { data } = await this.queryStore.fetch({
				variables: {
					lookups,
				},
			})
			if (data) {
				this.#set(data.getDocumentsByAssociation)
			}
		}
	}

	public async fetchForSample(sample: Sample, workOrderPlantId: number) {
		const lookups = sample.sampleValues.map(
			({ analysisOption }): DocumentLookupInput => ({
				analysisId: sample.analysis.id,
				analysisOptionId: analysisOption.id,
				plantId: sample.plant?.id ?? workOrderPlantId,
				productId: sample.product?.id ?? null,
				severityClassId: sample.location?.severityClass?.id ?? null,
			})
		)
		try {
			await this.fetch(lookups)
		} catch (error) {
			console.error('Error fetching documents for sample', error)
			this.mediator.call('showMessage', {
				heading: this.translate('workOrder.errorFetchingDocumentsError', 'Error fetching documents for new sample'),
				message: (error as Error).message,
				type: 'danger',
				time: false,
			})
		}
	}
	get tree() {
		return this.documentTree
	}
}

/** analysisOptionId -> plantId -> productId -> locationId -> restriction */
export type RestrictionTreeMap = Record<number, Record<number, Record<number, Record<number, OutcomeOrNone$options>>>>
// Simplify the fetching and storing of restrictions for analysis options
export class RestrictionTree {
	public constructor(restrictions: WoGetOptionRestrictions$result | null, mediator: Mediator, translate: AppContext['i18next']['t']) {
		this.restrictionTree = {}
		this.restrictionTreeStore = writable(this.restrictionTree)
		this.#set(restrictions?.getOptionRestrictions ?? [])
		this.subscribe = this.restrictionTreeStore.subscribe
		this.queryStore = new WoGetOptionRestrictionsStore()
		this.mediator = mediator
		this.translate = translate
	}

	private restrictionTree: RestrictionTreeMap
	private restrictionTreeStore: Writable<RestrictionTreeMap>
	private queryStore: WoGetOptionRestrictionsStore
	private mediator: Mediator
	private translate: AppContext['i18next']['t']

	public subscribe: Writable<RestrictionTreeMap>['subscribe']

	/**
	 * Get the restriction for a given analysis option, plant, product, and location from the tree.
	 * If the input is not found in the tree, returns 'NONE', so make sure you called fetch first if you want to ensure you have the latest data.
	 */
	public get(options: GetOptionRestrictionInput): OutcomeOrNone$options {
		return this.#get(options) ?? 'NONE'
	}

	#get(options: GetOptionRestrictionInput): OutcomeOrNone$options | undefined {
		const { analysisOptionId, plantId, productId, locationId } = options
		const restriction = this.restrictionTree[analysisOptionId]?.[plantId]?.[productId ?? -1]?.[locationId ?? -1]
		return restriction
	}

	#set(values: WoGetOptionRestrictions$result['getOptionRestrictions']) {
		values.forEach(({ restriction, analysisOptionId, plantId, productId, locationId }) => {
			productId ??= -1
			locationId ??= -1
			if (!this.restrictionTree[analysisOptionId]) {
				this.restrictionTree[analysisOptionId] = {}
			}
			if (!this.restrictionTree[analysisOptionId][plantId]) {
				this.restrictionTree[analysisOptionId][plantId] = {}
			}
			if (productId && !this.restrictionTree[analysisOptionId][plantId][productId]) {
				this.restrictionTree[analysisOptionId][plantId][productId] = {}
			}
			if (locationId && !this.restrictionTree[analysisOptionId][plantId][productId][locationId]) {
				this.restrictionTree[analysisOptionId][plantId][productId][locationId] = restriction
			}
		})
		this.restrictionTreeStore.set(this.restrictionTree)
	}
	/**
	 * Fetch the restriction for a given analysis option, plant, product, and location from the server and store it in the tree.
	 */
	public async fetch(options: GetOptionRestrictionInput | Array<GetOptionRestrictionInput>) {
		if (!Array.isArray(options)) {
			options = [options]
		}
		const existingRestrictions = options.map(o => ({
			...o,
			restriction: this.#get(o),
		}))

		const lookups = existingRestrictions
			.filter(({ restriction }) => !restriction)
			.map(({ analysisOptionId, plantId, productId, locationId }) => ({
				analysisOptionId,
				plantId,
				productId,
				locationId,
			}))

		if (lookups.length) {
			const { data } = await this.queryStore.fetch({
				variables: {
					getOptionRestrictionsInput: lookups,
				},
			})
			if (data) {
				this.#set(data.getOptionRestrictions)
			}
		}
	}

	public async fetchForSample(sample: Sample, workOrderPlantId: number) {
		const lookups = sample.sampleValues.map(({ analysisOption }) => ({
			analysisOptionId: analysisOption.id,
			plantId: sample.plant?.id ?? workOrderPlantId,
			productId: sample.product?.id ?? null,
			locationId: sample.location?.id ?? null,
		}))
		try {
			await this.fetch(lookups)
		} catch (error) {
			console.error('Error fetching documents for sample', error)
			this.mediator.call('showMessage', {
				heading: this.translate('workOrder.errorFetchingRestrictionsError', 'Error fetching restrictions for new sample'),
				message: (error as Error).message,
				type: 'danger',
				time: false,
			})
		}
	}
	get tree() {
		return this.restrictionTree
	}
}
