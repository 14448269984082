export default {
    "name": "UserAccountFields",
    "kind": "HoudiniFragment",
    "hash": "b3f8d28ce66b36bfb5d065da43e44d0783ba1dea883ad96771d345b1da1936cf",

    "raw": `fragment UserAccountFields on UserAccount {
  id
  name
  firstName
  lastName
  status
  lastLoginDate
  workEmail
  recoveryEmail
  lockNotes
  apiToken
  userLastPasswordResetDate
  userActivationData {
    activationPIN
    activationPINExpiration
  }
  userGroups {
    id
  }
  authorizedPlants {
    id
  }
  userPermissions {
    id
    permissionId
    value
  }
  __typename
}
`,

    "rootType": "UserAccount",
    "stripVariables": [],

    "selection": {
        "fields": {
            "id": {
                "type": "PositiveInt",
                "keyRaw": "id",
                "visible": true
            },

            "name": {
                "type": "String",
                "keyRaw": "name",
                "visible": true
            },

            "firstName": {
                "type": "String",
                "keyRaw": "firstName",
                "nullable": true,
                "visible": true
            },

            "lastName": {
                "type": "String",
                "keyRaw": "lastName",
                "nullable": true,
                "visible": true
            },

            "status": {
                "type": "UserAccountStatus",
                "keyRaw": "status",
                "visible": true
            },

            "lastLoginDate": {
                "type": "DateTime",
                "keyRaw": "lastLoginDate",
                "nullable": true,
                "visible": true
            },

            "workEmail": {
                "type": "String",
                "keyRaw": "workEmail",
                "nullable": true,
                "visible": true
            },

            "recoveryEmail": {
                "type": "EmailAddress",
                "keyRaw": "recoveryEmail",
                "nullable": true,
                "visible": true
            },

            "lockNotes": {
                "type": "String",
                "keyRaw": "lockNotes",
                "nullable": true,
                "visible": true
            },

            "apiToken": {
                "type": "String",
                "keyRaw": "apiToken",
                "nullable": true,
                "visible": true
            },

            "userLastPasswordResetDate": {
                "type": "DateTime",
                "keyRaw": "userLastPasswordResetDate",
                "nullable": true,
                "visible": true
            },

            "userActivationData": {
                "type": "UserActivationData",
                "keyRaw": "userActivationData",
                "nullable": true,

                "selection": {
                    "fields": {
                        "activationPIN": {
                            "type": "NonEmptyString",
                            "keyRaw": "activationPIN",
                            "nullable": true,
                            "visible": true
                        },

                        "activationPINExpiration": {
                            "type": "DateTime",
                            "keyRaw": "activationPINExpiration",
                            "nullable": true,
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "userGroups": {
                "type": "Group",
                "keyRaw": "userGroups",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "authorizedPlants": {
                "type": "Plant",
                "keyRaw": "authorizedPlants",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "userPermissions": {
                "type": "UserPermission",
                "keyRaw": "userPermissions",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "permissionId": {
                            "type": "PositiveInt",
                            "keyRaw": "permissionId",
                            "visible": true
                        },

                        "value": {
                            "type": "PermissionValue",
                            "keyRaw": "value",
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "__typename": {
                "type": "String",
                "keyRaw": "__typename",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    }
};

"HoudiniHash=87f61d4c6cbdcb99544f2a91287da4fc63cf58deaed42e81d341f5612bdadfdf";