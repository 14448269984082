import type { AppContext } from 'types/common'
import type { Merge, WritableDeep } from 'type-fest'
import type { PermissionValueMap } from '@isoftdata/svelte-user-configuration'
import type { UserAccount$result, UserConfigurationData$result } from '$houdini'

import { klona } from 'klona'
import { graphql } from '$houdini'
import component from './User.svelte'
import camelCase from 'just-camel-case'
import { getSession } from 'stores/session'
import { plantsQuery } from 'utility/plants'
import { stringToBoolean } from '@isoftdata/utility-string'

export type Group = UserConfigurationData$result['groups']['data'][number]
export type GroupPermissionMap = Map<number, Array<Merge<Group['groupPermissions'][number], { value: 'NONE' | 'SITE' | 'GLOBAL' }>>>
export type UserAccount = WritableDeep<
	Omit<
		Merge<
			UserAccount$result['userAccount'],
			{
				id: number | null
				userActivationData: Merge<
					NonNullable<UserAccount$result['userAccount']>['userActivationData'],
					{
						activationPINExpiration: Date | null
					}
				> | null
				newPassword?: string
			}
		>,
		' $fragments' | 'userPermissions' | 'userGroups'
	>
>

const defaultNewUserAccount = Object.freeze<UserAccount>({
	id: null,
	status: 'ACTIVE',
	name: '',
	workEmail: '',
	recoveryEmail: null,
	firstName: '',
	lastName: '',
	lockNotes: '',
	apiToken: null,
	userLastPasswordResetDate: null,
	userActivationData: null,
	lastLoginDate: null,
	authorizedPlants: [],
})

export default function ({ stateRouter, i18next }: AppContext) {
	const translate = i18next.t

	stateRouter.addState({
		name: 'app.configuration.user',
		route: 'user',
		querystringParameters: ['plantId', 'userAccountId', 'lastResetTime', 'lastSavedTime'],
		defaultParameters: {
			plantId: () => getSession().siteId.toString(),
			userAccountId: () => getSession().userAccountId.toString(),
			lastResetTime: null,
			lastSavedTime: null,
		},
		template: component,
		canLeaveState: domApi => {
			// @ts-expect-error
			// eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
			return domApi.canLeaveState() as boolean
		},
		async resolve(_data, parameters) {
			const session = getSession()
			const userAccountId = parameters.userAccountId === null ? null : parseInt(parameters.userAccountId, 10) || session.userAccountId
			const plantId = parameters.plantId === null ? null : parseInt(parameters.plantId, 10) || session.siteId

			let [plants, { data }] = await Promise.all([
				plantsQuery.fetch({ variables: { pagination: { pageSize: 0 } } }).then(res => res?.data?.plants?.data ?? []),
				bigData.fetch({
					variables: {
						pagination: { pageSize: 0 },
						userAccountsFilter: { authorizedPlants: plantId ? { ids: [plantId] } : null },
					},
				}),
			])

			// Translate!
			const permissions = (data?.permissions.data ?? []).map(permission => ({
				...permission,
				category: translate(`permissions:categories.${camelCase(permission.category)}`, permission.category),
				description: translate(`permissions:${camelCase(permission.codeName)}.description`, permission.description),
				displayName: translate(`permissions:${camelCase(permission.codeName)}.displayName`, permission.displayName),
			}))

			// There will be cases where the userAccountId is not in the list of userAccounts when we change the plant filter
			// If that happens, we need to reset the userAccountId to null
			const selectedUserInSite = !!(userAccountId && data?.userAccounts.data.some(userAccount => userAccount.id === userAccountId))

			//TODO remove this map if Presage Web ever changes to have Houdini handle marshal/unmarshal of DateTime scalers
			let userAccounts = (data?.userAccounts.data ?? []).map(userAccount => {
				return {
					...userAccount,
					lastLoginDate: userAccount.lastLoginDate ? new Date(userAccount.lastLoginDate) : null,
				}
			})

			const manageUserPermissionLevel = session.user.permissions.CONFIGURATION_USER_MANAGER
			const manageAPIKeyPermissionLevel = session.user.permissions.CONFIGURATION_CAN_MANAGE_API_KEYS
			let hasPermissionToChangePassword = false

			if (manageUserPermissionLevel !== 'NONE') {
				const settings = await getSettings.fetch()
				hasPermissionToChangePassword = stringToBoolean(settings.data?.changePasswordPermission.value ?? 'False')
			}

			if (manageUserPermissionLevel === 'PLANT') {
				// if the permission is "PLANT" level,
				// we need to ony show the plants that the user has access to
				// we also need to only show the plants in the user's access plants/sites
				// only show user accounts that the other's sites is a subset of the session user's access sites
				const sessionUserPlantAccessIds = session.authorizedPlantIDs
				plants = plants.filter(plant => sessionUserPlantAccessIds.includes(plant.id))
				userAccounts = userAccounts.filter(userAccount => {
					const userAccountPlantIds = userAccount.authorizedPlants.map(plant => plant.id)
					return new Set([...sessionUserPlantAccessIds, ...userAccountPlantIds]).size === sessionUserPlantAccessIds.length
				})
			}

			let userAccount = klona(defaultNewUserAccount)
			// Create maps with generic 'SITE' key for PLANT permissions
			let permissionValueMap: PermissionValueMap = new Map()
			let groupPermissionMap: GroupPermissionMap = new Map()
			let authorizedSitesSet = new Set<number>(plantId ? [plantId] : [])
			let groupMembershipSet = new Set<number>()

			if (userAccountId && selectedUserInSite) {
				const res = await userAccountQuery.fetch({ variables: { userAccountId } })

				for (const userPermission of res.data?.userAccount?.userPermissions ?? []) {
					permissionValueMap.set(userPermission.permissionId, userPermission.value === 'PLANT' ? 'SITE' : userPermission.value)
				}

				for (const group of data?.groups.data ?? []) {
					groupPermissionMap.set(
						group.id,
						group.groupPermissions.map(groupPermission => {
							return {
								...groupPermission,
								value: groupPermission.value === 'PLANT' ? 'SITE' : groupPermission.value,
							}
						})
					)
				}

				authorizedSitesSet = new Set(res.data?.userAccount?.authorizedPlants.map(plant => plant.id))
				groupMembershipSet = new Set(res.data?.userAccount?.userGroups.map(group => group.id))

				//TODO remove this code if we ever change Presage Web to have Houdini handle marshal/unmarshal of DateTime scalers
				userAccount = {
					...(res.data?.userAccount ?? userAccount),
					userActivationData: res.data?.userAccount?.userActivationData
						? {
								...res.data?.userAccount?.userActivationData,
								activationPINExpiration: res.data?.userAccount?.userActivationData.activationPINExpiration ? new Date(res.data?.userAccount?.userActivationData.activationPINExpiration) : null,
						  }
						: null,
				}

				if (userAccount.id) {
					/* Regardless of filter, we need to ensure the userAccount is in the list
					That way if they create a new account with different plant access than the selected filter,
					the new user account will still be in the list */
					userAccounts.find(userAccount => userAccount.id === userAccountId) ??
						userAccounts.push({
							id: userAccount.id,
							name: userAccount.name,
							status: userAccount.status,
							lastLoginDate: userAccount.lastLoginDate ? new Date(userAccount.lastLoginDate) : null,
							authorizedPlants: userAccount.authorizedPlants,
						})
				}
			}

			return {
				plants,
				groups: data?.groups.data ?? [],
				permissions,
				userAccounts,
				selectedPlantId: plantId,
				userAccount: userAccount ?? klona(defaultNewUserAccount),
				defaultNewUserAccount,
				hasPermissionToChangePassword,
				manageAPIKeyPermissionLevel,
				authorizedSitesSet,
				permissionValueMap,
				groupPermissionMap,
				groupMembershipSet,
				i18next,
			}
		},
	})
}

graphql(`
	fragment UserAccountFields on UserAccount {
		id
		name
		firstName
		lastName
		status
		lastLoginDate
		workEmail
		recoveryEmail
		lockNotes
		apiToken
		userLastPasswordResetDate
		userActivationData {
			activationPIN
			activationPINExpiration
		}
		userGroups {
			id
		}
		authorizedPlants {
			id
		}
		userPermissions {
			id
			permissionId
			value
		}
	}
`)

const userAccountQuery = graphql(`
	query UserAccount($userAccountId: PositiveInt!) {
		userAccount(id: $userAccountId) {
			...UserAccountFields
		}
	}
`)

const bigData = graphql(`
	query UserConfigurationData($pagination: PaginatedInput, $userAccountsFilter: UserFilter) {
		userAccounts(filter: $userAccountsFilter, pagination: $pagination) {
			data {
				id
				name
				status
				lastLoginDate
				authorizedPlants {
					id
				}
			}
		}
		permissions(pagination: $pagination) {
			data {
				id
				displayName
				description
				codeName
				category
			}
		}
		groups(pagination: $pagination) {
			data {
				id
				name
				workerGroup
				groupPermissions {
					permissionId
					value
				}
			}
		}
	}
`)

const getSettings = graphql(`
	query UserAccountConfigurationSettings {
		changePasswordPermission: getGlobalSetting(lookup: { name: "Administrators can set other users' passwords", settingType: HIDDEN, category: "Security", defaultValue: "False" }) {
			value
		}
	}
`)
