import type { AppContext } from 'types/common'
import { loadAnalysesData } from 'utility/analysis-management-helper'
import { getSession } from 'stores/session'

import component from 'components/analysis-management/Analyses.svelte'

export default function ({ mediator, stateRouter }: AppContext) {
	stateRouter.addState({
		name: 'app.product-management.recipes',
		route: 'recipes',
		querystringParameters: ['plantId', 'showInactive', 'showUnused'],
		defaultParameters: {
			plantId: () => getSession().siteId.toString(),
			showInactive: 'true',
			showUnused: 'false',
			selectedAnalysisId: null,
		},
		template: component,
		canLeaveState: domApi => {
			// TODO: replace with the ConfirmNavigationModal once ASR can give me the destination route/parameters
			// @ts-expect-error
			// eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
			return domApi.canLeaveState() as boolean
		},
		resolve(_data, parameters: Record<string, string>) {
			return loadAnalysesData(parameters, mediator, true)
		},
	})
}
