import { type ProductNode, type ProductAttachment, loadProducts, type Analysis, makePermissionFunctions, loadProductsPageQuery, makeSpecificationCache, Batch } from 'utility/product-helper'
import type { AppContext, ResolveParameters, SvelteDomApi } from 'types/common'
import makeCrudStore from '@isoftdata/svelte-store-crud'
import { findNodeById } from '@isoftdata/svelte-table'
import { stringToBoolean } from '@isoftdata/utility-string'
import component from './Product.svelte'
import { ProductType } from '$houdini'
import showErrorAndRedirect from 'utility/show-error-and-redirect'
import { v4 as uuid } from '@lukeed/uuid'
import makeAddRemoveStore from 'stores/add-remove-store'
import { writable } from 'svelte/store'
import { getSession } from 'stores/session'
import type { MetaSpecification } from 'components/ConfigureSpecificationCard.svelte'

export type ProductParams = {
	productId: string | null
	[other: string]: string | null
}

export default ({ mediator, stateRouter, i18next: { t: translate } }: AppContext) => {
	stateRouter.addState({
		name: 'app.product-management.product',
		route: 'product',
		querystringParameters: ['plantId', 'showInactive', 'showUnused', 'selectedProductTypeFilter'],
		defaultParameters: {
			plantId: () => getSession().siteId.toString() ?? 'null',
			selectedProductId: null,
			showInactive: 'false',
			showUnused: 'false',
			selectedProductTypeFilter: 'PRODUCT',
		},
		template: component,
		canLeaveState(domApi) {
			// @ts-expect-error
			return domApi.canLeaveState() || confirm(translate('common:canLeaveState', 'You have unsaved changes. Are you sure you want to leave? All unsaved changes will be lost.'))
		},
		async resolve(_data, parameters: ResolveParameters<ProductParams>) {
			const selectedProductTypeFilter = parameters.selectedProductTypeFilter === 'PRODUCT' ? ProductType.PRODUCT : ProductType.INGREDIENT
			const showInactive = stringToBoolean(parameters.showInactive)
			const showUnused = stringToBoolean(parameters.showUnused)
			const productId = parseInt(parameters.selectedProductId, 10) ?? null

			// Load all non-plant-specific data
			const { data } = await loadProductsPageQuery.fetch({
				variables: {
					pagination: {
						pageSize: 0,
					},
					filter: {
						active: true,
						entityTypes: ['PRODUCT'],
					},
				},
			})
			// Data can possibly be null, (like if we have an error) so we need to check for that
			if (!data) {
				console.error('No data was returned from the server.')
				throw showErrorAndRedirect(
					mediator,
					translate('product.errorLoadingProductManagement', 'Error Loading Product Management.'),
					translate('product.noDataWasReturnedFromTheServer', 'No data was returned from the server.')
				)
			}
			const plants = data.plants.data
			const tags = data.entityTags
			const severityClasses = data.severityClasses.data
			const analyses = data.analyses.data
			const productCategories = data.productCategories

			const session = getSession()

			let plantId: number | null = (parseInt(parameters.plantId, 10) || null) ?? session.siteId

			const authorizedPlantIds = session.authorizedPlantIDs ?? []

			const nonPrivatePlants = plants.filter(plant => plant.id === session.siteId || !plant.private)

			const plantsWithPermissions = nonPrivatePlants
				.filter(plant => authorizedPlantIds.includes(plant.id))
				.map(plant => {
					return {
						...plant,
						uuid: plant.id.toString(),
					}
				})

			if (!plantId && plantsWithPermissions.length > 0) {
				plantId = plantId ?? plantsWithPermissions[0].id
			} else if (plantId && plantsWithPermissions.length > 0) {
				plantId = plantId ?? plantsWithPermissions[0].id
			} else {
				throw showErrorAndRedirect(
					mediator,
					translate('product.errorLoadingProductManagement', 'Error loading Product Management'),
					translate('product.noPlantsFound', 'No plants were found. Make sure you have the proper permissions at, at least one plant.')
				)
			}

			const selectedPlant = (plantId ? plants.find(plant => plant.id === plantId) : null) ?? null

			const filter = {
				plantIdsFilteringByChildren: selectedProductTypeFilter === 'PRODUCT' ? (showUnused ? null : [plantId]) : null,
				productType: selectedProductTypeFilter,
				activeOnly: selectedProductTypeFilter === 'PRODUCT' ? !showInactive : null,
				active: !showInactive,
				plantIds: showUnused ? null : [plantId],
			}

			const uuidTags = tags.map(tag => ({ ...tag, uuid: uuid() }))

			const { uuidMap, productsTree, products } = await loadProducts(filter, plantId, uuidTags)

			const analysesById = analyses.reduce((acc: Record<number, Analysis>, analysis) => {
				acc[analysis.id] = analysis
				return acc
			}, {})

			const selectedProduct = productId ? findNodeById(productsTree, 'uuid', uuidMap[productId]) ?? null : productsTree[0] ?? null

			const { canEditGlobalFields } = makePermissionFunctions(products, plantId)

			return {
				plantId,
				plants: plantsWithPermissions,
				tags: uuidTags,
				severityClasses,
				productCategories,
				clipboard: null,
				expandedCard: 'Details',
				analyses,
				tagAddRemoveStore: makeAddRemoveStore(),
				inUseAtPlantIDsAddRemoveStore: makeAddRemoveStore(),
				productsList: products,
				productsTree,
				selectedProduct,
				selectedSpecification: null,
				specificationCache: makeSpecificationCache(),
				tagCrudStore: makeCrudStore<(typeof uuidTags)[0], 'uuid'>('uuid'),
				productCrudStore: makeCrudStore<ProductNode, 'uuid'>('uuid'),
				hasUnsavedChanges: writable(false),
				attachmentCrudStore: makeCrudStore<ProductAttachment, 'uuid'>('uuid'),
				specificationCrudStore: makeCrudStore<MetaSpecification, 'uuid'>('uuid'),
				batchCrudStore: makeCrudStore<Batch, 'uuid'>('uuid'),
				selectedPlant: selectedPlant ?? plantsWithPermissions[0],
				analysesById,
				selectedProductTypeFilter,
				showInactive,
				showUnused,
				canEditGlobalFields,
			}
		},
	})
}
