export default {
    "name": "UserAccount",
    "kind": "HoudiniQuery",
    "hash": "176197d5ca875d9365db5b7631867f4d1831cb991aa7cfe1a0aa439522332da4",

    "raw": `query UserAccount($userAccountId: PositiveInt!) {
  userAccount(id: $userAccountId) {
    ...UserAccountFields
    id
  }
}

fragment UserAccountFields on UserAccount {
  id
  name
  firstName
  lastName
  status
  lastLoginDate
  workEmail
  recoveryEmail
  lockNotes
  apiToken
  userLastPasswordResetDate
  userActivationData {
    activationPIN
    activationPINExpiration
  }
  userGroups {
    id
  }
  authorizedPlants {
    id
  }
  userPermissions {
    id
    permissionId
    value
  }
  __typename
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "userAccount": {
                "type": "UserAccount",
                "keyRaw": "userAccount(id: $userAccountId)",
                "nullable": true,

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        },

                        "firstName": {
                            "type": "String",
                            "keyRaw": "firstName",
                            "nullable": true,
                            "visible": true
                        },

                        "lastName": {
                            "type": "String",
                            "keyRaw": "lastName",
                            "nullable": true,
                            "visible": true
                        },

                        "status": {
                            "type": "UserAccountStatus",
                            "keyRaw": "status",
                            "visible": true
                        },

                        "lastLoginDate": {
                            "type": "DateTime",
                            "keyRaw": "lastLoginDate",
                            "nullable": true,
                            "visible": true
                        },

                        "workEmail": {
                            "type": "String",
                            "keyRaw": "workEmail",
                            "nullable": true,
                            "visible": true
                        },

                        "recoveryEmail": {
                            "type": "EmailAddress",
                            "keyRaw": "recoveryEmail",
                            "nullable": true,
                            "visible": true
                        },

                        "lockNotes": {
                            "type": "String",
                            "keyRaw": "lockNotes",
                            "nullable": true,
                            "visible": true
                        },

                        "apiToken": {
                            "type": "String",
                            "keyRaw": "apiToken",
                            "nullable": true,
                            "visible": true
                        },

                        "userLastPasswordResetDate": {
                            "type": "DateTime",
                            "keyRaw": "userLastPasswordResetDate",
                            "nullable": true,
                            "visible": true
                        },

                        "userActivationData": {
                            "type": "UserActivationData",
                            "keyRaw": "userActivationData",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "activationPIN": {
                                        "type": "NonEmptyString",
                                        "keyRaw": "activationPIN",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "activationPINExpiration": {
                                        "type": "DateTime",
                                        "keyRaw": "activationPINExpiration",
                                        "nullable": true,
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "userGroups": {
                            "type": "Group",
                            "keyRaw": "userGroups",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "authorizedPlants": {
                            "type": "Plant",
                            "keyRaw": "authorizedPlants",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "userPermissions": {
                            "type": "UserPermission",
                            "keyRaw": "userPermissions",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "permissionId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "permissionId",
                                        "visible": true
                                    },

                                    "value": {
                                        "type": "PermissionValue",
                                        "keyRaw": "value",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "__typename": {
                            "type": "String",
                            "keyRaw": "__typename",
                            "visible": true
                        }
                    },

                    "fragments": {
                        "UserAccountFields": {
                            "arguments": {}
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "userAccountId": "PositiveInt"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=314701e2aa8567e819501e09410697829cbed95263c8af110836239b09c0711c";