import component from './UserGroup.svelte'
import { v4 as uuid } from '@lukeed/uuid'
import { AppContext, SvelteDomApi } from 'client/types/common'

import { graphql } from '$houdini'
import type { LoadUserGroups$result, PermissionValue$options } from '$houdini'
import showErrorAndRedirect from 'utility/show-error-and-redirect'
import type { Merge, WritableDeep } from 'type-fest'

type UserAccount = Merge<
	WritableDeep<Omit<LoadUserGroups$result['groups']['data'][0]['userAccounts'][0], 'userGroups'>>,
	{
		isMember: boolean
	}
>

type GroupPermission = Merge<
	WritableDeep<LoadUserGroups$result['permissions']['data'][number]>,
	{
		value: PermissionValue$options
	}
>

export type Group = Merge<
	WritableDeep<LoadUserGroups$result['groups']['data'][number]>,
	{
		userAccounts: Array<UserAccount>
		groupPermissions: Array<GroupPermission>
		uuid: string
	}
>

export default ({ mediator, stateRouter, i18next: { t: translate } }: AppContext) => {
	stateRouter.addState({
		name: 'app.configuration.user-group',
		route: 'user-group',
		querystringParameters: ['lastResetTime', 'lastSavedTime'],
		defaultParameters: {
			lastResetTime: null,
			lastSavedTime: null,
		},
		template: component,
		canLeaveState(svelte) {
			// @ts-expect-error
			return svelte.canLeaveState()
		},
		async resolve(_data, _parameters) {
			const { data } = await userGroups.fetch()
			if (!data) {
				console.error('No data was returned from the server.')
				throw showErrorAndRedirect(
					mediator,
					translate('configuration.userGroup.noDataReturnErrorHeading', 'Error!'),
					translate('configuration.userGroup.noDataReturnErrorMessage', 'No data was returned from the server.')
				)
			}

			const groupRes = data.groups.data
			const permissionRes = data.permissions.data
			const userAccountRes = data.userAccounts.data

			const formattedGroups = groupRes.reduce((acc, group) => {
				acc[group.id] = {
					...group,
					userAccounts: userAccountRes.map(userAccount => {
						return {
							id: userAccount.id,
							name: userAccount.name,
							isMember: userAccount.userGroups ? userAccount.userGroups.some(userGroup => userGroup.id === group.id) : false,
						}
					}),
					groupPermissions: permissionRes.map(permission => {
						return {
							...permission,
							value: group.groupPermissions.find(groupPermission => groupPermission.permissionId === permission.id)?.value ?? 'NONE',
						}
					}),
					uuid: uuid(),
				}
				return acc
			}, {} as Record<number, Group>)

			return {
				groups: formattedGroups,
				selectedGroup: Object.values(formattedGroups)[0],
				hasUnsavedChanges: false,
			}
		},
	})
}

const userGroups = graphql(`
	query LoadUserGroups {
		groups {
			data {
				id
				name
				workerGroup
				userAccounts {
					id
					name
				}
				groupPermissions {
					permissionId
					value
				}
				groupInUseData {
					alertSubscriptionCount
					analysisCount
					workOrderTypeCount
				}
			}
		}
		permissions {
			data {
				id
				displayName
				category
				codeName
			}
		}
		userAccounts {
			data {
				id
				name
				userGroups {
					id
				}
			}
		}
	}
`)
