<script lang="ts">
	import type { Mediator, SvelteAsr, MediatorProviders } from 'client/types/common'
	import type { i18n } from 'i18next'
	import type { Writable } from 'svelte/store'
	import { AsrNavTabBar } from '@isoftdata/svelte-nav-tab-bar'

	import { getContext, type ComponentProps } from 'svelte'
	import SaveResetButton from '@isoftdata/svelte-save-reset-button'
	import StateCardHeader from 'components/StateCardHeader.svelte'

	export let asr: SvelteAsr
	export let lastSavedTime: string
	export let saveResetProps: Writable<ComponentProps<SaveResetButton>>

	$: lastSaved = lastSavedTime ? new Date(lastSavedTime).toLocaleString() : 'Never'

	const { t: translate } = getContext<i18n>('i18next')
	const mediator = getContext<
		Mediator<
			MediatorProviders & {
				'analysis-management-save': () => Promise<void>
			}
		>
	>('mediator')

	const tabs: ComponentProps<AsrNavTabBar>['tabs'] = [
		{
			title: translate('analysisManagement.analysesTabLabel', 'Analyses'),
			name: 'app.analysis-management.analyses',
			hidden: false, // todo permissions
			disabled: false, // todo permissions
		},
		/* 		{
			title: 'Scheduled Analysis Plan',
			name: 'app.analysis-management.scheduled-analysis-plan',
			hidden: true, // todo make this state and also check permissions
		},
		{
			title: 'Severity Classes',
			name: 'app.analysis-management.severity-classes',
			hidden: true, // todo make this state and also check permissions
		}, */
	]

	const showNavTabBar = tabs.filter(state => !state.hidden && !state.disabled).length > 1
</script>

<div class="card">
	<StateCardHeader
		title={translate('analysisManagement.analysisManagement', 'Analysis Management')}
		icon="flask-gear"
	>
		<svelte:fragment slot="right"
			><span class="mr-3 align-self-center">{translate('common:lastSaved', 'Last saved')}: {lastSaved}</span>
			<SaveResetButton
				{...$saveResetProps}
				save={async () => {
					mediator.call('showMessage', { heading: `${translate('common:saving')}...`, message: '', type: 'info', time: false })
					await $saveResetProps.save?.()
				}}
				resetHref={asr.makePath(null, { lastResetTime: Date.now(), lastSavedTime: null }, { inherit: true })}
			></SaveResetButton></svelte:fragment
		>
		{#if showNavTabBar}
			<AsrNavTabBar
				{asr}
				{tabs}
				breakpoint="md"
			/>
		{/if}
	</StateCardHeader>
	<div class="card-body">
		<uiView></uiView>
	</div>
</div>
