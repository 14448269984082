<script lang="ts">
	import type { PreviewConfiguration } from 'utility/alert-subscription-helper'
	import type { i18n } from 'i18next'
	import type { AlertPreviewInput } from '$houdini'
	import type { AlertSubscription, AlertTypeMap } from 'utility/alert-subscription-helper'

	import { getContext } from 'svelte'
	import { graphql } from '$houdini'
	import { klona } from 'klona'
	import { buildTranslatedHelpers } from 'utility/alert-subscription-helper'
	import Modal from '@isoftdata/svelte-modal'
	import Icon from '@isoftdata/svelte-icon'

	const { t: translate } = getContext<i18n>('i18next')
	const { buildNewAlertSubscriptionForApiFromViewState } = buildTranslatedHelpers(translate)

	const defaultPreviewConfiguration: PreviewConfiguration = Object.freeze<PreviewConfiguration>({
		documentId: '',
		scheduleId: 0,
		date: '',
		loadedPreview: null,
	})

	export let preview: PreviewConfiguration = klona(defaultPreviewConfiguration)
	export let alertTypeMap: AlertTypeMap
	export let alertSubscription: AlertSubscription
	export let isLoading: boolean = false
	export let showModal: boolean = false

	const alertPreviewQuery = graphql(`
		query AlertPreview($input: AlertPreviewInput!) {
			alertPreview(input: $input) {
				body
				emailAddress
				error
				title
			}
		}
	`)

	export async function showPreview(givenPreview: PreviewConfiguration) {
		showModal = true
		const defaultPreview = klona(defaultPreviewConfiguration)
		preview = {
			...defaultPreview,
			documentId: givenPreview?.documentId ?? '',
			scheduleId: givenPreview?.scheduleId ?? null,
			date: givenPreview?.date ?? defaultPreview.date,
		}

		isLoading = true
		const alertType = alertSubscription.type ? alertTypeMap.get(alertSubscription.type) : undefined

		let input: AlertPreviewInput = {}
		const subscription = buildNewAlertSubscriptionForApiFromViewState(alertSubscription, true)

		if (alertType?.isSummary && preview.scheduleId) {
			input = {
				subscription,
				schedule: {
					id: preview.scheduleId,
					date: preview.date,
				},
			}
		} else if (preview.documentId) {
			input = {
				subscription,
				documentId: parseInt(preview.documentId, 10),
			}
		} else {
			console.error('Unable to build preview input.')
			alert('Error: Invalid preview input.')
			return
		}

		try {
			const alertPreviewResult = await alertPreviewQuery.fetch({ variables: { input } })

			preview.loadedPreview = {
				to: alertPreviewResult?.data?.alertPreview?.emailAddress ?? '',
				subject: alertPreviewResult?.data?.alertPreview?.title ?? '',
				body: alertPreviewResult?.data?.alertPreview?.body ?? '',
				error: alertPreviewResult?.data?.alertPreview?.error ?? '',
			}
		} catch (err) {
			preview.loadedPreview = {
				to: '',
				subject: '',
				body: '',
				error: err instanceof Error ? err.message : 'An unknown error occurred.',
			}

			console.error(err)
		} finally {
			isLoading = false
		}
	}
</script>

<Modal
	bind:show={showModal}
	title="Alert Preview"
	modalSize="xl"
	cancelButtonText={translate('common:close', 'Close')}
	cancelButtonSize="sm"
	cancelButtonColor="primary"
	cancelButtonOutline
	confirmShown={false}
	on:close={() => (showModal = false)}
>
	{#if isLoading}
		<div class="text-center">
			<Icon
				isLoading
				size="2x"
			/>
		</div>
	{:else if preview?.loadedPreview}
		{#if preview.loadedPreview.error}
			<div
				class="alert alert-danger text-center border-0"
				style="border-radius: 0;"
			>
				<div class="alert-heading">
					<Icon
						icon="exclamation-triangle"
						size="3x"
						class="mb-2"
					/>
					<h5>{translate('configuration.alertSubscription.errorLoadingAlertPreview', 'Error Loading Alert Preview')}</h5>
				</div>
				<p>{preview.loadedPreview.error}</p>
			</div>
		{:else}
			<div class="card">
				<div class="card-body">
					<div class="d-block">
						<span class="font-weight-bold">{translate('common:to', 'To')}: </span><span>{preview.loadedPreview.to}</span>
					</div>
					<hr class="my-1" />
					<div class="d-block">
						<span class="font-weight-bold">{translate('common:subject', 'Subject')}: </span><span>{preview.loadedPreview.subject}</span>
					</div>
					<hr class="my-1" />
					<span class="font-weight-bold">{translate('common:body', 'Body')}</span>
					<span class="d-block overflow-auto">
						{@html preview.loadedPreview.body}
					</span>
				</div>
			</div>
		{/if}
	{/if}
</Modal>
