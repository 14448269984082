<script lang="ts">
	import type { CrudStore } from '@isoftdata/svelte-store-crud'

	import { AsrNavTabBar } from '@isoftdata/svelte-nav-tab-bar'
	import SaveResetButton from '@isoftdata/svelte-save-reset-button'
	import type { Mediator, SvelteAsr } from 'types/common'
	import { getContext, type ComponentProps } from 'svelte'
	import type { InvestigationTrigger } from './configure-investigation/configure-investigation'
	import { graphql } from '$houdini'
	import type { UpdateInvestigationTriggers$input } from '$houdini'
	import type { i18n } from 'i18next'
	import StateCardHeader from 'components/StateCardHeader.svelte'

	export let asr: SvelteAsr
	export let lastSavedTime: string
	export let childStates: ComponentProps<AsrNavTabBar>['tabs']
	export let investigationTriggersCrudStore: CrudStore<InvestigationTrigger, 'uuid'>

	const { t: translate } = getContext<i18n>('i18next')
	const mediator = getContext<Mediator>('mediator')

	const createInvestigationTriggers = graphql(`
		mutation CreateInvestigationTriggers($inputs: [NewInvestigationTrigger!]!) {
			createInvestigationTriggers(inputs: $inputs) {
				id
				description
				active
				resultStatus
				resultCount
				period
				periodType
				defaultComments
				defaultInvestigationType
				defaultStatus
				retestMode
				daysUntilInvestigationDue
				expansionMethod
				expansionSize
				sampleCollectionCount
				retestDocumentCount
				daysBetweenRetests
				analysisOptionId
				analysisOption {
					id
					option
					analysisId
				}
				plantId
				locationId
				severityClassId
				productId
				retestWorkOrderTypeId
				retestAnalysisId
			}
		}
	`)

	const updateInvestigationTriggers = graphql(`
		mutation UpdateInvestigationTriggers($inputs: [UpdateInvestigationTrigger!]!) {
			updateInvestigationTriggers(inputs: $inputs) {
				id
				description
				active
				resultStatus
				resultCount
				period
				periodType
				defaultComments
				defaultInvestigationType
				defaultStatus
				retestMode
				daysUntilInvestigationDue
				expansionMethod
				expansionSize
				sampleCollectionCount
				retestDocumentCount
				daysBetweenRetests
				analysisOptionId
				analysisOption {
					id
					option
					analysisId
				}
				plantId
				locationId
				severityClassId
				productId
				retestWorkOrderTypeId
				retestAnalysisId
			}
		}
	`)

	const deleteInvestigationTriggers = graphql(`
		mutation DeleteInvestigationTriggers($ids: [PositiveInt!]!) {
			deleteInvestigationTriggers(ids: $ids)
		}
	`)

	let selectedTab: string

	$: hasChanges = $investigationTriggersCrudStore && investigationTriggersCrudStore.hasChanges()

	async function save() {
		if (selectedTab === 'app.investigation.configure') {
			const investigationTriggersToCreate = investigationTriggersCrudStore.createdValues
			const investigationTriggersToUpdate = investigationTriggersCrudStore.updatedValues
			const investigationTriggersToDelete = investigationTriggersCrudStore.deletedValues

			const createInvestigationTriggerInputs = investigationTriggersToCreate?.map(trigger => {
				// TODO: revisit this, there might be a better way to handle this
				if (!trigger.analysisOptionId) {
					mediator.call('showMessage', {
						type: 'danger',
						heading: translate('investigation.missingFieldErrorHeading', 'Missing Required Field!'),
						message: translate(
							'investigation.missingRequiredAnalysisOptionErrorMessage',
							'An analysis option is required for each investigation trigger. Please select an analysis option for each trigger and try again.',
						),
						time: false,
					})
					throw new Error('Analysis option is required')
				}
				return {
					// format the data shape to match the API inputs
					active: trigger.active,
					description: trigger.description ?? '',
					daysBetweenRetests: trigger.daysBetweenRetests,
					daysUntilInvestigationDue: trigger.daysUntilInvestigationDue,
					defaultComments: trigger.defaultComments,
					defaultInvestigationType: trigger.defaultInvestigationType,
					defaultStatus: trigger.defaultStatus,
					expansionMethod: trigger.expansionMethod,
					expansionSize: trigger.expansionSize,
					period: trigger.period,
					periodType: trigger.periodType,
					plantId: trigger.plantId,
					locationId: trigger.locationId,
					productId: trigger.productId,
					retestAnalysisId: trigger.retestAnalysisId,
					retestDocumentCount: trigger.retestDocumentCount,
					retestMode: trigger.retestMode,
					retestWorkOrderTypeId: trigger.retestWorkOrderTypeId,
					resultCount: trigger.resultCount,
					resultStatus: trigger.resultStatus,
					sampleCollectionCount: trigger.sampleCollectionCount,
					severityClassId: trigger.severityClassId,
					analysisOptionId: trigger.analysisOptionId,
				}
			})

			const updateInvestigationTriggerInputs = investigationTriggersToUpdate.reduce((acc: UpdateInvestigationTriggers$input['inputs'], trigger) => {
				if (trigger.id) {
					acc.push({
						id: trigger.id,
						active: trigger.active,
						description: trigger.description ?? '',
						daysBetweenRetests: trigger.daysBetweenRetests,
						daysUntilInvestigationDue: trigger.daysUntilInvestigationDue,
						defaultComments: trigger.defaultComments,
						defaultInvestigationType: trigger.defaultInvestigationType,
						defaultStatus: trigger.defaultStatus,
						expansionMethod: trigger.expansionMethod,
						expansionSize: trigger.expansionSize,
						period: trigger.period,
						periodType: trigger.periodType,
						plantId: trigger.plantId,
						locationId: trigger.locationId,
						productId: trigger.productId,
						retestAnalysisId: trigger.retestAnalysisId,
						retestDocumentCount: trigger.retestDocumentCount,
						retestMode: trigger.retestMode,
						retestWorkOrderTypeId: trigger.retestWorkOrderTypeId,
						resultCount: trigger.resultCount,
						resultStatus: trigger.resultStatus,
						sampleCollectionCount: trigger.sampleCollectionCount,
						severityClassId: trigger.severityClassId,
						analysisOptionId: trigger.analysisOptionId,
					})
				}
				return acc
			}, [])

			const deleteInvestigationTriggerIds = investigationTriggersToDelete.reduce((acc: number[], trigger) => {
				if (trigger.id) {
					acc.push(trigger.id)
				}
				return acc
			}, [])

			const failedSaves: Set<'create' | 'update' | 'delete'> = new Set()
			let errorMessages: Array<string> = []
			try {
				const res = await Promise.allSettled([
					createInvestigationTriggerInputs.length ? createInvestigationTriggers.mutate({ inputs: createInvestigationTriggerInputs }) : Promise.resolve(),
					updateInvestigationTriggerInputs.length ? updateInvestigationTriggers.mutate({ inputs: updateInvestigationTriggerInputs }) : Promise.resolve(),
					deleteInvestigationTriggerIds.length ? deleteInvestigationTriggers.mutate({ ids: deleteInvestigationTriggerIds }) : Promise.resolve(),
				])

				// If any of the saves failed, add the failed save to the failedSaves set
				if (res[0].status === 'rejected') {
					failedSaves.add('create')
					errorMessages.push(res[0].reason)
				}
				if (res[1].status === 'rejected') {
					failedSaves.add('update')
					errorMessages.push(res[1].reason)
				}
				if (res[2].status === 'rejected') {
					failedSaves.add('delete')
					errorMessages.push(res[2].reason)
					// Throw an error message to use if any of the saves failed and prevent page reload
				}
				if (failedSaves.size) {
					throw new Error(errorMessages.join('\n'))
				}
				mediator.call('showMessage', {
					type: 'success',
					heading: translate('investigation.savedSuccessHeading', 'Investigation Triggers Saved!'),
					message: translate('investigation.investigationTriggerSavedMessage', 'Investigation triggers have been saved successfully.'),
					time: 10,
				})
				investigationTriggersCrudStore.clear()
				asr.go(null, { lastSavedTime: Date.now() }, { inherit: true })
			} catch (error: any) {
				mediator.call('showMessage', {
					type: 'danger',
					heading: translate('investigation.savingErrorHeading', 'Error Saving Investigation Triggers!'),
					message: error.message || translate('common:unknownError', 'Unknown error'),
					time: false,
				})
				// Handle the state change to make sure crud that got saved is still saved
				// If failed saves doesn't have a specific save, clear the ones not present
				if (!failedSaves.has('create')) {
					investigationTriggersCrudStore.clear('create')
				}
				if (!failedSaves.has('update')) {
					investigationTriggersCrudStore.clear('update')
				}
				if (!failedSaves.has('delete')) {
					investigationTriggersCrudStore.clear('delete')
				}
				console.error(error)
			}
		}
	}
</script>

<div class="card">
	<StateCardHeader
		title={translate('investigation.title', 'Investigations')}
		icon="file-magnifying-glass"
	>
		<svelte:fragment slot="right"
			><span class="mr-3 align-self-center">
				{translate('common:lastSaved', 'Last saved')}: {lastSavedTime ? new Date(parseInt(lastSavedTime, 10)).toLocaleTimeString() : translate('common:never', 'never')}
			</span>
			<SaveResetButton
				{save}
				disabled={!hasChanges}
				resetHref={asr.makePath(null, { lastSavedTime: null, lastResetTime: Date.now() }, { inherit: true })}
			/></svelte:fragment
		>
		<AsrNavTabBar
			tabs={childStates}
			{asr}
			breakpoint="md"
			bind:selectedTab
		/>
	</StateCardHeader>

	<div class="card-body">
		<uiView></uiView>
	</div>
</div>
