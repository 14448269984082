<script
	context="module"
	lang="ts"
>
	import type { LOCATIONS_LIST_PAGE$result, LOCATIONS_PAGE$result, NewUpdateLocation } from '$houdini'
	export type Location = LOCATIONS_LIST_PAGE$result['locations']['data'][0]
	export type Plant = LOCATIONS_PAGE$result['plants']['data'][0]
	export type SeverityClass = LOCATIONS_LIST_PAGE$result['defaultSeverityClass']
	export type ProcessZone = LOCATIONS_LIST_PAGE$result['processZones']['data'][0]
	export type ProductProximity = LOCATIONS_LIST_PAGE$result['productProximities']['data'][0]

	export type LocationNode = TreeNode<
		Omit<Location, 'id' | 'severityClass' | 'tags' | 'attachments'> & {
			id: number | null
			uuid: string
			attachments: MetaAttachment[]
			imageAttachments: MetaAttachment[]
			thumbnailPath: string | null
			tags: MetaTag[]
			parentLocationUuid: string | null
			severityClass: Partial<SeverityClass> | Promise<Partial<SeverityClass>>
		},
		'uuid',
		'parentLocationUuid'
	>
	export type MetaAttachment = {
		/** New files will have a File object on them*/
		File?: File
		locationId: number | null
		locationUuid: string
		mimeType: string
		name: string
		path: string
		public: boolean
		rank: number
		size: number
		uuid: string
		fileId?: number
	}
	export function computeLocationName(location: LocationNode, delimiter: string): string {
		if (location.parent) {
			return `${computeLocationName(location.parent, delimiter)}${delimiter}${location.code}`
		}
		return location.code
	}

	export const LOCATION_MAX_LENGTH = 50
</script>

<script lang="ts">
	import type { SvelteAsr, Mediator } from 'types/common'
	import type { TreeNode } from '@isoftdata/svelte-table'
	import type { MetaTag } from 'components/TagSelection.svelte'
	import type { AddRemoveStore } from 'stores/add-remove-store'
	import type { CrudStore } from '@isoftdata/svelte-store-crud'
	import type { i18n } from 'i18next'
	import type { Writable } from 'svelte/store'
	// These are classes so don't import type
	import { graphql } from '$houdini'

	import Input from '@isoftdata/svelte-input'
	import SaveResetButton from '@isoftdata/svelte-save-reset-button'

	import { getContext, onDestroy, onMount } from 'svelte'
	import pMap from 'p-map'
	import b64ify from 'utility/b64ify'
	import StateCardHeader from 'components/StateCardHeader.svelte'
	import { createEntityTags, updateEntityTags, deleteEntityTags } from 'utility/entity-tags'

	const mediator = getContext<Mediator>('mediator')
	const { t: translate } = getContext<i18n>('i18next')
	const LOCATION_MAX_LENGTH = 50

	export let tags: Array<MetaTag>
	export let tagCrudStore: CrudStore<MetaTag, 'uuid'>
	export let locationCrudStore: CrudStore<LocationNode, 'uuid'>
	export let tagAddRemoveStore: AddRemoveStore
	export let attachmentCrudStore: CrudStore<MetaAttachment, 'uuid'>
	export let hasUnsavedChanges: boolean
	export let canLeaveLocationState: Writable<boolean>

	//#region GraphQL
	const updateDelimiter = graphql(`
		mutation UpdateLocationDelimiter($delimiter: String!) {
			updateLocationDelimiter(delimiter: $delimiter)
		}
	`)

	const createAndUpdateLocations = graphql(`
		mutation CreateAndUpdateLocations($locations: [NewUpdateLocation!]!) {
			createAndUpdateLocations(locations: $locations) {
				id
				plantId
				location
			}
		}
	`)

	const deleteLocations = graphql(`
		mutation DeleteLocations($locationIds: [UnsignedInt!]!) {
			deleteLocations(locationIds: $locationIds)
		}
	`)

	const attachFileToLocation = graphql(`
		mutation AttachFileToLocation($input: NewLocationFile!) {
			attachFileToLocation(input: $input) {
				id
			}
		}
	`)

	const detachFilesFromLocation = graphql(`
		mutation DetachFilesFromLocation($fileIds: [PositiveInt!]!, $locationId: PositiveInt!) {
			detachFilesFromLocation(fileIds: $fileIds, locationId: $locationId)
		}
	`)
	//#endregion

	let hasTagChanges = false
	const tagChangesUnsub = tagCrudStore.subscribe(({ created, updated, deleted }) => {
		hasTagChanges = hasTagChanges || Object.keys(created).length > 0 || Object.keys(updated).length > 0 || Object.keys(deleted).length > 0
	})
	let hasLocationChanges = false
	const locationChangesUnsub = locationCrudStore.subscribe(() => {
		// can't `hasLocationChanges ||` because we may clear the store on plant change
		hasLocationChanges = locationCrudStore.hasChanges()
	})
	let hasAttachmentChanges = false
	const attachmentChangesUnsub = attachmentCrudStore.subscribe(() => {
		// can't `hasAttachmentChanges ||` because we may clear the store on plant change
		hasAttachmentChanges = attachmentCrudStore.hasChanges()
	})
	$: hasUnsavedChanges = hasLocationChanges || hasTagChanges || hasAttachmentChanges || delimiter !== oldDelimiter
	export let plantId: number | null
	export let plants: Plant[]
	export let asr: SvelteAsr
	export let delimiter: string

	let selectedPlant: Plant | null = null

	const oldDelimiter = delimiter

	if (plantId) {
		selectedPlant = plants.find(p => p.id === plantId) ?? null
	} else {
		selectedPlant = null
	}

	$: plantId = selectedPlant?.id ?? null

	function validateLocationsBeforeSave(locationsToValidate: LocationNode[]) {
		const locationsTooLong = locationsToValidate
			.reduce((acc, location) => {
				if (location.location.length > LOCATION_MAX_LENGTH) {
					acc.push(`- ${location.location}`)
				}
				return acc
			}, new Array<string>())
			.join('\r\n')
		if (locationsTooLong.length) {
			// I don't think this abuses interpolation too badly, because locationsTooLong is a bulleted list and LOCATION_MAX_LENGTH is a number
			const message = translate(
				'locations.locationTooLong',
				`Error: The following locations have a code that is too long.

{{locationsTooLong}}

Note: A location's code plus all parents' codes must be less than {{- LOCATION_MAX_LENGTH}} characters total.`,
				{
					LOCATION_MAX_LENGTH,
					locationsTooLong,
				},
			)
			alert(message)
			return false
		}
		return true
	}

	async function save() {
		const locationsToSave: LocationNode[] = locationCrudStore.createdValues.concat(locationCrudStore.updatedValues).map(location => ({
			...location,
			location: computeLocationName(location, delimiter),
		}))

		if (!validateLocationsBeforeSave(locationsToSave)) {
			console.error('Locations not saved due to validation errors')
			return
		}
		if (
			delimiter !== oldDelimiter &&
			confirm(translate('location.confirmChangeDelimieter', 'Change the global location string delimiter from "{{oldDelimiter}}" to "{{delimiter}}"?', { oldDelimiter, delimiter }))
		) {
			await updateDelimiter.mutate({ delimiter })
			if ($updateDelimiter.errors) {
				console.error($updateDelimiter.errors)
				return mediator.call('showMessage', {
					heading: translate('location.errorSavingDelimiter', 'Error Saving Location Delimiter; Locations and Tags Not Saved'),
					message: $updateDelimiter.errors[0].message ?? translate('common:unknownError', 'Unknown error'),
					type: 'danger',
					time: false,
				})
			}
		}

		/** Map the new tags (with no ID) to their new IDs so we can reference them during location save*/
		let newTagNamesToIds: Record<string, number> = {}

		if (hasTagChanges) {
			try {
				if (tagCrudStore.createdValues.length) {
					const res = await createEntityTags.mutate({
						input: tagCrudStore.createdValues.map(tag => {
							return {
								active: tag.active,
								entityType: 'LOCATION',
								name: tag.name,
							}
						}),
					})
					const createdTags = res.data?.createEntityTags
					// Update IDs of newly created tags so we can reference them during location save
					if (createdTags) {
						newTagNamesToIds = createdTags.reduce(
							(acc, tag) => {
								acc[tag.name] = tag.id
								return acc
							},
							{} as Record<string, number>,
						)
						tags = tags.map(tag => {
							if (tag.name in newTagNamesToIds) {
								tag.id = newTagNamesToIds[tag.name]
							}
							return tag
						})
					}
				}

				if (tagCrudStore.updatedValues.length) {
					await updateEntityTags.mutate({
						input: tagCrudStore.updatedValues
							.filter(tag => tag.id)
							.map(tag => {
								return {
									id: tag.id!,
									entityType: 'LOCATION',
									active: tag.active,
									name: tag.name,
								}
							}),
					})
				}

				if (tagCrudStore.deletedValues.length) {
					await deleteEntityTags.mutate({
						ids: tagCrudStore.deletedValues.filter(tag => tag.id).map(tag => tag.id!),
					})
				}
			} catch (err) {
				console.error(err)
				return mediator.call('showMessage', {
					heading: translate('location.errorSavingTags', 'Error Saving Tags; Locations not Saved'),
					message: err && typeof err === 'object' && 'message' in err && typeof err.message === 'string' ? err.message : translate('common:unknownError', 'Unknown error'),
					type: 'danger',
					time: false,
				})
			}
		}

		// Have to link new locations to their new IDs so we can attach attachments to them
		let uuidsToLocations: Record<string, string> = {}
		let locationsToIds: Record<string, number> = {}
		if (locationCrudStore.createdValues.length || locationCrudStore.updatedValues.length || locationCrudStore.deletedValues.length) {
			const locations: NewUpdateLocation[] = locationsToSave.map(location => {
				// We've validated each location's location above this, so we know it's current and not too long
				uuidsToLocations[location.uuid] = location.location
				return {
					active: location.active,
					code: location.code,
					description: location.description || null,
					id: location.id,
					parentLocationId: location.parentLocationId,
					parentLocationUuid: location.parentLocationUuid,
					plantId: location.plantId,
					processZoneId: location.processZone?.id ?? null,
					productProximityId: location.productProximity?.id ?? null,
					tagsToAdd: tagAddRemoveStore.getAddIds(location.uuid, tags, 'id'),
					tagsToRemove: tagAddRemoveStore.getRemoveIds(location.uuid, tags, 'id'),
					testable: location.testable,
					uuid: location.uuid,
				}
			})

			const locationsIdsToDelete = locationCrudStore.deletedValues.map(location => location.id) as number[]

			try {
				if (locations.length) {
					const { data } = await createAndUpdateLocations.mutate({
						locations,
					})
					const savedLocations = data?.createAndUpdateLocations
					if (Array.isArray(savedLocations)) {
						locationsToIds = savedLocations.reduce(
							(acc, location) => {
								acc[location.location] = location.id
								return acc
							},
							{} as Record<string, number>,
						)
					}
				}
				// Delete AFTER update so when we delete a former parent, the children don't get deleted too
				if (locationsIdsToDelete.length) {
					await deleteLocations.mutate({ locationIds: locationsIdsToDelete })
				}
			} catch (err) {
				console.error(err)
				return mediator.call('showMessage', {
					heading: translate('location.errorSavingLocations', 'Error Saving Locations'),
					message: err && typeof err === 'object' && 'message' in err && typeof err.message === 'string' ? err.message : translate('common:unknownError', 'Unknown error'),
					type: 'danger',
					time: false,
				})
			}
		}
		try {
			if (attachmentCrudStore.createdValues.length) {
				// Apparently doing this in a reduce() makes the types get funky, so .filter().map() it is.
				const filesToSave = await Promise.all(
					attachmentCrudStore.createdValues
						.filter(file => (file.locationId ?? locationsToIds[uuidsToLocations[file.locationUuid]]) && file.File)
						.map(async file => {
							const locationId = file.locationId ?? locationsToIds[uuidsToLocations[file.locationUuid]]
							return {
								locationId,
								fileName: file.name,
								// we're doing a filter above to make sure this is defined
								base64String: await b64ify(file.File!),
								public: true,
								rank: file.rank,
							}
						}),
				)

				// Don't have a bulk save endpoint, so send them through 2 at a time
				await pMap(filesToSave, async input => await attachFileToLocation.mutate({ input }), { concurrency: 2 })
			}
			if (attachmentCrudStore.deletedValues.length) {
				const fileIdsByLocationId: Record<number, number[]> = attachmentCrudStore.deletedValues.reduce(
					(acc, file) => {
						if (file.locationId) {
							if (!(file.locationId in acc)) {
							}
							if (acc[file.locationId] && file.fileId) {
								acc[file.locationId].push(file.fileId)
							} else if (file.fileId) {
								acc[file.locationId] = [file.fileId]
							}
						}
						return acc
					},
					{} as Record<number, number[]>,
				)
				await pMap(
					Object.entries(fileIdsByLocationId),
					async ([locationId, fileIds]) => {
						await detachFilesFromLocation.mutate({ locationId: Number(locationId), fileIds })
					},
					{ concurrency: 2 },
				)
			}
			// Don't clear the attachment crud store so the temp file urls can get cleaned up on destroy
		} catch (err) {
			console.error(err)
			return mediator.call('showMessage', {
				heading: translate('location.errorSavingAttachments', 'Error Saving Attachments'),
				message: err && typeof err === 'object' && 'message' in err && typeof err.message === 'string' ? err.message : 'Unknown error',
				type: 'danger',
				time: false,
			})
		}

		// Set this so route guard doesn't trip on reload
		hasUnsavedChanges = false
		asr.go('app.locations.list', { lastSaveTime: Date.now(), lastResetTime: null }, { inherit: true })
	}

	export function canLeaveState() {
		const canLeave = !hasUnsavedChanges || confirm(translate('common:canLeaveState', 'You have unsaved changes. Are you sure you want to leave? All unsaved changes will be lost.'))
		$canLeaveLocationState = canLeave
		return canLeave
	}

	onMount(() => {
		if (plantId) {
			asr.go('app.locations.list', { plantId }, { inherit: false })
		}
	})
	onDestroy(() => {
		tagChangesUnsub()
		locationChangesUnsub()
		attachmentChangesUnsub()
	})
</script>

<div class="card">
	<StateCardHeader
		title={translate('location.locationManagement', 'Locations')}
		icon="shelves"
	>
		<svelte:fragment slot="right">
			<Input
				label={translate('location.delimiterCharacters', 'Delimiter Character(s)')}
				labelClass="mr-2 py-0"
				class="mr-3"
				labelParentClass="form-inline order-1 order-sm-0 mt-2 mt-sm-0"
				maxlength={5}
				style="width: 100px;"
				title={translate(
					'locations.delimiterCharactersTitle',
					'The character(s) used to separate child locations from their parents when constructing the full location string, e.g. "A{{delimiter}}B{{delimiter}}C"',
					{ delimiter },
				)}
				bind:value={delimiter}
			/>
			<SaveResetButton
				disabled={!hasUnsavedChanges}
				resetHref={asr.makePath('app.locations.list', { lastResetTime: Date.now(), lastSaveTime: null }, { inherit: true })}
				{save}
			/>
		</svelte:fragment>
	</StateCardHeader>

	<div class="card-body">
		<uiView></uiView>
	</div>
</div>
