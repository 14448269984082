export default {
    "name": "SaveFavoriteWorkOrders",
    "kind": "HoudiniMutation",
    "hash": "c04584cbd931dcef339c8ceb8372a5117c02427c52e0ab84abf3f3e5a2074518",

    "raw": `mutation SaveFavoriteWorkOrders($workOrders: [UpdateWorkOrder!]!) {
  updateWorkOrders(workOrders: $workOrders) {
    id
    favorite
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "updateWorkOrders": {
                "type": "WorkOrder",
                "keyRaw": "updateWorkOrders(workOrders: $workOrders)",
                "nullable": true,

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "favorite": {
                            "type": "Boolean",
                            "keyRaw": "favorite",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "workOrders": "UpdateWorkOrder"
        },

        "types": {
            "NewSampleValue": {
                "analysisOptionId": "PositiveInt",
                "defaultValue": "String",
                "expiration": "Date",
                "lot": "String",
                "result": "String",
                "totalQuantity": "Float"
            },

            "NewSampleOnUnsavedWorkOrder": {
                "analysisId": "PositiveInt",
                "collectedByUserId": "PositiveInt",
                "comments": "String",
                "due": "DateTime",
                "findings": "String",
                "incubationBegan": "DateTime",
                "incubationEnded": "DateTime",
                "investigationId": "PositiveInt",
                "locationId": "PositiveInt",
                "performed": "DateTime",
                "plantId": "PositiveInt",
                "platesReadByUserId": "PositiveInt",
                "productId": "PositiveInt",
                "productionVolume": "Float",
                "sampleValuesToAdd": "NewSampleValue",
                "scheduled": "DateTime",
                "scheduledAnalysisId": "PositiveInt",
                "sequenceId": "PositiveInt",
                "sourceSampleId": "PositiveInt",
                "status": "DocumentStatus",
                "tagNumber": "String"
            },

            "UpdateSampleValue": {
                "analysisOptionId": "PositiveInt",
                "defaultValue": "String",
                "expiration": "Date",
                "id": "PositiveInt",
                "lot": "String",
                "result": "String",
                "totalQuantity": "Float"
            },

            "UpdateSample": {
                "analysisId": "PositiveInt",
                "collectedByUserId": "PositiveInt",
                "comments": "String",
                "due": "DateTime",
                "findings": "String",
                "id": "PositiveInt",
                "incubationBegan": "DateTime",
                "incubationEnded": "DateTime",
                "investigationId": "PositiveInt",
                "locationId": "PositiveInt",
                "performed": "DateTime",
                "plantId": "PositiveInt",
                "platesReadByUserId": "PositiveInt",
                "productId": "PositiveInt",
                "productionVolume": "Float",
                "sampleValuesToAdd": "NewSampleValue",
                "sampleValuesToUpdate": "UpdateSampleValue",
                "scheduled": "DateTime",
                "scheduledAnalysisId": "PositiveInt",
                "sequenceId": "PositiveInt",
                "sourceSampleId": "PositiveInt",
                "status": "DocumentStatus",
                "tagNumber": "String",
                "userAccessId": "PositiveInt",
                "workOrderId": "PositiveInt"
            },

            "UpdateWorkOrder": {
                "assignedToGroupId": "PositiveInt",
                "due": "DateTime",
                "favorite": "Boolean",
                "id": "PositiveInt",
                "instructions": "String",
                "internalNotes": "String",
                "plantId": "PositiveInt",
                "productBatchId": "PositiveInt",
                "samplesToAdd": "NewSampleOnUnsavedWorkOrder",
                "samplesToRemove": "PositiveInt",
                "samplesToUpdate": "UpdateSample",
                "scheduled": "DateTime",
                "sourceWorkOrderId": "PositiveInt",
                "title": "String",
                "userAccessId": "PositiveInt",
                "verificationDue": "DateTime",
                "verifiedByUserId": "PositiveInt",
                "verifiedOn": "DateTime",
                "workOrderTypeId": "PositiveInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=cd8ae427290a7191f094db4143423c6ead3137ced41a9ef63edbd16efa916d12";