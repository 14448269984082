<script lang="ts">
	import { SamplingHistoryResultsStore, type SampleFilter, type ShAnalyses$result } from '$houdini'
	import type { i18n } from 'i18next'
	import type { Mediator, SvelteAsr } from 'types/common'
	import type { SampleResult, SampleOrderByField } from './results'
	import type { Merge } from 'type-fest'
	import type { SettingValueStore } from 'stores/setting-value'

	import { Table, Td, type Column } from '@isoftdata/svelte-table'
	import Checkbox from '@isoftdata/svelte-checkbox'
	import Button from '@isoftdata/svelte-button'
	import ImageThumbnail from '@isoftdata/svelte-image-thumbnail'
	import ImageViewer from '@isoftdata/svelte-image-viewer'
	import CardFooter from 'components/CardFooter.svelte'
	import Input from '@isoftdata/svelte-input'
	import ReportJobModal from 'components/ReportJobModal.svelte'
	import OptionValueInput from 'components/OptionValueInput.svelte'
	import Fieldset from '@isoftdata/svelte-fieldset'

	import { toZonedTime } from 'date-fns-tz'
	import { getContext, onDestroy } from 'svelte'
	import session from 'stores/session'
	import pMap from 'p-map'
	import { klona } from 'klona'
	import { encode as encodeCsvLine } from 'csv-line'
	import formatDocumentStatus from 'utility/format-document-status'
	import { optionMatchesFilter } from 'utility/analysis-management-helper'

	type Analysis = ShAnalyses$result['analyses']['data'][number]
	type ComputedSampleResult = ReturnType<typeof computeSamplesWithDependencies>[number]

	const { t: translate } = getContext<i18n>('i18next')

	interface Props {
		asr: SvelteAsr
		pageNumber: number
		pageSize: number
		totalItems: number
		sortDirection: 'ASC' | 'DESC'
		sortColumnProperty: string
		results: Array<SampleResult>
		filter: SampleFilter
		tableColumnSortNames: Record<string, SampleOrderByField>
		showOptions: SettingValueStore<boolean>
		showCollectionDetail: SettingValueStore<boolean>
		showTestingDetail: SettingValueStore<boolean>
		showWorkOrderDetail: SettingValueStore<boolean>
		showImageThumbnails: SettingValueStore<boolean>
		showOnlyApplicableThresholds: SettingValueStore<boolean>
		allowShowThresholdsTable: boolean
		selectedOptions: Set<number>
		analysisList: Array<Analysis>
	}

	let {
		asr,
		pageNumber,
		pageSize,
		totalItems,
		sortDirection,
		sortColumnProperty,
		results,
		filter: sampleFilter,
		tableColumnSortNames,
		showOptions,
		showCollectionDetail,
		showTestingDetail,
		showWorkOrderDetail,
		showImageThumbnails,
		showOnlyApplicableThresholds,
		allowShowThresholdsTable,
		selectedOptions,
		analysisList,
	}: Props = $props()

	let isLoadingCsvExport = $state(false)
	let resultTable: Table<ComputedSampleResult> | undefined = $state(undefined)
	let currentPhotoIndex = $state(0)
	let showImageViewer = $state(false)
	let selectedSampleImages: Array<string> = $state([])
	let csvExport: string | null = $state(null)
	let reportJobModal: ReportJobModal | undefined = $state(undefined)

	const analysisIdsInResults = [...new Set(results.map(result => result.analysisId))]

	const maxOptionCount = getMaxOptionCount(analysisIdsInResults)
	const analysisListMap = new Map<number, Analysis>(analysisList?.map(analysis => [analysis.id, analysis]))
	const displayResults = computeSamplesWithDependencies(results, analysisListMap, maxOptionCount)
	const optionsColumns = buildOptionsColumns(displayResults)
	const optionColumnProperties = optionsColumns.map(column => column.property)

	const mediator = getContext<Mediator>('mediator')
	const resultsColumns: Array<Column<ComputedSampleResult>> = [
		{
			property: 'analysis[name]',
			name: translate('common:analysis', 'Analysis'),
			width: '200px',
		},
		{
			property: 'imageCount',
			name: '',
			icon: 'photo-video',
			sortType: false,
			width: '71px',
		},
		{
			property: 'plant[name]',
			name: translate('common:plant', 'Plant'),
			width: '125px',
		},
		{
			property: 'product[name]',
			name: translate('samplingHistory.results.table.column.IngredientOrProduct', 'Ingredient/Product'),
			width: '150px',
		},
		{
			property: 'location[location]',
			name: translate('common:location', 'Location'),
			width: '150px',
		},
		{
			property: 'findings',
			name: translate('workOrder.testingCommentsColumn', 'Testing Comments'),
			width: '150px',
		},
		{
			property: 'comments',
			name: translate('workOrder.samplingCommentsColumn', 'Sampling Comments'),
			width: '150px',
		},
		{
			property: 'status',
			name: translate('common:status', 'Status'),
			width: '80px',
		},
		{
			property: 'tagNumber',
			name: translate('common:tagNumber', 'Tag #'),
			width: '80px',
		},
		// Option/Value columns
		...optionsColumns,
		// Begin Collection Details
		{
			property: 'scheduled',
			name: translate('common:scheduled', 'Scheduled'),
			width: '180px',
		},
		{
			property: 'due',
			name: translate('common:due', 'Due'),
			width: '180px',
		},
		{
			property: 'performed',
			name: translate('common:performedOn', 'Performed On'),
			width: '180px',
		},
		// Begin Testing Details
		{
			property: 'incubationBegan',
			name: translate('common:incubationBegan', 'Incubation Began'),
			sortType: false,
			width: '180px',
		},
		{
			property: 'incubationEnded',
			name: translate('common:incubationEnded', 'Incubation Ended'),
			sortType: false,
			width: '180px',
		},
		{
			property: 'platesReadBy[fullName]',
			name: translate('common:platesReadBy', 'Plates Read By'),
			sortType: false,
			width: '100px',
		},
		// Begin Work Order Details
		{
			property: 'workOrder[title]',
			name: `${translate('common:abbreviations.workorder', 'WO')} ${translate('common:title', 'Title')}`,
			sortType: false,
			width: '150px',
		},
		{
			property: 'workOrder[id]',
			name: `${translate('common:abbreviations.workorder', 'WO')} #`,
			sortType: false,
			width: '75px',
		},
		{
			property: 'workOrder[workOrderType][name]',
			name: `${translate('common:abbreviations.workorder', 'WO')} ${translate('common:type', 'Type')}`,
			sortType: false,
			width: '100px',
		},
		{
			property: 'workOrder[internalNotes]',
			name: translate('common:internalNotes', 'Internal Notes'),
			sortType: false,
			width: '100px',
		},
		{
			property: 'workOrder[instructions]',
			name: `${translate('common:abbreviations.workorder', 'WO')} ${translate('common:instructions', 'Instructions')}`,
			sortType: false,
			width: '100px',
		},
		{
			property: 'workOrder[dateCreated]',
			name: `${translate('common:abbreviations.workorder', 'WO')} ${translate('common:created', 'Created')}`,
			sortType: false,
			width: '180px',
		},
		{
			property: 'workOrder[due]',
			name: `${translate('common:abbreviations.workorder', 'WO')} ${translate('common:due', 'Due')}`,
			sortType: false,
			width: '180px',
		},
		{
			property: 'workOrder[scheduled]',
			name: `${translate('common:abbreviations.workorder', 'WO')} ${translate('common:scheduled', 'Scheduled')}`,
			sortType: false,
			width: '180px',
		},
		{
			property: 'workOrder[assignedToGroup][name]',
			name: translate('samplingHistory.results.table.column.groupAssigned', 'Group Assigned'),
			sortType: false,
			width: '100px',
		},
		{
			property: 'workOrder[verifiedByUser][fullName]',
			name: translate('common:approvedBy', 'Approved By'),
			sortType: false,
			width: '100px',
		},
		{
			property: 'workOrder[verifiedOn]',
			name: translate('common:approvedOn', 'Approved On'),
			sortType: false,
			width: '180px',
		},
		{
			property: 'workOrder[productBatch][name]',
			name: translate('common:batch', 'Batch'),
			sortType: false,
			width: '100px',
		},
	]
	let sortColumn: Column<ComputedSampleResult> | undefined = $derived(resultsColumns.find(column => column.property === sortColumnProperty))
	let woTypeShowTestingDetail = $derived(results.some(result => result?.workOrder?.workOrderType?.showTestingDetail))
	$effect(() => resultTable?.setColumnVisibility(['scheduled', 'due', 'performed'], $showCollectionDetail))
	$effect(() => resultTable?.setColumnVisibility(['incubationBegan', 'incubationEnded', 'platesReadBy[fullName]'], $showTestingDetail))
	$effect(() =>
		resultTable?.setColumnVisibility(
			[
				'workOrder[title]',
				'workOrder[id]',
				'workOrder[workOrderType][name]',
				'workOrder[internalNotes]',
				'workOrder[instructions]',
				'workOrder[dateCreated]',
				'workOrder[due]',
				'workOrder[scheduled]',
				'workOrder[assignedToGroup][name]',
				'workOrder[verifiedByUser][fullName]',
				'workOrder[verifiedOn]',
				'workOrder[productBatch][name]',
			],
			$showWorkOrderDetail,
		),
	)
	$effect(() => resultTable?.setColumnVisibility(optionColumnProperties, $showOptions))

	function getMaxOptionCount(analysisIds: Array<number>) {
		const analysesInResults = analysisList?.filter(analysis => analysisIds.includes(analysis.id)) ?? []
		let maxOptionsCount = Math.max(...analysesInResults.map(analysis => analysis.options.filter(option => selectedOptions.has(option.id)).length))
		return maxOptionsCount > 0 ? maxOptionsCount : 0
	}

	function buildOptionsColumns(samples: Array<ComputedSampleResult>): Array<Column<ComputedSampleResult>> {
		if (!samples.length) {
			return []
		}
		const columns: Array<Column<ComputedSampleResult>> = []
		if (analysisIdsInResults.length === 1) {
			const analysis = analysisListMap.get(analysisIdsInResults[0])
			if (analysis) {
				analysis.options.forEach(option => {
					const svIndex = columns.length
					if (selectedOptions.has(option.id)) {
						columns.push({
							name: option.unit ? `${samples[0].sampleValues[svIndex]?.analysisOption.option} (${option.unit})` : option.option,
							// @ts-expect-error
							property: `sampleValues[${svIndex}][result]`,
							minWidth: '100px',
							width: '200px',
							sortType: false,
						})
					}
				})
			}
		} else {
			for (let i = 0; i < maxOptionCount; i++) {
				columns.push({
					name: translate('workOrder.optionColumn', 'Option {{number}}', { number: i + 1 }),
					// @ts-expect-error I accidentally made number keys illegal, even though I've used it before
					property: `sampleValues[${i}][result]`,
					minWidth: '100px',
					width: '150px',
					sortType: false,
				})
			}
		}
		return columns
	}

	function getDateTimeString() {
		const now = new Date()
		return now.toLocaleString()
	}

	async function generateCsvExport() {
		// Trying to get a CSV for too many results will take forever
		if (totalItems > 1000 && !confirm(`This will generate a CSV file with ${totalItems} rows. Are you sure you want to continue?`)) {
			return
		}

		isLoadingCsvExport = true
		// page # -> results
		let results: Array<SampleResult> = []
		const pages: Record<number, Array<SampleResult>> = {}
		try {
			let currentPage = 1
			await pMap(
				Array.from({ length: Math.ceil(totalItems / pageSize) }, (_, i) => i + 1),
				async pageNumber => {
					if (pageNumber > currentPage) {
						mediator.call('showMessage', {
							heading: translate('samplingHistory.csvExportProgress', 'Loading Data for CSV Export...'),
							message: translate('samplingHistory.csvExportProgressMessage', 'Loading page {{pageNumber}} of {{totalPages}}...', { pageNumber, totalPages: Math.ceil(totalItems / pageSize) }),
							type: 'info',
							time: false,
						})
						currentPage = pageNumber
					}

					const samplesQuery = new SamplingHistoryResultsStore()

					const { data } = await samplesQuery.fetch({
						variables: {
							filter: sampleFilter,
							pagination: { pageSize, pageNumber },
						},
					})

					pages[pageNumber] = data?.samples?.data ?? []
				},
				{ concurrency: 2 },
			)

			results = Object.values(pages).flat()
		} catch (err: any) {
			console.error(err)
			isLoadingCsvExport = false
			mediator.call('showMessage', {
				heading: translate('samplingHistory.csvExportError', 'Error Loading Data for CSV Export'),
				message: err.message,
				type: 'danger',
				time: false,
			})
			return
		}

		const analysisIdsInResults = [...new Set(results.map(result => result.analysisId))]

		let headers: Array<string> = []
		const sampleValueHeaders: Array<string> = []

		const samplesForCsv = results.map((sample, sampleIndex) => {
			const analysis = sample.analysisId ? (analysisListMap.get(sample.analysisId) ?? null) : null

			const output = {
				Analysis: analysis?.name ?? '',
				Plant: sample.plant?.name ?? '',
				Product: sample.product?.name ?? '',
				Location: sample.location?.location ?? '',
				'Location Description': sample.location?.description,
				'Testing Comments': sample.comments,
				'Sampling Comments': sample.findings,
				Status: sample.status,
				Scheduled: formatDate(sample.workOrder?.scheduled),
				Due: formatDate(sample.workOrder?.due),
				'Performed On': sample.performed?.toLocaleString?.(),
				'Performed By': sample.collectedBy?.fullName,
				'Tag #': sample.tagNumber,
				'Testing Began': sample.incubationBegan,
				'Testing Ended': sample.incubationEnded,
				'Tested By': sample.platesReadBy?.fullName || 'Unknown',
			}

			if (sampleIndex === 0) {
				headers = Object.keys(output)
			}

			const sampleValues: Array<string> = []

			const validAnalysisOptionIds = analysis?.options.map(option => option.id) ?? []

			//we have to use our own interator value instead of the sampleValue array's index because the sampleValue may not be in the validAnalysisOptionIds array
			let index = 0
			sample.sampleValues.forEach(sampleValue => {
				if (validAnalysisOptionIds.includes(sampleValue.analysisOptionId)) {
					if (analysisIdsInResults.length > 1) {
						//divide by 2 because for each option we have 2 columns, Label and Value
						if (sampleValueHeaders.length / 2 < index + 1) {
							sampleValueHeaders.push(`Label ${sampleValueHeaders.length + 1}`)
							sampleValueHeaders.push(`SampleResult ${sampleValueHeaders.length + 1}`)
						}
						sampleValues.push(sampleValue.analysisOption.option)
						sampleValues.push(sampleValue.result)
					} else {
						//Here, since we only have a single analysis, we can just use the analysis option names as the column header.
						//only a single column per option(we don't need a label column)
						if (sampleValueHeaders.length < index + 1) {
							sampleValueHeaders.push(sampleValue.analysisOption.option)
						}
						sampleValues.push(sampleValue.result)
					}
					index++
				}
			})

			return [...Object.values(output), ...sampleValues]
		})

		const csv = [[...headers, ...sampleValueHeaders], ...samplesForCsv].map(sample => encodeCsvLine(sample)).join('\n')

		csvExport = URL.createObjectURL(new Blob([csv]))
		isLoadingCsvExport = false

		mediator.call('showMessage', {
			heading: translate('samplingHistory.csvExportReady', 'CSV Generated!'),
			message: translate('samplingHistory.csvExportReadyMessage', 'The CSV file is ready for download.'),
			type: 'success',
			time: 10,
		})
	}

	function formatDate(date: string | null | undefined) {
		return date ? toZonedTime(date, $session.plant.timezone).toLocaleString() : ''
	}

	// Can't be moved to resolve b/c we need the analyses to be loaded first
	function computeSamplesWithDependencies(samples: Array<SampleResult> = [], analysisListMap: Map<number, Analysis>, maxAnalysisOptionsCount) {
		return samples.map((sample: SampleResult | SampleResult) => {
			let { id, sampleValues, workOrder, analysisId, incubationBegan, plant, product, location, incubationEnded, sampleFiles, status, performed, scheduled, due, ...rest } = sample

			const analysis = klona(analysisListMap.get(analysisId))

			type FormattedOption = Merge<Analysis['options'][number], { sampleValue: SampleResult['sampleValues'][number] | null }>
			let options: Array<FormattedOption> = []

			if (analysis) {
				options = analysis.options
					.map((option): FormattedOption => {
						const sampleValue = sampleValues.find(sampleValue => sampleValue.analysisOptionId === option.id)
						return {
							...option,
							sampleValue: sampleValue ?? null,
						}
					})
					.filter(option => selectedOptions.has(option.id))
				// TODO: check if these are load bearing
				if (options.length < maxAnalysisOptionsCount) {
					options = options.concat(Array(maxAnalysisOptionsCount - options.length).fill(null))
				}
			}

			return {
				...rest,
				analysis,
				due: formatDate(due),
				files: sampleFiles.map(({ file }) => file.path),
				id,
				imageCount: sampleFiles.length,
				incubationBegan: formatDate(incubationBegan),
				incubationEnded: formatDate(incubationEnded),
				location,
				options,
				performed: formatDate(performed),
				plant,
				product,
				sampleValues: sampleValues.map(sv => ({
					...sv,
					expiration: formatDate(sv.expiration),
					filledOut: formatDate(sv.filledOut),
					lastModified: formatDate(sv.lastModified),
				})),
				scheduled: formatDate(scheduled),
				status: formatDocumentStatus(translate, status, true),
				thumbnailFile: { path: sampleFiles[0]?.file.path },
				workOrder: workOrder
					? {
							...workOrder,
							displayWorkOrderCreated: formatDate(workOrder.dateCreated),
							displayWorkOrderDue: formatDate(workOrder.due),
							displayWorkOrderScheduled: formatDate(workOrder.scheduled),
							verificationDue: formatDate(workOrder.verificationDue),
							verifiedOn: formatDate(workOrder.verifiedOn),
						}
					: null,
			}
		})
	}

	onDestroy(() => {
		// Revoke CSV object URL when page is destroyed
		// Includes leaving and reloading the state
		if (csvExport) {
			URL.revokeObjectURL(csvExport)
		}
	})
</script>

<div class="card-body">
	<Table
		responsive
		stickyHeader
		columnHidingEnabled
		columnResizingEnabled
		idProp="id"
		rowSelectionIdProp="id"
		localStorageKey="sampling-history-results"
		rows={displayResults}
		columns={resultsColumns}
		{sortDirection}
		{sortColumn}
		parentClass="overflow-y-auto mh-700"
		filterEnabled
		filterPlaceholder={translate('samplingHistory.results.filterPlaceholder', 'Filter Results')}
		rowMatchesFilterMethod={(filter, row, props) => (resultTable?.defaultRowMatchesFilter(filter, row, props) || optionMatchesFilter(filter, row.analysis?.options ?? [])) ?? false}
		totalItemsCount={totalItems}
		perPageCount={pageSize}
		currentPageNumber={pageNumber}
		columnClickedMethod={(column, newSortDirection) => {
			try {
				sortColumnProperty = column.property
				sortDirection = newSortDirection

				asr.go(null, { orderBy: tableColumnSortNames[column.property], sortDirection, pageNumber: 1 }, { inherit: true })
			} catch (err) {
				console.error(err)
			}
			return Promise.resolve()
		}}
		pageChange={context => {
			asr.go(null, { pageNumber: context.pageNumber }, { inherit: true })
		}}
		bind:this={resultTable}
	>
		{#snippet header()}
			<Fieldset
				legendText={translate('common:display', 'Display')}
				style="width: fit-content;"
				class="mb-1"
			>
				<Checkbox
					inline
					label={translate('samplingHistory.results.display.optionsAndValues', 'Options And Values')}
					bind:checked={$showOptions}
				/>
				<Checkbox
					inline
					label={translate('samplingHistory.results.display.collectionDetails', 'Collection Details')}
					bind:checked={$showCollectionDetail}
				/>
				<Checkbox
					inline
					label={translate('samplingHistory.results.display.workOrderDetails', 'Work Order Details')}
					bind:checked={$showWorkOrderDetail}
				/>
				{#if woTypeShowTestingDetail}
					<Checkbox
						inline
						label={translate('samplingHistory.results.display.testingDetails', 'Testing Details')}
						bind:checked={$showTestingDetail}
					/>
				{/if}
				<Checkbox
					inline
					label={translate('samplingHistory.results.display.imageThumbnails', 'Image Thumbnails')}
					bind:checked={$showImageThumbnails}
				/>
			</Fieldset>
		{/snippet}
		{#snippet body({ rows })}
			{#each rows as row (row.id)}
				<tr class="text-nowrap">
					<Td property="analysis[name]">{row.analysis?.name ?? ''}</Td>
					<Td
						property="imageCount"
						style="overflow: hidden; text-overflow: clip;"
					>
						<ImageThumbnail
							noImagePath="images/no-image.png"
							thumbnailFile={row.thumbnailFile}
							showThumbnail={$showImageThumbnails}
							fileCount={row.imageCount}
							context={row.id}
							on:click={() => {
								if (selectedSampleImages !== row.files) {
									currentPhotoIndex = 0
									selectedSampleImages = row.files
								}
								showImageViewer = true
							}}
						></ImageThumbnail>
					</Td>
					<Td property="plant[name]">{row.plant?.name ?? ''}</Td>
					<Td property="product[name]">{row.product?.name ?? ''}</Td>
					<Td property="location[location]">{row.location?.location ?? ''}</Td>
					<Td property="findings">{row.findings}</Td>
					<Td property="comments">{row.comments}</Td>
					<Td property="status">{translate(`common:status${row.status}`, row.status)}</Td>
					<Td property="tagNumber">{row.tagNumber}</Td>

					{#if $showOptions}
						{#each row.options as option, index}
							<Td
								property="sampleValues[{index}][result]"
								class="align-bottom text-wrap"
							>
								{#if option && row.workOrder && option.sampleValue}
									<OptionValueInput
										readonly
										showLabel={analysisIdsInResults.length > 1}
										{allowShowThresholdsTable}
										workOrder={row.workOrder}
										sampleValue={option?.sampleValue}
										sample={row}
										bind:showOnlyApplicableThresholds={$showOnlyApplicableThresholds}
									></OptionValueInput>
								{:else}
									<Input
										readonly
										showLabel={analysisIdsInResults.length > 1}
										label={option?.option ?? ''}
										labelClass="badge"
										value=""
									>
										<svelte:fragment slot="prepend">
											<Button
												disabled
												color="secondary"
												style="min-width: 35.5px"
											/>
										</svelte:fragment>
									</Input>
								{/if}
							</Td>
						{/each}
					{/if}

					<Td property="scheduled">{row.scheduled}</Td>
					<Td property="due">{row.due}</Td>
					<Td property="performed">{row.performed}</Td>
					<Td property="incubationBegan">{row.incubationBegan}</Td>
					<Td property="incubationEnded">{row.incubationEnded}</Td>
					<Td property="platesReadBy[fullName]">{row.platesReadBy ?? ''}</Td>

					<Td property="workOrder[title]">{row.workOrder?.title ?? ''}</Td>
					<Td property="workOrder[id]">
						{#if row.workOrder?.id}
							<a href={asr.makePath('app.work-order.edit', { workOrderId: row.workOrder.id })}>{row.workOrder.id}</a>
						{/if}
					</Td>
					<Td property="workOrder[workOrderType][name]">{row.workOrder?.workOrderType?.name ?? ''}</Td>
					<Td property="workOrder[internalNotes]">{row.workOrder?.internalNotes ?? ''}</Td>
					<Td property="workOrder[instructions]">{row.workOrder?.instructions ?? ''}</Td>
					<Td property="workOrder[dateCreated]">{row.workOrder?.displayWorkOrderCreated ?? ''}</Td>
					<Td property="workOrder[due]">{row.workOrder?.displayWorkOrderDue ?? ''}</Td>
					<Td property="workOrder[scheduled]">{row.workOrder?.displayWorkOrderScheduled ?? ''}</Td>
					<Td property="workOrder[assignedToGroup][name]">{row.workOrder?.assignedToGroup?.name ?? ''}</Td>
					<Td property="workOrder[verifiedByUser][fullName]">{row.workOrder?.verifiedByUser?.fullName ?? ''}</Td>
					<Td property="workOrder[verifiedOn]">{row.workOrder?.verifiedOn ?? ''}</Td>
					<Td property="workOrder[productBatch][name]">{row.workOrder?.productBatch?.name ?? ''}</Td>
				</tr>
			{/each}
		{/snippet}
	</Table>
</div>

<CardFooter>
	{#if csvExport}
		<Button
			outline
			size="sm"
			iconClass="file-csv"
			href={csvExport}
			download="presage-export-{getDateTimeString()}.csv">{translate('common:downloadCSV', 'Download CSV')}</Button
		>
	{:else}
		<Button
			outline
			size="sm"
			iconClass="file-csv"
			isLoading={isLoadingCsvExport}
			on:click={generateCsvExport}
		>
			{#if isLoadingCsvExport}
				{translate('common:generatingCSV', 'Generating CSV')}...
			{:else}
				{translate('common:generateCSV', 'Generate CSV')}
			{/if}
		</Button>
	{/if}
	<Button
		outline
		size="sm"
		iconClass="print"
		on:click={() => {
			const parameters = {
				sample_select: results.map((sample, index) => `SELECT ${sample.id} AS sampleid, ${index} AS \`rank\``).join(' UNION '),
				option_clause: `IN (${results
					.map(sample => sample.sampleValues.map(sv => sv.analysisOptionId))
					.flat()
					.join(', ')})`,
			}
			reportJobModal?.open({
				type: 'Sample Results',
				parameters,
			})
		}}
		>Print
	</Button>
	{#snippet right()}
		{translate('common:samplesCount', '{{count}} Samples', { count: totalItems })}
	{/snippet}
</CardFooter>

<ImageViewer
	bind:currentPhotoIndex
	files={selectedSampleImages}
	bind:show={showImageViewer}
/>

<ReportJobModal bind:this={reportJobModal}></ReportJobModal>
