import component, { type LocationNode } from './Locations.svelte'
import type { MetaAttachment } from 'types/files'
import type { AppContext, DefaultParameters, ResolveParameters } from 'types/common'
import { getSession } from 'stores/session'
import { v4 as uuid } from '@lukeed/uuid'
import showErrorAndRedirect from 'utility/show-error-and-redirect'

import { writable } from 'svelte/store'
import makeCrudStore from '@isoftdata/svelte-store-crud'
import makeAddRemoveStore from 'stores/add-remove-store'

import { LOCATIONS_PAGEStore } from '$houdini'

export type LocationsParams = {
	plantId: string | null
	[other: string]: string | null
}

export default ({ mediator, stateRouter, hasPermission, i18next: { t: translate } }: AppContext) => {
	stateRouter.addState({
		name: 'app.locations',
		route: 'locations',
		querystringParameters: ['lastResetTime', 'lastSaveTime'],
		defaultParameters: {
			plantId: () => getSession()?.siteId?.toString(),
		} satisfies DefaultParameters<LocationsParams>,
		defaultChild: 'list',
		template: component,
		canLeaveState(svelte) {
			// @ts-expect-error
			return svelte.canLeaveState()
		},
		async resolve(_data, parameters: ResolveParameters<LocationsParams>) {
			// Load all non-plant-specific data
			const { data } = await new LOCATIONS_PAGEStore().fetch({
				variables: {
					pagination: {
						pageSize: 0,
					},
					lookup: {
						settingType: 'HIDDEN',
						name: 'Location string delimiter',
						defaultValue: '-',
						category: 'Configuration',
					},
					filter: {
						active: true,
						entityTypes: ['LOCATION'],
					},
				},
			})
			// Data can possibly be null, (like if we have an error) so we need to check for that
			if (!data) {
				console.error('No data was returned from the server.')
				throw showErrorAndRedirect(
					mediator,
					translate('location.errorLoadingLocationManagement', 'Error Loading Location Management'),
					translate('location.noDataWasReturnedFromTheServer', 'No data was returned from the server.')
				)
			}
			const plants = data.plants.data
			const tags = data.entityTags
			const delimiter = data.getGlobalSetting.value

			let plantId: number

			// Desktop checks LOCATION_CAN_VIEW_MAP, but we don't have a map, so I guess check this?
			const plantsWithPermissions = plants.filter(plant => hasPermission('LOCATION_CAN_OPEN_SCREEN', plant.id))

			if ((!parameters.plantId || parameters.plantId === 'null') && plantsWithPermissions.length > 0) {
				plantId = plantsWithPermissions[0].id
			} else if (parameters.plantId && parameters.plantId !== 'null' && plantsWithPermissions.length > 0) {
				plantId = parseInt(parameters.plantId, 10)
			} else {
				throw showErrorAndRedirect(
					mediator,
					translate('location.errorLoadingLocationManagement', 'Error Loading Location Management'),
					translate('location.noPlantsFound', 'No plants were found. Make sure you have the proper permissions at at least one plant.')
				)
			}

			const clipboard = writable<LocationNode | null>(null)

			const uuidTags = tags.map(tag => ({ ...tag, uuid: uuid() }))

			return {
				clipboard,
				delimiter,
				plants: plantsWithPermissions,
				plantId,
				tags: uuidTags,
				tagAddRemoveStore: makeAddRemoveStore(),
				tagCrudStore: makeCrudStore<(typeof uuidTags)[0], 'uuid'>('uuid'),
				locationCrudStore: makeCrudStore<LocationNode, 'uuid'>('uuid'),
				hasUnsavedChanges: writable(false),
				attachmentCrudStore: makeCrudStore<MetaAttachment, 'uuid'>('uuid'),
				/** Track whether we've already said we can leave the top level state, so we don't check again in the child states */
				canLeaveLocationState: writable(false),
			}
		},
	})
}
