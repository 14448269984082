import type { State, ResolveParameters } from 'types/common'
import type { WorkOrderSearchResult } from 'components/WorkOrderSearch.svelte'

import { graphql } from '$houdini'
import { getSession } from 'stores/session'
import makeCrudStore from '@isoftdata/svelte-store-crud'
import component from 'components/WorkOrderListAsr.svelte'
import { stringToBoolean } from '@isoftdata/utility-string'
import { datesFromRange } from '@isoftdata/utility-date-time'

const workOrderListDataQuery = graphql(`
	query WorkOrderList($pagination: PaginatedInput) {
		groups {
			data {
				id
				name
			}
		}
		workOrderTypes {
			data {
				name
				id
			}
		}
		plants(pagination: $pagination) {
			data {
				id
				name
				code
			}
		}
	}
`)

type Parameters = {
	toDate: string
	showOpen: string
	fromDate: string
	showClosed: string
	pageNumber: string
	pageSize: string
	orderByColumn: string
	orderByDirection: string
}

const defaultDateRange = 'Last 30 Days'

const options: Omit<State, 'name' | 'route'> = {
	querystringParameters: ['fromDate', 'toDate', 'plantId', 'showClosed', 'showOpen', 'pageNumber', 'pageSize', 'orderByColumn', 'orderByDirection', 'workOrderTypeId', 'groupId'],
	defaultParameters: {
		plantId: () => getSession()?.siteId?.toString(),
		showClosed: 'false',
		showOpen: 'true',
		pageSize: '100',
		pageNumber: '1',
		fromDate: () => datesFromRange(defaultDateRange).from,
		toDate: () => datesFromRange(defaultDateRange).to,
	},
	template: component,
	async resolve(_data, { plantId, workOrderTypeId, groupId, showClosed, showOpen, fromDate, toDate, pageNumber, pageSize, orderByColumn, orderByDirection }: ResolveParameters<Parameters>) {
		const { data } = await workOrderListDataQuery.fetch()

		return {
			plants: data?.plants.data ?? [],
			workOrderTypes: data?.workOrderTypes.data ?? [],
			groups: data?.groups.data ?? [],
			plantId: parseInt(plantId, 10) || null,
			groupId: parseInt(groupId, 10) || null,
			workOrderTypeId: parseInt(workOrderTypeId, 10) || null,
			showClosed: stringToBoolean(showClosed),
			showOpen: stringToBoolean(showOpen),
			dateRange: { from: fromDate, to: toDate },
			workOrderFavoritesStore: makeCrudStore<WorkOrderSearchResult, 'id'>('id'),
			pageNumber: parseInt(pageNumber, 10) ?? 1,
			pageSize: parseInt(pageSize, 10) ?? 100,
			orderByColumn,
			orderByDirection,
		}
	},
}

export default options
