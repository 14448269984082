import type { AppContext, DefaultParameters, ResolveParameters, SvelteDomApi } from 'client/types/common'

import Timezone from 'timezone-enum'
import { v4 as uuid } from '@lukeed/uuid'
import { getSession } from 'stores/session'
import component from './Plant.svelte'
import makeCrudStore from '@isoftdata/svelte-store-crud'
import { graphql } from '$houdini'
import showErrorAndRedirect from 'utility/show-error-and-redirect'
import type { MetaTag } from 'components/TagSelection.svelte'

const gqlPagninationAllPages = { pagination: { pageSize: 0, pageNumber: 1 } }

type PlantConfigurationParameters = {
	lastResetTime: string | null
	lastSavedTime: string | null
}

export default ({ mediator, stateRouter, hasPermission, i18next: { t } }: AppContext) => {
	stateRouter.addState({
		name: 'app.configuration.plant',
		route: 'plant',
		querystringParameters: ['lastResetTime', 'lastSavedTime'],
		defaultParameters: {
			lastResetTime: null,
			lastSavedTime: null,
		} satisfies DefaultParameters<PlantConfigurationParameters>,
		template: component,
		canLeaveState(domApi) {
			// @ts-expect-error
			return domApi.canLeaveState()
		},
		async resolve(_data, _parameters: ResolveParameters<PlantConfigurationParameters>) {
			const { authorizedPlantIDs, siteId } = getSession()

			if (!authorizedPlantIDs.some(id => hasPermission('CONFIGURATION_CAN_CONFIGURE_PLANTS', id))) {
				// If you dont have permission to view at least one plant, redirect to home
				showErrorAndRedirect(mediator, t('common:accessDenied', 'Access denied'), t('configuration.plant.configurePlantPermissionError', 'You do not have permission to configure this plant.'), {
					name: 'app.home',
				})
			}

			const res = await plantConfigurationData.fetch({
				variables: {
					entityTagFilter: { active: true, entityTypes: ['PLANT'] },
				},
			})

			const plants = res.data?.plants.data ?? []
			const tags = res.data?.entityTags ?? []
			const states = res.data?.states ?? []

			const loggedInPlantId = siteId
			const nonPrivatePlants = plants.filter(plant => !plant.private || loggedInPlantId === plant.id)

			const mappedTags = tags.map(tag => {
				return {
					...tag,
					uuid: uuid(),
				}
			})

			const mappedPlants = nonPrivatePlants.map(plant => {
				return {
					...plant,
					tags: mappedTags.filter(tag => plant.tags?.some(plantTag => plantTag.id === tag.id)) || [],
					uuid: uuid(),
					deleted: false,
					dirty: false,
				}
			})

			const selectedPlantIndex = mappedPlants.findIndex(plant => plant.id === loggedInPlantId) ?? 0

			return {
				plants: mappedPlants,
				timezones: Object.values(Timezone),
				tags: mappedTags,
				states,
				selectedPlant: mappedPlants[selectedPlantIndex],
				hasPermission,
				tagCrudStore: makeCrudStore<MetaTag, 'uuid'>('uuid'),
			}
		},
	})
}

export const plantConfigurationData = graphql(`
	query PlantConfigurationData($entityTagFilter: EntityTagFilter!) {
		plants(pagination: { pageSize: 0 }) {
			data {
				id
				name
				code
				private
				street
				city
				state
				zip
				country
				phone
				fax
				timezone
				tags {
					id
					name
					entityType
					active
				}
				getPlantUsageCount {
					analyseCount
					productCount
					tagCount
				}
			}
		}
		states {
			abbreviation: stateAbbreviation
			name: stateName
		}
		entityTags(filter: $entityTagFilter) {
			id
			name
			entityType
			active
		}
	}
`)

export const zipCodeLookupQuery = graphql(`
	query getCityStateAbbvCountryFromZip($zipcode: String!) {
		getCityStateAbbvCountryFromZip(zipcode: $zipcode) {
			city
			country
			stateAbbreviation
		}
	}
`)

export const createPlantsMutation = graphql(`
	mutation CreatePlants($plants: [PlantCreation!]!) {
		createPlants(plants: $plants) {
			id
			name
			code
			private
			street
			city
			state
			zip
			country
			phone
			fax
			timezone
			tags {
				id
				name
				entityType
				active
			}
			getPlantUsageCount {
				analyseCount
				productCount
				tagCount
			}
		}
	}
`)

export const updatePlantsMutation = graphql(`
	mutation UpdatePlants($plants: [PlantUpdate!]!) {
		updatePlants(plants: $plants) {
			id
			name
			code
			private
			street
			city
			state
			zip
			country
			phone
			fax
			timezone
			tags {
				id
				name
				entityType
				active
			}
			getPlantUsageCount {
				analyseCount
				productCount
				tagCount
			}
		}
	}
`)

export const deletePlantsMutation = graphql(`
	mutation DeletePlants($ids: [ID!]!) {
		deletePlants(ids: $ids)
	}
`)
