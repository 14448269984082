import type { ResultStatus$options, Acceptability$options, BoundaryType$options } from '$houdini'
import type { IconName } from '@fortawesome/fontawesome-common-types'
import type { ButtonColors } from '@isoftdata/utility-bootstrap'
import type { i18n } from 'i18next'

export function makeValueAcceptabilityMap(translate: i18n['t']) {
	return new Map<AcceptabilityOption, AcceptabilityObject>([
		[
			'ALLOWED',
			{
				label: translate('common:acceptable', 'Acceptable'),
				colorClass: 'success',
				icon: 'check',
				graphValue: 'Allowed',
			},
		],
		[
			'WARNING',
			{
				label: translate('common:marginal', 'Marginal'),
				colorClass: 'warning',
				icon: 'exclamation',
				graphValue: 'Warning',
			},
		],
		[
			'ERROR',
			{
				label: translate('common:unacceptable', 'Unacceptable'),
				colorClass: 'danger',
				icon: 'exclamation-triangle',
				graphValue: 'Error',
			},
		],
		[
			'OUT_OF_BOUNDS',
			{
				label: translate('common:invalid', 'Invalid'),
				colorClass: 'info',
				icon: 'ban',
				graphValue: 'Out of Bounds',
			},
		],
		[
			'NOT_CALCULATED',
			{
				label: translate('common:notCalculated', 'Not Calculated'),
				colorClass: 'secondary',
				icon: undefined,
				graphValue: 'Not Calculated',
			},
		],
	])
}
export type ValueAcceptabilityMap = Map<AcceptabilityOption, AcceptabilityObject>

export const acceptabilityToResultStatus: Record<Acceptability$options, ResultStatus$options> = {
	ALLOWED: 'ALLOWED',
	NOT_CALCULATED: 'NOT_CALCULATED',
	INVALID: 'OUT_OF_BOUNDS',
	MARGINAL: 'WARNING',
	UNACCEPTABLE: 'ERROR',
}

export const boundaryTypeToResultStatus: Record<BoundaryType$options, ResultStatus$options> = {
	ALLOWED: 'ALLOWED',
	BOUNDARY: 'OUT_OF_BOUNDS',
	MARGINAL: 'WARNING',
	UNACCEPTABLE: 'ERROR',
}

export type AcceptabilityOption = ResultStatus$options
export type AcceptabilityObject = {
	label: string
	colorClass: ButtonColors
	icon?: IconName
	graphValue: string
}

// DO NOT TRANSLATE THIS MAP
export const inverseValueAcceptabilityMap = new Map<string, AcceptabilityOption>([
	['Allowed', 'ALLOWED'],
	['Warning', 'WARNING'],
	['Error', 'ERROR'],
	['Out of Bounds', 'OUT_OF_BOUNDS'],
	['Not Calculated', 'NOT_CALCULATED'],
])
