import type { AppContext, SvelteDomApi } from 'types/common'
import component from './ConfigureInvestigation.svelte'

import { graphql } from '$houdini'
import { getSession } from 'stores/session'
import { v4 as uuid } from '@lukeed/uuid'
import showErrorAndRedirect from 'utility/show-error-and-redirect'
import { LoadInvestigationTriggers$result as InvestigationTriggerResult } from '$houdini'

export type {
	InvestigationTriggerResultStatus$options as ResultStatus,
	InvestigationTriggerPeriodType$options as PeriodType,
	DefaultStatus$options as DefaultStatus,
	RetestMode$options as RetestMode,
	ExpansionMethod$options as ExpansionMethod,
} from '$houdini'

export type Plant = InvestigationTriggerResult['plants']['data'][number]
export type Analysis = InvestigationTriggerResult['analyses']['data'][number]
export type Location = InvestigationTriggerResult['locations']['data'][number]
export type SeverityClass = InvestigationTriggerResult['severityClasses']['data'][number]
export type Product = InvestigationTriggerResult['products']['data'][number]
export type WorkOrderType = InvestigationTriggerResult['workOrderTypes']['data'][number]

export type PlantData = {
	plantName: string
	analysesInPlant: Analysis[]
	locationsInPlant: Location[]
	severityClassesInPlant: SeverityClass[]
	productsInPlant: Product[]
	workOrderTypesInPlant: WorkOrderType[]
}

export type InvestigationTrigger = Omit<InvestigationTriggerResult['investigationTriggers']['data'][number], 'id' | 'description' | 'analysisOptionId' | 'analysisOption'> &
	PlantData & {
		id: number | null
		description: string
		analysisId: number | null
		analysisName: string | null
		analysisOptionsInAnalysis: { id: number; option: string }[]
		analysisOptionId: number | null
		analysisOption: Omit<InvestigationTriggerResult['investigationTriggers']['data'][number]['analysisOption'], 'analysisId'> | null
		analysisOptionName: string | null
		locationName: string | null
		severityClassName: string | null
		productName: string | null
		retestAnalysisName: string | null
		retestWorkOrderTypeName: string | null
		uuid: string
	}

export default ({ mediator, stateRouter, i18next: { t: translate } }: AppContext) => {
	stateRouter.addState({
		name: 'app.investigation.configure',
		route: 'configure',
		querystringParameters: ['plantId'],
		defaultParameters: {
			plantId: () => getSession()?.siteId?.toString() || null,
		},
		template: component,
		canLeaveState(svelte) {
			// @ts-expect-error
			return svelte.canLeaveState()
		},
		async resolve(_data, parameters) {
			const plantId = parameters.plantId ? Number(parameters.plantId) : null
			const { data } = await investigationTriggers.fetch({
				variables: {
					investigationTriggerFilter: {
						plantId,
					},
					analysesFilter: {
						activeOnly: true,
					},
					locationsFilter: {
						activeOnly: true,
					},
					productsFilter: {
						active: true,
					},
					pagination: {
						pageNumber: 1,
						pageSize: 0,
					},
				},
			})

			if (!data) {
				console.error('No data was returned from the server.')
				throw showErrorAndRedirect(
					mediator,
					translate('configureInvestigation.errorLoadingConfigureInvestigationHeading', 'Error Loading Configure Investigation'),
					translate('configureInvestigation.noDataReturnErrorMessage', 'No data was returned from the server.')
				)
			}

			const session = getSession()

			// get only the plants the user has access to, if they have PLANT permission
			let plants = data.plants.data
			const configureInvestigationPermission = session?.user.permissions.INVESTIGATION_CAN_CONFIGURE

			if (!configureInvestigationPermission || configureInvestigationPermission === 'NONE') {
				throw showErrorAndRedirect(
					mediator,
					translate('configureInvestigation.errorLoadingConfigureInvestigationHeading', 'Error Loading Configure Investigation'),
					translate('configureInvestigation.noPermissionErrorMessage', 'You do not have permission to configure investigations.')
				)
			} else if (configureInvestigationPermission === 'PLANT') {
				const authorizedPlantIDs = session?.authorizedPlantIDs
				plants = data.plants.data.filter(plant => authorizedPlantIDs.includes(plant.id))
			}
			const selectedPlant = plants.find(plant => plant.id === plantId)

			const analyses = data.analyses.data
			const locations = data.locations.data.filter(location => location.testable)
			const severityClasses = data.severityClasses.data
			const products = data.products.data
			const workOrderTypes = data.workOrderTypes.data
			const allInvestigationTypes = data.allInvestigationTypes

			const analysisDataMap: Map<number, Analysis> = new Map(analyses.map(analysis => [analysis.id, analysis]))
			const locationDataMap: Map<number, Location> = new Map(locations.map(location => [location.id, location]))
			const severityClassDataMap: Map<number, SeverityClass> = new Map(severityClasses.map(severityClass => [severityClass.id, severityClass]))
			const productDataMap: Map<number, Product> = new Map(products.map(product => [product.id, product]))
			const workOrderTypeDataMap: Map<number, WorkOrderType> = new Map(workOrderTypes.map(workOrderType => [workOrderType.id, workOrderType]))

			const plantDataMap: Map<number | null, PlantData> = new Map([
				[
					null,
					{
						plantName: 'All Plants',
						analysesInPlant: analyses,
						locationsInPlant: locations,
						severityClassesInPlant: severityClasses,
						productsInPlant: products,
						workOrderTypesInPlant: workOrderTypes,
					},
				],
			])

			plants.forEach(plant => {
				const analysisInPlant = analyses.filter(analysis => analysis.inUseAtPlantIDs.includes(plant.id))
				const locationInPlant = locations.filter(location => location.plantId === plant.id)
				const severityClassInPlant = severityClasses.filter(severityClass => severityClass.plantId === plant.id)
				const productInPlant = products.filter(product => product.inUseAtPlants?.some(p => p.id === plant.id))
				const workOrderTypeInPlant = workOrderTypes.filter(workOrderType => workOrderType.inUseAtPlantIDs?.includes(plant.id))
				plantDataMap.set(plant.id, {
					plantName: plant.name,
					analysesInPlant: analysisInPlant,
					locationsInPlant: locationInPlant,
					severityClassesInPlant: severityClassInPlant,
					productsInPlant: productInPlant,
					workOrderTypesInPlant: workOrderTypeInPlant,
				})
			})

			const investigationTriggersData: InvestigationTrigger[] = data.investigationTriggers.data.map(investigationTrigger => {
				const selectedPlantId = investigationTrigger.plantId ?? null
				const plantData = plantDataMap.get(selectedPlantId)
				const analysisData = analysisDataMap.get(investigationTrigger.analysisOption.analysisId)
				const locationName = investigationTrigger.locationId ? locationDataMap.get(investigationTrigger.locationId)?.location ?? null : null
				const severityClassName = investigationTrigger.severityClassId ? severityClassDataMap.get(investigationTrigger.severityClassId)?.name ?? null : null
				const productName = investigationTrigger.productId ? productDataMap.get(investigationTrigger.productId)?.name ?? null : null
				const retestAnalysisName = investigationTrigger.retestAnalysisId ? analysisDataMap.get(investigationTrigger.retestAnalysisId)?.name ?? null : null
				const retestWorkOrderTypeName = investigationTrigger.retestWorkOrderTypeId ? workOrderTypeDataMap.get(investigationTrigger.retestWorkOrderTypeId)?.name ?? null : null

				return {
					...investigationTrigger,
					plantName: plantData?.plantName ?? '',
					analysesInPlant: plantData?.analysesInPlant ?? [],
					locationsInPlant: plantData?.locationsInPlant ?? [],
					severityClassesInPlant: plantData?.severityClassesInPlant ?? [],
					productsInPlant: plantData?.productsInPlant ?? [],
					workOrderTypesInPlant: plantData?.workOrderTypesInPlant ?? [],
					analysisId: investigationTrigger.analysisOption?.analysisId || null,
					analysisName: analysisData?.name ?? null,
					analysisOptionsInAnalysis: analysisData?.options ?? [],
					analysisOptionName: investigationTrigger.analysisOption?.option || null,
					locationName,
					severityClassName,
					productName,
					retestAnalysisName,
					retestWorkOrderTypeName,
					uuid: uuid(),
				}
			})

			return {
				investigationTriggers: investigationTriggersData,
				plants,
				selectedPlant,
				allInvestigationTypes,
				plantDataMap,
				analysisDataMap,
				locationDataMap,
				severityClassDataMap,
				productDataMap,
				workOrderTypeDataMap,
			}
		},
	})
}

const investigationTriggers = graphql(`
	query LoadInvestigationTriggers(
		$investigationTriggerFilter: InvestigationTriggerFilter
		$analysesFilter: AnalysisFilter
		$locationsFilter: LocationFilter
		$productsFilter: ProductFilter
		$pagination: PaginatedInput
	) {
		investigationTriggers(filter: $investigationTriggerFilter, pagination: $pagination) {
			data {
				id
				description
				active
				resultStatus
				resultCount
				period
				periodType
				defaultComments
				defaultInvestigationType
				defaultStatus
				retestMode
				daysUntilInvestigationDue
				expansionMethod
				expansionSize
				sampleCollectionCount
				retestDocumentCount
				daysBetweenRetests
				analysisOptionId
				analysisOption {
					id
					option
					analysisId
				}
				plantId
				locationId
				severityClassId
				productId
				retestWorkOrderTypeId
				retestAnalysisId
			}
		}
		plants(pagination: $pagination) {
			data {
				id
				name
				code
			}
		}
		analyses(filter: $analysesFilter, pagination: $pagination) {
			data {
				id
				name
				inUseAtPlantIDs
				active
				options {
					id
					option
				}
			}
		}
		locations(filter: $locationsFilter, pagination: $pagination) {
			data {
				id
				location
				plantId
				testable
			}
		}
		severityClasses(pagination: $pagination) {
			data {
				id
				name
				plantId
			}
		}
		products(filter: $productsFilter, pagination: $pagination) {
			data {
				id
				name
				active
				inUseAtPlants {
					id
				}
			}
		}
		workOrderTypes(pagination: $pagination) {
			data {
				id
				name
				inUseAtPlantIDs
			}
		}
		allInvestigationTypes
	}
`)
