import { type AmAnalysisOptionChoices$result, type AmAnalysisOptions$result, type AnalysisManagementData$result, type ConstraintType$options, graphql, type ValueType$options } from '$houdini'
import type { Simplify, WritableDeep } from 'type-fest'
import type { i18n } from 'i18next'

import makeCrudStore, { doCrud, newCrudMap, type CrudMap, type CrudType, type EntityIdKey, type EntityIdValue } from '@isoftdata/svelte-store-crud'
import { v4 as uuid } from '@lukeed/uuid'
import { derived, writable, type Updater, type Writable } from 'svelte/store'

import hasPermission from 'utility/has-permission'
import { stringToBoolean } from '@isoftdata/utility-string'
import { klona } from 'klona'
import makeAddRemoveStore from 'stores/add-remove-store'
import showErrorAndRedirect from 'utility/show-error-and-redirect'
import type { Mediator } from 'types/common'
import { getSession } from 'stores/session'
import userLocalWritable from '@isoftdata/svelte-store-user-local-writable'

export type Analysis = Simplify<
	WritableDeep<Omit<AnalysisManagementData$result['analyses']['data'][number], 'id' | ' $fragments'>> & {
		id: number | null
		inUse: boolean
		uuid: string
		// houdini puts these fields on but we don't, so make them optional, but don't omit them so I konw they're there
		' $fragments'?: unknown
		__typename?: string
	}
>
export type Group = AnalysisManagementData$result['groups']['data'][number]
export type Plant = AnalysisManagementData$result['plants']['data'][number]
export type Product = AnalysisManagementData$result['products']['data'][number]
export type SeverityClass = AnalysisManagementData$result['severityClasses']['data'][number]
export type EntityTag = Simplify<
	WritableDeep<
		Omit<AnalysisManagementData$result['tags'][number], 'id'> & {
			id: number | null
			uuid: string
		}
	>
>

export type Option = Simplify<
	WritableDeep<
		Omit<AmAnalysisOptions$result['analysisOptions']['data'][number], 'analysisId' | 'rules' | 'id' | ' $fragments'> & {
			id: number | null
			/** Choices are not loaded until the option is selected */
			choices?: Array<Choice>
			/** New Options on a new Analysis won't have analysisId */
			analysisId: number | null
			uuid: string
			rules: Array<Rule>
			' $fragments'?: unknown
		}
	>
>
export type Rule = Simplify<
	Omit<WritableDeep<NonNullable<AmAnalysisOptions$result['analysisOptions']['data'][number]['rules']>[number]>, 'tags' | 'id' | 'analysisOptionId' | 'created' | ' $fragments'> & {
		created: Date | null
		tags: Array<EntityTag>
		id: number | null
		analysisOptionId: number | null
		uuid: string
		' $fragments'?: unknown
	}
>
export type Choice = Simplify<Omit<AmAnalysisOptionChoices$result['analysisOptionChoices']['data'][number], 'id' | 'analysisOptionId' | ' $fragments'>> & {
	id: number | null
	analysisOptionId: number | null
	uuid: string
	' $fragments'?: unknown
}

export type TagMap = Record<'PRODUCT' | 'PLANT' | 'LOCATION', Record<string, EntityTag | undefined>>

export type TagStore = Writable<TagMap>

export function canEditPlantSpecificFields(plantId: number) {
	return hasPermission('ANALYSIS_CAN_EDIT_ANALYSES', plantId)
}

export function makeCanEditGlobalFieldsMap(analyses: Array<Pick<Analysis, 'id' | 'inUseAtPlantIDs'>>, thisPlantId: number) {
	return analyses.reduce((acc: Map<number, boolean>, analysis) => {
		if (analysis.id) {
			acc.set(
				analysis.id,
				analysis.inUseAtPlantIDs.every(plantId => hasPermission('ANALYSIS_CAN_EDIT_ANALYSES', plantId))
			)
		}

		return acc
	}, new Map([[-1, hasPermission('ANALYSIS_CAN_EDIT_ANALYSES', thisPlantId)]])) // -1 = selected plant only
}

export function canEditGlobalFields(map: Map<number, boolean>, analysisId: number | null = null) {
	return map.get(analysisId ?? -1) ?? false
}

export function makePermissionFunctions(analyses: Array<Pick<Analysis, 'id' | 'inUseAtPlantIDs'>>, plantId: number) {
	const canEditGlobalFieldsMap = makeCanEditGlobalFieldsMap(analyses, plantId)
	return {
		canEditPlantSpecificFields: () => canEditPlantSpecificFields(plantId),
		canEditGlobalFields: (analysisId: number | null) => canEditGlobalFields(canEditGlobalFieldsMap, analysisId),
	}
}
// #region export Constants
export const analysisTemplate = Object.freeze<Omit<Analysis, 'uuid' | ' $fragments'>>({
	id: null,
	name: '',
	inUseAtPlantIDs: [] as Array<number>,
	active: true,
	requireAuthentication: false,
	category: '',
	visibleGroup: null,
	groupSamples: false,
	testingPeriod: 0,
	sampleTagPrintQuantity: 1,
	testingTagPrintQuantity: 1,
	options: [],
	analysisType: 'TESTING',
	batchUnit: '',
	batchVolume: null,
	instructions: '',
	inUse: true,
	createdProduct: null,
})

export const defaultConfirmNavigationModalData = Object.freeze({
	show: false,
	stateParmeter: '',
	stateParameterValue: null,
})

// options constants
export const defaultOptionData = Object.freeze<Omit<Option, 'uuid' | ' $fragments'>>({
	active: true,
	analysisId: null,
	choices: [],
	defaultType: 'FIXED',
	defaultValue: null,
	entryMethod: 'USER_ENTERED',
	id: null,
	inventoryMode: 'NONE',
	option: '',
	product: null,
	rank: 0,
	rules: [],
	thresholdType: 'FIXED',
	informational: false,
	requiredToClose: false,
	requiredToPerform: false,
	unit: '',
	valueType: 'NUMBER',
})

export const defaultChoiceData = Object.freeze<Omit<Choice, 'uuid' | ' $fragments'>>({
	active: true,
	analysisOptionId: null,
	boundaryType: 'UNACCEPTABLE',
	choice: '',
	constraint: 'NONE',
	id: null,
	plantId: null,
	product: null,
	severityClass: null,
})

export const defaultRuleData = Object.freeze<Simplify<Omit<Rule, 'uuid'>>>({
	active: true,
	analysisOptionId: null,
	created: null,
	description: '',
	id: null,
	outcome: 'HIDDEN',
	restriction: 'PRESENT',
	tags: [],
})

export const defaultDefaultOptionValueModalData = Object.freeze({
	show: false,
	defaultValue: '',
	testResult: '',
	options: [],
	loading: false,
	errorMessage: '',
	errorDetail: '',
})

export const defaultTestThresholdsModalData = Object.freeze({
	show: false,
	loading: true,
	severityClassId: null,
	plantId: null,
	productId: null,
	testValue: '',
	testResult: '',
	thresholds: [],
})

export const defaultTagSelectionModalData = Object.freeze({
	show: false,
	loading: true,
	selectedPlantTagIndex: null,
	selectedProductTagIndex: null,
	selectedLocationTagIndex: null,
	ruleName: '',
	plantTags: [],
	productTags: [],
	locationTags: [],
})

// Options State Constants

// TODO some of these might be able to be deduped
export function makeTranslatedConstants(translate: i18n['t']) {
	return {
		thresholdsWhenMap: Object.freeze({
			ALLOWED: translate('analysisManagement.allowedWhen', 'Allowed when'),
			BOUNDARY: translate('analysisManagement.invalidWhen', 'Invalid when'),
			MARGINAL: translate('analysisManagement.marginalWhen', 'Marginal when'),
			UNACCEPTABLE: translate('analysisManagement.unacceptableWhen', 'Unacceptable when'),
		}),
		constraintsMap: Object.freeze({
			MINIMUM: translate('common:above', 'Above'),
			MAXIMUM: translate('common:below', 'Below'),
			NOT_EQUAL: translate('common:doesNotEqual', "Doesn't equal"),
			NONE: translate('common:equals', 'Equals'),
		}),
		thresholdsMap: Object.freeze({
			ALLOWED: translate('common:allowed', 'Allowed'),
			BOUNDARY: translate('common:invalid', 'Invalid'),
			INVALID: translate('common:invalid', 'Invalid'),
			MARGINAL: translate('common:marginal', 'Marginal'),
			NOT_CALCULATED: translate('common:notCalculated', 'common:Not Calculated'),
			UNACCEPTABLE: translate('common:unacceptable', 'Unacceptable'),
		}),
		outcomeMap: Object.freeze({
			NONE: translate('common:none', 'None'),
			INACTIVE: translate('common:inactive', 'Inactive'),
			HIDDEN: translate('common:hidden', 'Hidden'),
			READONLY: translate('common:readonly', 'Readonly'),
			REQUIRED_TO_PERFORM: translate('common:requiredToPerform', 'Required to Perform'),
			REQUIRED_TO_CLOSE: translate('common:requiredToClose', 'Required to Close'),
		}),
	}
}

export function optionMatchesFilter(filter: string, options: Array<{ option: string }>) {
	return options.some(option => option.option.toUpperCase().indexOf(filter.toUpperCase()) > -1)
}

export function getConstraintsForValueType(constraintsMap: Record<ConstraintType$options, string>, valueType: ValueType$options | undefined) {
	const constraints = Object.entries(constraintsMap) as Array<[ConstraintType$options, string]>
	// boolean, choice, text - eq/neq
	if (valueType === 'BOOLEAN' || valueType === 'CHOICE' || valueType === 'TEXT') {
		return constraints.filter(([key]) => key === 'NONE' || key === 'NOT_EQUAL')
	}
	return constraints
}

// #endregion
// #region ParentCrudStore
/** Maps Parent Uuid -> A Crud object */
type ParentCrud<T> = Record<string, CrudMap<T> | undefined>

/** Create a store for tracking CRUD changes to entities, grouped by their parent's uuid*/
export function makeParentCrudStore<T>() {
	let storeValue: ParentCrud<T> = {}
	let hasChanges = false

	const key = 'uuid' as EntityIdKey<T>

	const { subscribe, set } = writable<ParentCrud<T>>(storeValue)
	const entitySubscribers = new Set<(parentUuid: string, entity: T) => void>()

	function updateStore(cb: Updater<ParentCrud<T>>) {
		storeValue = cb(storeValue)
		set(storeValue)
		hasChanges = true
	}

	function updateEntity(parentUuid: string, entity: T, crudType: CrudType) {
		updateStore(store => {
			if (!store[parentUuid]) {
				store[parentUuid] = newCrudMap<T>()
			}
			store[parentUuid] = doCrud<T, CrudMap<T>>(store[parentUuid], crudType, entity, key)
			return store
		})
		entitySubscribers.forEach(cb => cb(parentUuid, entity))
	}

	function getEntityId(entity: T): EntityIdValue<T> {
		return entity[key] as EntityIdValue<T>
	}

	const isCreated = (parentUuid: string, entity: T) => parentUuid in storeValue && !!storeValue[parentUuid] && getEntityId(entity) in storeValue[parentUuid].created
	const isUpdated = (parentUuid: string, entity: T) => parentUuid in storeValue && !!storeValue[parentUuid] && getEntityId(entity) in storeValue[parentUuid].updated
	const isDeleted = (parentUuid: string, entity: T) => parentUuid in storeValue && !!storeValue[parentUuid] && getEntityId(entity) in storeValue[parentUuid].deleted
	const isDirty = (parentUuid: string, entity: T) => isCreated(parentUuid, entity) || isUpdated(parentUuid, entity)

	return {
		subscribe,
		create: (parentUuid: string, entity: T) => updateEntity(parentUuid, entity, 'created'),
		update: (parentUuid: string, entity: T) => updateEntity(parentUuid, entity, 'updated'),
		delete: (parentUuid: string, entity: T) => updateEntity(parentUuid, entity, 'deleted'),
		unDelete: (parentUuid: string, entity: T) => updateEntity(parentUuid, entity, 'undelete'),
		isCreated,
		isUpdated,
		isDeleted,
		isDirty,
		hasChanges: () => hasChanges,
		/** Subscribe to changes to the store, but the callback gives the parentUuid / entity that was changed rather than the whole store */
		entitySubscribe: (cb: (parentUuid: string, entity: T) => void) => {
			entitySubscribers.add(cb)
			return () => entitySubscribers.delete(cb)
		},
		clear: () => {
			set({})
			hasChanges = false
		},
	}
}

export type ParentCrudStore<T> = ReturnType<typeof makeParentCrudStore<T>>
// #endregion
// #region Resolve Functions

graphql(`
	fragment AnalysisReturnData on Analysis {
		id
		analysisType
		name
		active
		testingPeriod
		category
		visibleGroup {
			id
			name
		}
		groupSamples
		requireAuthentication
		instructions
		createdProduct {
			id
			name
		}
		batchVolume
		batchUnit
		inUseAtPlantIDs
	}
`)

const analysesDataQuery = graphql(`
	query AnalysisManagementData(
		$analysesFilter: AnalysisFilter
		$entityTagFilter: EntityTagFilter!
		$productsFilter: ProductFilter
		$severityClassFilter: SeverityClassFilter
		$viewAsPlantId: ID!
		$pagination: PaginatedInput
	) {
		analyses(filter: $analysesFilter, pagination: $pagination) {
			data {
				...AnalysisReturnData
				sampleTagPrintQuantity(viewAsPlantId: $viewAsPlantId)
				testingTagPrintQuantity(viewAsPlantId: $viewAsPlantId)
				# Used for filtering analyses only
				options {
					id
					option
				}
			}
		}
		categories: analysisCategories
		groups(pagination: $pagination) {
			data {
				name
				id
			}
		}
		plants(pagination: $pagination) {
			data {
				id
				name
				code
			}
		}
		tags: entityTags(filter: $entityTagFilter) {
			active
			entityType
			id
			name
		}
		products(filter: $productsFilter, pagination: $pagination) {
			data {
				id
				name
				active
				category
				productType
				barcodeFormat
			}
		}
		severityClasses(filter: $severityClassFilter, pagination: $pagination) {
			data {
				id
				name
				description
				default
				plantId
			}
		}
	}
`)
const gqlPagninationAllPages = { pagination: { pageSize: 0, pageNumber: 1 } }

export async function loadAnalysesData(parameters: Record<string, string>, mediator: Mediator, recipesMode: boolean) {
	const plantId = parseInt(parameters.plantId, 10)
	const showUnused = stringToBoolean(parameters.showUnused)
	const showInactive = stringToBoolean(parameters.showInactive)

	let selectedAnalysisId: number | null = !parameters.selectedAnalysisId || parameters.selectedAnalysisId === 'null' ? null : parseInt(parameters.selectedAnalysisId, 10)

	const { data } = await analysesDataQuery.fetch({
		variables: {
			entityTagFilter: {
				entityTypes: ['PLANT', 'LOCATION', 'PRODUCT'],
			},
			viewAsPlantId: plantId.toString(),
			analysesFilter: {
				plantIds: showUnused || !plantId ? null : [plantId],
				activeOnly: !showInactive,
				type: recipesMode ? 'RECIPE' : 'TESTING',
			},
			productsFilter: {
				activeOnly: false,
				plantIds: [plantId],
			},
			severityClassFilter: {
				plantIds: [plantId],
			},
			...gqlPagninationAllPages,
		},
	})

	if (!data) {
		throw showErrorAndRedirect(mediator, 'No data was returned from the server', 'woops')
	}

	const {
		analyses: { data: analyses },
		groups: { data: groups },
		plants: { data: plants },
		categories,
		tags,
		products: { data: products },
		severityClasses: { data: severityClasses },
	} = data

	selectedAnalysisId = selectedAnalysisId ?? analyses[0]?.id ?? null
	const mappedAnalyses: Array<Analysis> = analyses.map(analysis => ({
		...analysis,
		visibleGroup: analysis.visibleGroup ?? null,
		inUse: analysis.inUseAtPlantIDs.includes(plantId),
		uuid: uuid(),
	}))

	mappedAnalyses.push({
		...klona(analysisTemplate),
		analysisType: recipesMode ? 'RECIPE' : 'TESTING',
		inUseAtPlantIDs: [plantId],
		inUse: true,
		uuid: uuid(),
		batchVolume: recipesMode ? 1 : null,
	})

	const tagMap = (tags ?? []).reduce(
		(acc: TagMap, tag) => {
			if (tag.entityType === 'PLANT' || tag.entityType === 'LOCATION' || tag.entityType === 'PRODUCT') {
				const newTag = { ...tag, uuid: uuid() }
				acc[tag.entityType][newTag.uuid] = newTag
			}

			return acc
		},
		{
			PRODUCT: {},
			PLANT: {},
			LOCATION: {},
		}
	)
	const tagStore = writable(tagMap)

	function deriver(entityType: keyof TagMap) {
		return ($tagStore: TagMap) =>
			Object.values($tagStore[entityType]).reduce((acc: Record<string, string>, tag) => {
				if (tag) {
					acc[tag.name] = tag.uuid
				}
				return acc
			}, {})
	}

	const plantTagUuids = derived(tagStore, deriver('PLANT'))
	const locationTagUuids = derived(tagStore, deriver('LOCATION'))
	const productTagUuids = derived(tagStore, deriver('PRODUCT'))

	// Make sure we've selected an analysis of the right type
	let selectedAnalysis = mappedAnalyses.find(analysis => analysis.id === selectedAnalysisId) ?? null
	if (!selectedAnalysis || selectedAnalysis.analysisType !== (recipesMode ? 'RECIPE' : 'TESTING')) {
		selectedAnalysis = mappedAnalyses[0]
		selectedAnalysisId = selectedAnalysis?.id ?? null
	}
	const selectedAnalysisUuid = selectedAnalysisId ? selectedAnalysis?.uuid ?? null : null

	const { canEditGlobalFields, canEditPlantSpecificFields: computeCanEditPlantSpecificFields } = makePermissionFunctions(analyses, plantId)

	const session = getSession()

	return {
		canEditGlobalFields,
		computeCanEditPlantSpecificFields,
		analyses: mappedAnalyses,
		categories,
		groups,
		selectedPlant: plants.find(plant => plant.id === plantId),
		plantId,
		plants,
		selectedAnalysisId,
		selectedAnalysisUuid,
		tagStore,
		plantTagUuids,
		locationTagUuids,
		productTagUuids,
		// used in Options state
		products,
		severityClasses,
		// oh crud
		analysisCrudStore: makeCrudStore<Analysis, 'uuid'>('uuid'),
		optionCrudStore: makeParentCrudStore(),
		choiceCrudStore: makeParentCrudStore(),
		ruleCrudStore: makeParentCrudStore(),
		tagCrudStore: makeCrudStore<EntityTag, 'uuid'>('uuid'),
		showInactive: userLocalWritable(session.userAccountId, 'analysisManagementShowInactive', showInactive),
		showUnused: userLocalWritable(session.userAccountId, 'analysisManagementShowUnused', showUnused),
		tagAddRemoveStore: makeAddRemoveStore(),
		recipesMode,
		hasUnsavedChanges: writable(false),
		stateName: recipesMode ? 'app.product-management.recipes' : 'app.analysis-management.analyses',
	}
}

graphql(`
	fragment AnalysisManagementChoiceData on AnalysisOptionChoice {
		active
		analysisOptionId
		plantId
		constraint
		boundaryType
		choice
		id
		product {
			name
			id
		}
		severityClass {
			id
			name
		}
	}
`)

graphql(`
	fragment AnalysisManagementOptionData on AnalysisOption {
		id
		active
		analysisId
		option
		unit
		valueType
		defaultValue
		defaultType
		entryMethod
		thresholdType
		requiredToPerform
		requiredToClose
		informational
		inventoryMode
		rank
		product {
			name
			id
			barcodeFormat
		}
		rules {
			id
			analysisOptionId
			active
			restriction
			outcome
			description
			created
			tags {
				id
				name
				active
				entityType
			}
		}
	}
`)

const analysisOptionsQuery = graphql(`
	query AmAnalysisOptions($filter: AnalysisOptionFilter) {
		analysisOptions(filter: $filter, pagination: { pageSize: 0, pageNumber: 1 }) {
			data {
				...AnalysisManagementOptionData
			}
		}
	}
`)

const analysisOptionChoicesQuery = graphql(`
	query AmAnalysisOptionChoices($filter: AnalysisOptionChoiceFilter) {
		analysisOptionChoices(filter: $filter) {
			data {
				...AnalysisManagementChoiceData
			}
		}
	}
`)

export async function loadOptionsData(parameters: Record<string, string>) {
	const selectedAnalysisId = !parameters.selectedAnalysisId || parameters.selectedAnalysisId === 'null' ? null : parseInt(parameters.selectedAnalysisId, 10)
	const plantId = parseInt(parameters.plantId, 10)

	let analysisOptions: Array<Option> = []

	if (selectedAnalysisId) {
		const { data } = await analysisOptionsQuery.fetch({
			variables: {
				filter: {
					analysisIds: [selectedAnalysisId],
				},
			},
		})

		analysisOptions = (data?.analysisOptions.data ?? []).map((option): Option => {
			return {
				...option,
				choices: [
					{
						...klona(defaultChoiceData),
						analysisOptionId: option.id,
						plantId,
						uuid: uuid(),
					},
				],
				rules: (
					option.rules?.map((rule): Rule => {
						return {
							...rule,
							// uuids will be set for real in onMount
							tags: rule.tags?.map(tag => ({ ...tag, uuid: '' })) ?? [],
							created: new Date(rule.created),
							uuid: uuid(),
						}
					}) ?? []
				).concat([
					{
						...klona(defaultRuleData),
						analysisOptionId: option.id,
						uuid: uuid(),
					},
				]),
				uuid: uuid(),
			}
		})
	}

	analysisOptions.push({
		...klona(defaultOptionData),
		analysisId: selectedAnalysisId,
		rank: analysisOptions.length + 1,
		uuid: uuid(),
		rules: [
			{
				...klona(defaultRuleData),
				uuid: uuid(),
			},
		],
		choices: [
			{
				...klona(defaultChoiceData),
				plantId,
				uuid: uuid(),
			},
		],
	})

	const selectedOptionIndex = 0 // This will always be 0 because we don't persist the selected option, and we'll always have at least one (empty) option
	const selectedOptionId = analysisOptions[0]?.id ?? null

	if (selectedOptionId) {
		const { data } = await analysisOptionChoicesQuery.fetch({
			variables: {
				filter: {
					optionId: selectedOptionId,
					plantId,
				},
			},
		})
		analysisOptions[selectedOptionIndex].choices = (data?.analysisOptionChoices.data ?? [])
			.map((choice): Choice => ({ ...choice, uuid: uuid() }))
			.concat([
				{
					...klona(defaultChoiceData),
					plantId,
					uuid: uuid(),
				},
			])
	}

	return {
		selectedAnalysisId,
		selectedAnalysisUuid: parameters.selectedAnalysisUuid,
		selectedOptionIndex,
		selectedOptionUuid: analysisOptions[selectedOptionIndex]?.uuid ?? null,
		analysisOptions,
	}
}

// #endregion
