<script
	context="module"
	lang="ts"
>
	import type { SvelteAsr, Mediator } from 'types/common'
	import type { i18n } from 'i18next'
	import type { ClientSession } from 'stores/session'
	import type {
		AlertSubscription,
		AlertTemplate,
		UserAccount,
		Schedule,
		Group,
		Analysis,
		Plant,
		Product,
		WorkOrderType,
		InvestigationRule,
		PhoneCarrier,
		LocationData,
	} from 'utility/alert-subscription-helper'
	import type { SaveResetProps } from '../configuration'

	import { buildTranslatedHelpers, updateAlertSubscription } from 'utility/alert-subscription-helper'
	import { graphql } from '$houdini'
	import Icon from '@isoftdata/svelte-icon'
	//end of module context
</script>

<script lang="ts">
	import { getContext } from 'svelte'
	const mediator = getContext<Mediator>('mediator')
	const { t: translate } = getContext<i18n>('i18next')

	const { alertTypeMap, alertTimingMap, alertMethodMap, resultStatusOptions } = buildTranslatedHelpers(translate)

	//Svelte components
	import Table, { Td } from '@isoftdata/svelte-table'
	import Button from '@isoftdata/svelte-button'
	import Checkbox from '@isoftdata/svelte-checkbox'
	import AddEditModal from './AddEditModal.svelte'

	export let asr: SvelteAsr
	export let permissions: Record<string, boolean>
	export let showOnlyActive: boolean = true
	export let showOnlyMine: boolean = true
	export let session: ClientSession
	// We don't need to use the master save button, so hide it
	export let saveResetProps: SaveResetProps
	$saveResetProps = null

	let addEditModalComponent: AddEditModal
	let alertSubscriptionActiveCheckboxDisabled: number[] = []

	//Business-specific Data
	export let alertSubscriptions: AlertSubscription[]
	export let analyses: Analysis[]
	export let plants: Plant[]
	export let products: Product[]
	export let workOrderTypes: WorkOrderType[]
	export let groups: Group[]
	export let schedules: Schedule[] = []
	export let investigationRules: InvestigationRule[]
	export let alertTemplates: AlertTemplate[]
	export let phoneCarriers: PhoneCarrier[]
	export let userAccounts: UserAccount[]
	export let scheduleMap: Map<number, Schedule>
	export let userAccountMap: Map<number, UserAccount>
	export let alertTemplateMap: Map<number, AlertTemplate>
	export let plantMap: Map<number, Plant>
	export let analysisMap: Map<number, Analysis>
	export let productMap: Map<number, Product>
	export let workOrderTypeMap: Map<number, WorkOrderType>
	export let groupMap: Map<number, Group>
	export let investigationRuleMap: Map<number, InvestigationRule>
	export let locationsMap: Map<number, LocationData>

	const columns = Object.freeze([
		{
			property: 'active',
			title: translate('configuration.alertSubscription.table.columns.activeTitle', 'Defines if the alert subscription is active and will be triggered'),
			name: translate('common:active', 'Active'),
			defaultSortColumn: showOnlyMine || undefined,
			defaultSortDirection: 'DESC',
			align: 'center',
		},
		{
			property: 'assignedToUser',
			title: translate(
				'configuration.alertSubscription.tableTitle.columns.assignedToUser',
				'User the alert subscription is assigned to. By default, new alert subscriptions will be assigned to your user account',
			),
			name: translate('common:assignedTo', 'Assigned To'),
		},
		{
			property: 'description',
			title: translate('configuration.alertSubscription.table.columns.descriptionTitle', 'A short description of the alert subscription'),
			name: translate('common:description', 'Description'),
		},
		{
			property: 'type',
			title: translate(
				'configuration.alertSubscription.table.columns.typeTitle',
				'Type of the alert. One of the following: Investigation, Sample, Sample Value, Sample Value Summary, User Authentication, User Authentication Summary, or Work Order',
			),
			name: translate('common:type', 'Type'),
			defaultSortColumn: showOnlyMine || undefined,
		},
		{
			property: 'timing',
			title: translate(
				'configuration.alertSubscription.table.columns.timingTitle',
				'Timing of the alert. One of the following: Created, Opened, Closed, Modified, Deleted, Scheduled, Expiration Approaching, or Verification Expiration Approaching',
			),
			name: translate('common:timing', 'Timing'),
		},
		{
			property: 'method',
			title: translate('configuration.alertSubscription.table.columns.methodTitle', 'Method in which the alert will be sent. One of the following: Email, Text, or Dashboard'),
			name: translate('common:sendVia', 'Send Via'),
		},
		{
			property: 'sendToString',
			title: translate('configuration.alertSubscription.table.columns.sendToTitle', 'Phone number(s) or email address(es) which the alert will get sent to'),
			name: translate('common:sendTo', 'Send To'),
		},
		{
			property: 'template',
			title: translate('configuration.alertSubscription.table.columns.templateTitle', 'Name of the message template'),
			name: translate('common:template', 'Template'),
		},
		{
			property: 'plant',
			title: translate('configuration.alertSubscription.table.columns.plantTitle', 'Alert will trigger for this plant'),
			name: translate('common:plant', 'Plant'),
		},
		{
			property: 'analysis',
			title: translate('configuration.alertSubscription.table.columns.analysisTitle', 'Alert will trigger for this analysis'),
			name: translate('common:analysis', 'Analysis'),
		},
		{
			property: 'location',
			title: translate('configuration.alertSubscription.table.columns.locationTitle', 'Alert will trigger for this location'),
			name: translate('common:location', 'Location'),
		},
		{
			property: 'product',
			title: translate('configuration.alertSubscription.table.columns.productTitle', 'Alert will trigger for this product'),
			name: translate('common:product', 'Product'),
		},
		{
			property: 'workOrderType',
			title: translate('configuration.alertSubscription.table.columns.workOrderTypeTitle', 'Alert will trigger for this work order type'),
			name: translate('common:WOType', 'WO Type'),
		},
		{
			property: 'group',
			title: translate('configuration.alertSubscription.table.columns.groupTitle', 'Alert will trigger for this group'),
			name: translate('common:group', 'Group'),
		},
		{
			property: 'resultStatus',
			title: translate('configuration.alertSubscription.table.columns.resultStatusTitle', 'Alert will trigger for this result status'),
			name: translate('common:result', 'Result'),
		},
		{
			property: 'includedInfo',
			title: translate(
				'configuration.alertSubscription.table.columns.includedInfoTitle',
				'Additional information that will be sent in the alert. Failed, Marginal, and Acceptable are possible options.',
			),
			name: translate('common:send', 'Send'),
		},
	])

	function getDisplayColumns(columns, anyAlertsHaveInvestigationRule) {
		let displayColumns = [...columns]

		//Only show the investigation rule column if any of the alerts have an investigation rule
		if (anyAlertsHaveInvestigationRule) {
			displayColumns = [
				...displayColumns,
				{
					property: 'investigationRule',
					name: translate('common:investigationRule', 'Investigation Rule'),
					title: translate('configuration.alertSubscription.table.columns.investigationRuleTitle', 'The title of the investigation rule'),
				},
			]
		}

		return displayColumns
	}

	function getDisplayAlertSubscriptions(alertSubscriptions: AlertSubscription[]) {
		//Only map in here. This needs to return the same number of items as the alertSubscriptions array
		return alertSubscriptions.map(alertSubscription => {
			let extraTimingInfo = ''

			if (alertSubscription.timing === 'SCHEDULED' && alertSubscription.scheduleId) {
				extraTimingInfo = scheduleMap.get(alertSubscription.scheduleId)?.name ?? ''
			} else if (alertSubscription.timing && ['EXPIRATION_APPROACHING', 'VERIFICATION_EXPIRATION_APPROACHING'].includes(alertSubscription.timing) && alertSubscription.hours) {
				extraTimingInfo = `${alertSubscription.hours} hour ${alertSubscription.tense.toLowerCase()}`
			}

			const alertType = alertSubscription.type ? alertTypeMap.get(alertSubscription.type) : undefined
			const alertTimingName = alertSubscription?.timing ? (alertTimingMap.get(alertSubscription.timing)?.name ?? '') : ''
			const hasValidSendMethod = alertSubscription.message.method && ['EMAIL', 'TEXT'].includes(alertSubscription.message.method)
			const sendTo = hasValidSendMethod ? [...alertSubscription.message.emailAddress, ...alertSubscription.message.phone.map(phone => phone.number)] : []

			return {
				id: alertSubscription.id,
				type: alertType?.name ?? '',
				active: alertSubscription.active,
				timing: extraTimingInfo ? `${alertTimingName}: ${extraTimingInfo}` : (alertTimingName ?? ''),
				assignedToUser: alertSubscription.assignedToUserAccountId ? userAccountMap.get(alertSubscription.assignedToUserAccountId)?.name : '',
				subject: alertSubscription.message.title,
				description: alertSubscription.description,
				body: alertSubscription.message.body,
				method: alertMethodMap.get(alertSubscription.message.method)?.name ?? '',
				sendTo,
				sendToString: sendTo.join(' '),
				template: alertSubscription.message?.templateId ? alertTemplateMap.get(alertSubscription.message?.templateId)?.name : '',
				plant: alertSubscription.filters?.plantId ? plantMap.get(alertSubscription.filters?.plantId)?.code : '',
				analysis: alertSubscription.filters?.analysisId ? analysisMap.get(alertSubscription.filters?.analysisId)?.name : '',
				location: alertSubscription?.filters?.locationId ? locationsMap.get(alertSubscription.filters?.locationId)?.location : '',
				product: alertSubscription.filters?.productId ? productMap.get(alertSubscription.filters?.productId)?.name : '',
				workOrderType: alertSubscription.filters?.workOrderTypeId ? workOrderTypeMap.get(alertSubscription.filters?.workOrderTypeId)?.name : '',
				group: alertSubscription.filters?.groupId ? groupMap.get(alertSubscription.filters?.groupId)?.name : '',
				resultStatus: alertSubscription.filters.resultStatus ? (resultStatusOptions[alertSubscription.filters.resultStatus]?.name ?? '') : '',
				investigationRule: alertSubscription.filters?.investigationTriggerId ? investigationRuleMap.get(alertSubscription.filters?.investigationTriggerId)?.description : '',
				schedule: alertSubscription.scheduleId ? scheduleMap.get(alertSubscription.scheduleId)?.name : '',
				includedInfo: [
					alertSubscription.message.send.failed ? (alertType?.category === 'Data' ? 'Failed' : 'Denied') : '',
					alertSubscription.message.send.marginal ? 'Marginal' : '',
					alertSubscription.message.send.acceptable ? (alertType?.category === 'Data' ? 'Acceptable' : 'Granted') : '',
				].filter(v => v),
				originalAlertSubscription: alertSubscription,
			}
		})
	}

	const showOnlyMineChange = (event: Event) => asr.go(null, { showOnlyMine: (event.target as HTMLInputElement).value }, { inherit: true })
	const showOnlyActiveChange = (event: Event) => asr.go(null, { showOnlyActive: (event.target as HTMLInputElement).value }, { inherit: true })

	function newAlertSubscription() {
		addEditModalComponent.showModal()
	}

	function editAlertSubscription(alertSubscription: AlertSubscription) {
		addEditModalComponent.showModal(alertSubscription)
	}

	function cloneAlertSubscription(alertSubscription: AlertSubscription) {
		addEditModalComponent.showModal(alertSubscription, true)
	}

	function alertSubscriptionCreated(savedAlertSubscription: AlertSubscription) {
		alertSubscriptions = [...alertSubscriptions, savedAlertSubscription]
		selectedAlertSubscriptionId = savedAlertSubscription.id
	}

	function alertSubscriptionUpdated(savedAlertSubscription: AlertSubscription) {
		const index = alertSubscriptions.findIndex(alertSubscription => alertSubscription.id === savedAlertSubscription.id)
		if (index > -1) {
			alertSubscriptions[index] = savedAlertSubscription
			selectedAlertSubscriptionId = savedAlertSubscription.id
		}
	}

	const deleteAlertSubscriptionMutation = graphql(`
		mutation DeleteAlertSubscriptions($ids: [PositiveInt!]!) {
			deleteAlertSubscriptions(ids: $ids)
		}
	`)

	async function deleteAlertSubscription(alertSubscription: AlertSubscription) {
		if (alertSubscription.id && confirm(translate('configuration.alertSubscription.confirmDeleteAlertSubscription', 'Are you sure you want to delete this alert subscription?'))) {
			try {
				await deleteAlertSubscriptionMutation.mutate({ ids: [alertSubscription.id] })

				selectedAlertSubscriptionId = undefined
				const index = alertSubscriptions.findIndex(alertSubscription => alertSubscription.id === selectedAlertSubscriptionId)
				alertSubscriptions.splice(index, 1)
				alertSubscriptions = alertSubscriptions
			} catch (err) {
				console.error(err)
				mediator.call('showMessage', {
					heading: translate('configuration.alertSubscription.errorDeletingAlertSubscription', 'Error Deleting Alert Subscription'),
					message: err && typeof err === 'object' && 'message' in err && typeof err.message === 'string' ? err.message : translate('common:unknownError', 'Unknown error'),
					type: 'danger',
				})
			}
		}
	}

	async function activeChecked(event: Event, alertSubscriptionId: number) {
		const newActiveState: boolean = (event.target as HTMLInputElement).checked
		let alertSubscriptionIndex = alertSubscriptions.findIndex(alertSubscription => alertSubscription.id === alertSubscriptionId)

		//Disable the checkbox while the mutation is happening async
		alertSubscriptionActiveCheckboxDisabled = [...alertSubscriptionActiveCheckboxDisabled, alertSubscriptionId]
		try {
			const { data } = await updateAlertSubscription.mutate({
				input: {
					id: alertSubscriptionId,
					active: newActiveState,
				},
			})

			if (data) {
				alertSubscriptions[alertSubscriptionIndex].active = data.savedAlertSubscription.active
			}
		} catch (err) {
			console.error($updateAlertSubscription.errors)
			mediator.call('showMessage', {
				heading: translate('configuration.alertSubscription.errorTogglingActiveFlag', 'Error Toggling The Active Flag'),
				message: err && typeof err === 'object' && 'message' in err && typeof err.message === 'string' ? err.message : translate('common:unknownError', 'Unknown error'),
				type: 'danger',
			})
		} finally {
			alertSubscriptionActiveCheckboxDisabled = alertSubscriptionActiveCheckboxDisabled.filter(id => id !== alertSubscriptionId)
		}
	}

	function computeSelectedAlertSubscription(alertSubscriptionId: number | undefined, _alertSubscriptions: AlertSubscription[]): AlertSubscription | undefined {
		return alertSubscriptionId ? alertSubscriptions.find(alertSubscription => alertSubscription.id === alertSubscriptionId) : undefined
	}

	function alertSubscriptionClicked(alertSubscription: AlertSubscription) {
		selectedAlertSubscriptionId = selectedAlertSubscriptionId === alertSubscription.id ? undefined : alertSubscription.id
	}

	let selectedAlertSubscriptionId: number | undefined = undefined
	let selectedAlertSubscription: AlertSubscription | undefined = undefined

	$: anyAlertsHaveInvestigationRule = alertSubscriptions.some(alertSubscription => alertSubscription.filters?.investigationTriggerId)
	$: displayColumns = getDisplayColumns(columns, anyAlertsHaveInvestigationRule)
	$: displayAlertSubscriptions = getDisplayAlertSubscriptions(alertSubscriptions)
	$: selectedAlertSubscription = computeSelectedAlertSubscription(selectedAlertSubscriptionId, alertSubscriptions)
</script>

{#if permissions.canOpenScreen}
	<Table
		columns={displayColumns}
		rows={displayAlertSubscriptions}
		responsive
		filterEnabled
		idProp="id"
		stickyHeader
		parentClass="mh-60vh"
		columnHidingEnabled
	>
		{#snippet header()}
			<div class="m-0 p-0">
				<div class="form-row mb-2">
					{#if permissions.canManageAlertSubscriptions}
						<div class="col-auto">
							<span>{translate('common:show', 'Show')}:</span>
							<Checkbox
								type="radio"
								trueLabel={translate('common:onlyMine', 'Only Mine')}
								falseLabel={translate('common:all', 'All')}
								checked={showOnlyMine}
								on:change={showOnlyMineChange}
							/>
						</div>
					{/if}
					<div class="col-auto">
						<Checkbox
							type="radio"
							trueLabel={translate('common:onlyActive', 'Only Active')}
							falseLabel={translate('common:all', 'All')}
							checked={showOnlyActive}
							on:change={showOnlyActiveChange}
						/>
					</div>
				</div>
			</div>
		{/snippet}
		{#snippet children({ row })}
			<tr
				id="alert-subscription-row-{row.id}"
				class="cursor-pointer"
				class:table-primary={selectedAlertSubscriptionId == row.id}
				on:click={() => alertSubscriptionClicked(row.originalAlertSubscription)}
			>
				<Td
					stopPropagation
					property="active"
				>
					<Checkbox
						checked={row.active}
						disabled={row.id ? alertSubscriptionActiveCheckboxDisabled.includes(row.id) : true}
						on:change={event => {
							if (row.id) {
								activeChecked(event, row.id)
							}
						}}
					/>
				</Td>
				<Td property="assignedToUser">{row.assignedToUser}</Td>
				<Td property="description">{row.description}</Td>
				<Td property="type">{row.type}</Td>
				<Td property="timing">{row.timing}</Td>
				<Td property="method">{row.method}</Td>
				<Td property="sendToString">
					{#if Array.isArray(row.sendTo) && row.sendTo.length}
						<ul class="pl-3 mb-0">
							{#each row.sendTo as destination}
								<li>{destination}</li>
							{/each}
						</ul>
					{/if}
				</Td>
				<Td
					property="template"
					class={!row.template ? 'font-italic' : ''}>{row.template || 'Custom Message'}</Td
				>
				<Td property="plant">{row.plant}</Td>
				<Td property="analysis">{row.analysis}</Td>
				<Td property="location">{row.location}</Td>
				<Td property="product">{row.product}</Td>
				<Td property="workOrderType">{row.workOrderType}</Td>
				<Td property="group">{row.group}</Td>
				<Td property="resultStatus">
					{#if row.resultStatus}
						{row.resultStatus}
					{:else}
						<i>{translate('common:any', 'Any')}</i>
					{/if}
				</Td>
				<Td property="includedInfo">
					{#if row.includedInfo.length}
						<ul class="pl-3 mb-0">
							{#each row.includedInfo as info}
								<li>{info}</li>
							{/each}
						</ul>
					{/if}
				</Td>
				{#if anyAlertsHaveInvestigationRule}
					<Td property="investigationRule">
						<span class:font-italic={!row.investigationRule}>{row.investigationRule ? row.investigationRule : 'N/A'}</span>
					</Td>
				{/if}
			</tr>
		{/snippet}
	</Table>
	<div class="form-row card-footer mt-2 ml-n3 mr-n3 border-bottom">
		<div class="col-12 col-sm-auto">
			<Button
				iconClass="plus"
				size="sm"
				outline
				color="success"
				class="mb-2 mb-sm-0 w-100"
				on:click={() => newAlertSubscription()}>{translate('configuration.alertSubscriptions.addAlertSubscription', 'New Alert Subscription')}...</Button
			>
		</div>
		<div class="col-4 col-sm-auto">
			<Button
				class="w-100"
				size="sm"
				outline
				color="primary"
				iconClass="pencil"
				style="min-width: 90px;"
				on:click={() => {
					if (selectedAlertSubscription) {
						editAlertSubscription(selectedAlertSubscription)
					}
				}}
				disabled={!selectedAlertSubscription}>{translate('common:edit', 'Edit')}...</Button
			>
		</div>
		<div class="col-4 col-sm-auto">
			<Button
				class="w-100"
				size="sm"
				outline
				color="primary"
				iconClass="clone"
				style="min-width: 90px;"
				disabled={!selectedAlertSubscription}
				on:click={() => {
					if (selectedAlertSubscription) {
						cloneAlertSubscription(selectedAlertSubscription)
					}
				}}>{translate('common:clone', 'Clone')}...</Button
			>
		</div>
		<div class="col-4 col-sm-auto">
			<Button
				class="w-100"
				size="sm"
				outline
				color="danger"
				iconClass="trash"
				style="min-width: 90px;"
				disabled={!selectedAlertSubscriptionId}
				on:click={() => {
					if (selectedAlertSubscription) {
						deleteAlertSubscription(selectedAlertSubscription)
					}
				}}>{translate('common:delete', 'Delete')}...</Button
			>
		</div>
	</div>
{:else}
	<div class="jumbotron">
		<h1 class="display-4">{translate('configuration.alertSubscriptions.alertSubscriptions', 'Alert Subscriptions')}</h1>
		<p class="lead">
			<Icon
				icon="lock-keyhole"
				prefix="far"
				class="mr-2"
			/>
			{translate('configuration.error.noAlertSubscriptionPermission', `You don't have permissions to view alert subscriptions`)}.
			{translate('configuration.error.contactAdminForPermission', 'Contact your administrator about obtaining the required permissions')}.
		</p>
		<hr class="my-4" />
		<p>{translate('configuration.error.permissionLogOutAndBackIn', 'After receiving permissions, log out of Presage and back in')}.</p>
	</div>
{/if}

<AddEditModal
	bind:this={addEditModalComponent}
	{session}
	{analyses}
	{groups}
	{investigationRules}
	{phoneCarriers}
	{plants}
	{products}
	{schedules}
	templates={alertTemplates}
	{userAccounts}
	{workOrderTypes}
	{permissions}
	on:alertSubscriptionCreated={e => alertSubscriptionCreated(e.detail)}
	on:alertSubscriptionUpdated={e => alertSubscriptionUpdated(e.detail)}
/>
