<script lang="ts">
	import type { AddRemoveStore } from 'stores/add-remove-store'
	import type { CrudMap, CrudStore } from '@isoftdata/svelte-store-crud'
	import type { Mediator, i18n, SvelteAsr } from 'types/common'
	import type SaveResetButton from '@isoftdata/svelte-save-reset-button'
	import type { AnalysisOptionChoiceUpdate, CreateAndUpdateProducts$input, EntityTagUpdate, NewAnalysisOptionChoice, NewProductBatch, UpdateProductBatch } from '$houdini'
	import type { Writable } from 'svelte/store'

	import CollapsibleCard from '@isoftdata/svelte-collapsible-card'
	import Button from '@isoftdata/svelte-button'
	import Modal from '@isoftdata/svelte-modal'
	import Attachments, { type BaseAttachmentFile } from '@isoftdata/svelte-attachments'
	import Input from '@isoftdata/svelte-input'
	import Textarea from '@isoftdata/svelte-textarea'
	import Autocomplete from '@isoftdata/svelte-autocomplete'
	import SiteAutocomplete from '@isoftdata/svelte-site-autocomplete'
	import Checkbox from '@isoftdata/svelte-checkbox'
	import ImageThumbnail from '@isoftdata/svelte-image-thumbnail'
	import ImageViewer from '@isoftdata/svelte-image-viewer'
	import Icon from '@isoftdata/svelte-icon'
	import Dropdown, { DropdownItem } from '@isoftdata/svelte-dropdown'
	import Table, { type Column, type IndexedRowProps, Td, TreeRow, findNodeById, removeFromTree, upsertIntoTree } from '@isoftdata/svelte-table'
	import ExpandableBadge from 'components/ExpandableBadge.svelte'
	import ConfigureSpecificationCard, { type MetaSpecification } from 'components/ConfigureSpecificationCard.svelte'
	import TagSelection, { type MetaTag } from 'components/TagSelection.svelte'
	import BarcodeFormatModal from './BarcodeFormatModal.svelte'
	import ProductBatchesModal from './ProductBatchesModal.svelte'

	import {
		type Analysis,
		type AnalysisOptionChange,
		type Batch,
		type Plant,
		type Product,
		type ProductNode,
		type ProductAttachment,
		type SeverityClass,
		type SpecificationCache,
		loadProductSpecificationsQuery,
		createTagsMutation,
		deleteTagsMutation,
		updateTagsMutation,
		createAndUpdateProductsMutation,
		deleteProductsMutation,
		attachFileToProductMutation,
		detachFilesFromProductMutation,
		checkProductReferenceCounts,
		formatNewProductBatch,
	} from 'utility/product-helper'
	import { v4 as uuid } from '@lukeed/uuid'
	import { klona } from 'klona'
	import b64ify from 'utility/b64ify'
	import pMap from 'p-map'
	import hasPermission from 'utility/has-permission'
	import { getContext, onMount, tick, untrack, type ComponentProps } from 'svelte'
	import userLocalWritable from '@isoftdata/svelte-store-user-local-writable'
	import session from 'stores/session'
	import getDuplicateName from 'utility/get-duplicate-name'
	import CardFooter from 'components/CardFooter.svelte'

	type SaveResetProps = Writable<ComponentProps<SaveResetButton> | null>
	type IndexedRow = ProductNode & IndexedRowProps

	type MetaPlant = Plant & { uuid: string }

	const mediator = getContext<Mediator>('mediator')
	const { t: translate } = getContext<i18n>('i18next')

	const productTypes = [
		{ label: translate('product.showProductsLabel', 'Show Products'), value: 'PRODUCT' },
		{ label: translate('product.showIngredientsLabel', 'Show Ingredients'), value: 'INGREDIENT' },
	]

	interface Props {
		asr: SvelteAsr
		tags: Array<MetaTag>
		plantId: number
		clipboard: ProductNode | null
		plants: Array<MetaPlant>
		tagCrudStore: CrudStore<MetaTag, 'uuid'>
		specificationCrudStore: CrudStore<MetaSpecification, 'uuid'>
		tagAddRemoveStore: AddRemoveStore
		inUseAtPlantIDsAddRemoveStore: AddRemoveStore
		productCategories: Array<string | null>
		productCrudStore: CrudStore<ProductNode, 'uuid'>
		attachmentCrudStore: CrudStore<ProductAttachment, 'uuid'>
		batchCrudStore: CrudStore<Batch, 'uuid'>
		productsList: Array<Product>
		productsTree: Array<ProductNode>
		expandedCard: 'Details' | 'Tags'
		// Can't unselect product, but if there are no products, it will be null
		selectedProduct: ProductNode | null
		selectedSpecification: MetaSpecification | null
		specificationCache: SpecificationCache
		selectedPlant: MetaPlant
		analyses?: Array<Analysis>
		analysesById: Record<number, Analysis>
		severityClasses?: Array<SeverityClass>
		selectedProductTypeFilter?: 'PRODUCT' | 'INGREDIENT'
		showInactive?: boolean
		showUnused?: boolean
		saveResetProps: SaveResetProps
		hasUnsavedChanges?: boolean
		canEditGlobalFields: (productId?: number | null) => boolean
	}

	let {
		asr,
		tags = $bindable(),
		plantId,
		clipboard = $bindable(),
		plants,
		tagCrudStore = $bindable(),
		specificationCrudStore,
		tagAddRemoveStore,
		inUseAtPlantIDsAddRemoveStore,
		productCategories,
		productCrudStore,
		attachmentCrudStore,
		batchCrudStore,
		productsList,
		productsTree = $bindable(),
		expandedCard = $bindable(),
		selectedProduct = $bindable(),
		selectedSpecification = $bindable(),
		specificationCache = $bindable(),
		selectedPlant = $bindable(),
		analyses = [],
		analysesById,
		severityClasses = [],
		selectedProductTypeFilter = $bindable('PRODUCT'),
		showInactive = $bindable(false),
		showUnused = $bindable(false),
		saveResetProps,
		hasUnsavedChanges = $bindable(false),
		canEditGlobalFields,
	}: Props = $props()

	function isString(val: unknown): val is string {
		return typeof val === 'string'
	}

	let selectedProductSpecifications: Array<MetaSpecification> = $state([])

	let theTable: Table<ProductNode> | undefined = $state()
	let currentPageRows: Array<IndexedRow> = []
	let isCut = $state(false)
	let copiedProductUuid: string | null = $state(null)
	let showAttachmentsModal = $state(false)
	let productBatchesModal: ProductBatchesModal | undefined = $state(undefined)
	let barcodeFormatModal: BarcodeFormatModal | undefined = $state(undefined)

	let showImageThumbnails = userLocalWritable($session.userAccountId, 'showProductThumbnails', false)
	let showImageViewer = $state(false)
	let currentPhotoIndex = $state(0)
	let hasBatchSpecificationChanges = false

	const columns: Array<Column<ProductNode>> = [
		{
			name: translate('product.name', 'Name'),
			property: 'name',
			title: translate('product.nameColumnTitle', 'A unique name describing a product provided.'),
			minWidth: '200px',
		},
		{
			name: '',
			property: 'dirty',
			icon: 'save',
			width: '1rem',
			sortType: false,
			title: translate('product.dirtyColumnTitle', 'Rows with a save icon have unsaved changes, and will be saved when you hit the "Save" button.'),
		},
		{
			name: '',
			icon: 'photo-video',
			property: 'attachmentCount',
			width: '1rem',
			align: 'center',
			sortType: false,
			title: translate('product.attachmentCountColumnTitle', 'The number of attachments at the location. Click to view image attachments'),
		},
		{
			name: translate('product.active', 'Active'),
			property: 'active',
			title: translate('product.activeColumnTitle', 'Indicates whether the product is active or not.'),
			width: '1rem',
			align: 'center',
			sortType: false,
		},
		{
			name: translate('product.inUse', 'In Use'),
			property: 'inUse',
			title: translate('product.inUseColumnTitle', 'Indicates whether the product is in use at the current plant or not.'),
			width: '1rem',
			align: 'center',
			sortType: false,
		},
	]

	function getUpdatedProductUuidSet(
		productChanges: CrudMap<ProductNode[]>,
		attachmentChanges: CrudMap<ProductAttachment[]>,
		specChanges: CrudMap<MetaSpecification[]>,
		batchChanges: CrudMap<Batch[]>,
	): Set<string> {
		const updatedProductUuids = new Set<string>()
		const keys = ['created', 'updated', 'deleted']
		keys.forEach(key => {
			if (productChanges[key]) {
				Object.keys(productChanges[key]).forEach(uuid => updatedProductUuids.add(uuid))
			}
			if (attachmentChanges[key]) {
				;(Object.values(attachmentChanges[key]) as ProductAttachment[]).forEach(attachment => {
					if (attachment.productUuid) {
						updatedProductUuids.add(attachment.productUuid)
					}
				})
			}
			if (specChanges[key]) {
				;(Object.values(specChanges[key]) as MetaSpecification[]).forEach((spec: MetaSpecification) => {
					if (spec.productUuid) {
						updatedProductUuids.add(spec.productUuid)
					}
				})
			}
			if (batchChanges[key]) {
				;(Object.values(batchChanges[key]) as Batch[]).forEach((batch: Batch) => {
					if (batch.productUuid) {
						updatedProductUuids.add(batch.productUuid)
					}
				})
			}
		})
		return updatedProductUuids
	}

	function canEditChoice(plantId: number | null) {
		if (!selectedProduct) {
			return false
		}
		if (plantId === null) {
			return canEditGlobalFields(selectedProduct.id)
		}

		if (plantId) {
			return hasPermission('PRODUCT_CAN_EDIT_PRODUCTS', plantId)
		}

		return false
	}

	function validateNamesBeforeSave(productsToValidate: Array<ProductNode>): boolean {
		return productsToValidate.every(product => {
			if (!productHasUniqueName(product)) {
				mediator.call('showMessage', {
					heading: translate('product.duplicateNameError', 'Duplicate Name Error'),
					message: translate('product.duplicateNameErrorMessage', 'Sibling products cannot have the same name.'),
					type: 'danger',
					time: false,
				})
				return false
			}
			return true
		})
	}

	async function save() {
		const productsToSave: Array<ProductNode> = Array.from(updatedProductUuids)
			.map(uuid => findNodeById(productsTree, 'uuid', uuid))
			.filter(node => node !== undefined)
		if (!validateNamesBeforeSave(productsToSave)) {
			throw new Error('Validation error.')
		}

		let newTagNamesToIds: Record<string, number> = {}

		try {
			const tagsToCreate = tagCrudStore.createdValues
			const tagsToUpdate = tagCrudStore.updatedValues
			const tagsToDelete = tagCrudStore.deletedValues
			if (tagsToCreate.length) {
				const { data } = await createTagsMutation.mutate({
					input: tagsToCreate.map(tag => {
						return {
							active: tag.active,
							entityType: 'PRODUCT',
							name: tag.name,
						}
					}),
				})

				const createdTags = data?.createEntityTags ?? []

				if (createdTags.length) {
					newTagNamesToIds = createdTags.reduce((acc: Record<string, number>, tag) => {
						acc[tag.name] = tag.id
						return acc
					}, {})
					tags = tags.map(tag => {
						if (tag.name in newTagNamesToIds) {
							tag.id = newTagNamesToIds[tag.name]
						}
						return tag
					})
				}
			}

			if (tagsToUpdate.length) {
				await updateTagsMutation.mutate({
					input: tagsToUpdate.reduce((acc: EntityTagUpdate[], tag) => {
						if (tag.id) {
							acc.push({
								id: tag.id,
								entityType: 'PRODUCT',
								active: tag.active,
								name: tag.name,
							})
						}
						return acc
					}, []),
				})
			}

			if (tagsToDelete.length) {
				await deleteTagsMutation.mutate({
					ids: tagsToDelete.reduce((acc: number[], tag) => {
						if (tag.id) {
							acc.push(tag.id)
						}
						return acc
					}, []),
				})
			}
			tagCrudStore.clear()
		} catch (err: unknown) {
			const error = err as Error
			return mediator.call('showMessage', {
				heading: translate('product.errorSavingTags', 'Error Saving Tags; Products not Saved'),
				message: error.message ?? translate('product.unknownError', 'Unknown Error.'),
				type: 'danger',
				time: false,
			})
		}

		let uuidsToProducts: Record<string, string> = {}
		let productsToIds: Record<string, number> = {}
		if (productCrudStore.hasChanges() || specificationCrudStore.hasChanges() || batchCrudStore.hasChanges() || attachmentCrudStore.hasChanges()) {
			const productsToCreateAndUpdate: CreateAndUpdateProducts$input['products'] = productsToSave.map((product): CreateAndUpdateProducts$input['products'][number] => {
				uuidsToProducts[product.uuid] = product.name
				const tagIdsToAdd = tagAddRemoveStore.getAddIds(product.uuid, tags, 'id').map(tagId => parseInt(tagId, 10))
				const tagIdsToRemove = tagAddRemoveStore.getRemoveIds(product.uuid, tags, 'id').map(tagId => parseInt(tagId, 10))
				const inUseAtPlantIdsToAdd = inUseAtPlantIDsAddRemoveStore.getAddIds(product.uuid, plants, 'id').map(plantId => parseInt(plantId, 10))
				const inUseAtPlantIdsToRemove = inUseAtPlantIDsAddRemoveStore.getRemoveIds(product.uuid, plants, 'id').map(plantId => parseInt(plantId, 10))
				let analysisOptionChoicesToAdd: Array<NewAnalysisOptionChoice> = []
				let analysisOptionChoiceIdsToRemove: Array<number> = []
				let analysisOptionChoicesToUpdate: Array<AnalysisOptionChoiceUpdate> = []
				let batchesToAdd: Array<NewProductBatch> = []
				let updatedBatches: Array<UpdateProductBatch> = []
				const analysisOptionChoicesToAddToBatches: Map<string, Array<NewAnalysisOptionChoice>> = new Map()
				const analysisOptionChoicesToUpdateInBatches: Map<string, Array<AnalysisOptionChoiceUpdate>> = new Map()
				const analysisOptionChoiceIdsToRemoveInBatches: Map<string, Array<number>> = new Map()

				// If the specification crud store has changes we need to split the changes into product batch specifications based on if a uuid is included
				// And the rest are normal product specifications
				// We then need to format the product batch specifications into an applicable format so it can be included into the batches save object

				if ($specificationCrudStore && specificationCrudStore.hasChanges())
					if (specificationCrudStore.hasChanges()) {
						if (specificationCrudStore.createdValues.length > 0) {
							analysisOptionChoicesToAdd = specificationCrudStore.createdValues.reduce((acc: Array<NewAnalysisOptionChoice>, spec) => {
								if (spec.analysisOption?.id === null || spec.analysisOption?.id === undefined) {
									throw new Error(translate('product.specCreateError', `Error saving specification: ${spec.id} on product: ${spec.productId}, Analysis Option Required`))
								}
								const newChoice = formatSpecificationForSave(spec)
								if (spec.productBatchUuid) {
									analysisOptionChoicesToAddToBatches.set(spec.productBatchUuid, [...(analysisOptionChoicesToAddToBatches.get(spec.productBatchUuid) ?? []), newChoice])
								} else {
									acc.push(newChoice)
								}
								return acc
							}, [])
						}
						if (specificationCrudStore.updatedValues.length > 0) {
							analysisOptionChoicesToUpdate = specificationCrudStore.updatedValues.reduce((acc: Array<AnalysisOptionChoiceUpdate>, spec) => {
								if (spec.analysisOption?.id === null || spec.analysisOption?.id === undefined) {
									throw new Error(translate('product.specUpdateError', `Error saving specification: ${spec.id} on product: ${spec.productId}, Analysis Option Required`))
								}
								if (spec.id) {
									const updatedChoice = formatSpecificationForSave(spec)
									if (spec.productBatchUuid) {
										analysisOptionChoicesToUpdateInBatches.set(spec.productBatchUuid, [...(analysisOptionChoicesToUpdateInBatches.get(spec.productBatchUuid) ?? []), updatedChoice])
									} else {
										acc.push(updatedChoice)
									}
								}
								return acc
							}, [])
						}
						if (specificationCrudStore.deletedValues.length > 0) {
							analysisOptionChoiceIdsToRemove = specificationCrudStore.deletedValues.reduce((acc: Array<number>, spec) => {
								if (spec.id) {
									if (spec.productBatchUuid) {
										analysisOptionChoiceIdsToRemoveInBatches.set(spec.productBatchUuid, [...(analysisOptionChoiceIdsToRemoveInBatches.get(spec.productBatchUuid) ?? []), spec.id])
									} else {
										acc.push(spec.id)
									}
								}
								return acc
							}, [])
						}
					}

				if (batchCrudStore.hasChanges()) {
					if (batchCrudStore.createdValues.length > 0) {
						batchesToAdd = batchCrudStore.createdValues.map(batch => {
							const batchSpecificationChanges: AnalysisOptionChange = {
								analysisOptionChoicesToAdd: analysisOptionChoicesToAddToBatches.get(batch.uuid) ?? [],
								analysisOptionChoicesToUpdate: analysisOptionChoicesToUpdateInBatches.get(batch.uuid) ?? [],
								analysisOptionChoiceIdsToRemove: analysisOptionChoiceIdsToRemoveInBatches.get(batch.uuid) ?? [],
							}
							return formatNewProductBatch(batch, batchSpecificationChanges)
						})
					}

					if (
						batchCrudStore.updatedValues.length > 0 ||
						analysisOptionChoicesToUpdateInBatches.size > 0 ||
						analysisOptionChoicesToAddToBatches.size > 0 ||
						analysisOptionChoiceIdsToRemoveInBatches.size > 0
					) {
						updatedBatches = batchCrudStore.updatedValues.map(batch => {
							if (batch.id) {
								return {
									id: batch.id,
									description: batch.description,
									end: batch.end,
									locationId: batch.location?.id,
									name: batch.name,
									plantId: batch.plantId,
									productId: batch.productId,
									start: batch.start,
									status: batch.status,
									expiration: batch.expiration,
									analysisOptionChoicesToAdd: analysisOptionChoicesToAddToBatches.get(batch.uuid) ?? [],
									analysisOptionChoicesToUpdate: analysisOptionChoicesToUpdateInBatches.get(batch.uuid) ?? [],
									analysisOptionChoiceIdsToRemove: analysisOptionChoiceIdsToRemoveInBatches.get(batch.uuid) ?? [],
								}
							} else {
								throw new Error(translate('product.batchUpdateError', `Error updating batch: ${batch.id}, Batch ID Required`))
							}
						})
					}
				}

				return {
					id: product.id,
					name: product.name,
					active: product.active,
					barcodeFormat: product.barcodeFormat,
					category: product.category,
					description: product.description,
					parentProductId: product.parentProductId,
					productType: product.productType,
					tagIdsToAdd,
					tagIdsToRemove,
					inUseAtPlantIdsToAdd,
					inUseAtPlantIdsToRemove,
					analysisOptionChoicesToAdd,
					analysisOptionChoiceIdsToRemove,
					analysisOptionChoicesToUpdate,
					batchesToAdd,
					batchesToUpdate: updatedBatches,
					uuid: product.uuid,
					parentProductUuid: product.parentProductUuid,
					itemNumber: product.itemNumber,
					supplierItemNumber: product.supplierItemNumber,
					unit: product.unit,
					unitConversion: product.unitConversion,
				}
			})

			const productIdsToDelete: number[] = productCrudStore.deletedValues.map(product => product.id).filter(productId => productId !== null)

			try {
				const { data } = await createAndUpdateProductsMutation.mutate({
					products: productsToCreateAndUpdate,
				})
				const savedProducts = data?.createAndUpdateProducts
				if (Array.isArray(savedProducts)) {
					productsToIds = savedProducts.reduce(
						(acc, product) => {
							acc[product.name] = product.id
							return acc
						},
						{} as Record<string, number>,
					)
				}

				if (productIdsToDelete.length) {
					await deleteProductsMutation.mutate({ ids: productIdsToDelete })
				}
			} catch (err: any) {
				console.error('Error saving products:', err)
				return mediator.call('showMessage', {
					heading: translate('product.errorSavingProducts', 'Error Saving Products'),
					message: err.message ?? translate('product.unknownError', 'Unknown Error.'),
					type: 'danger',
					time: false,
				})
			}

			try {
				if (attachmentCrudStore.createdValues.length) {
					const filesToSave = await Promise.all(
						attachmentCrudStore.createdValues
							.filter(file => (file.productId ?? productsToIds[uuidsToProducts[file.productUuid]]) && file.File)
							.map(async file => {
								const productId = file.productId ?? productsToIds[uuidsToProducts[file.productUuid]]
								return {
									productId,
									fileName: file.name,
									base64String: await b64ify(file.File!),
									public: true,
									rank: file.rank,
								}
							}),
					)

					// B64 prevents easy bulk inserts so we do 2 at a time
					await pMap(filesToSave, async input => await attachFileToProductMutation.mutate({ input }), { concurrency: 2 })
				}
				if (attachmentCrudStore.deletedValues.length) {
					const fileIdsByProductId: Record<number, Array<number>> = attachmentCrudStore.deletedValues.reduce((acc: Record<number, number[]>, file) => {
						if (file.productId && file.fileId) {
							acc[file.productId] ??= []
							acc[file.productId].push(file.fileId)
						}
						return acc
					}, {})
					await pMap(
						Object.entries(fileIdsByProductId),
						async ([productId, fileIds]) => {
							await detachFilesFromProductMutation.mutate({ productId: parseInt(productId, 10), fileIds })
						},
						{ concurrency: 2 },
					)
				}
			} catch (err: any) {
				return mediator.call('showMessage', {
					heading: translate('product.errorSavingAttachments', 'Error Saving Attachments'),
					message: err.message ?? translate('product.unknownError', 'Unknown Error.'),
					type: 'danger',
					time: false,
				})
			}

			hasUnsavedChanges = false
			if (selectedProduct) {
				asr.go(
					'app.product-management.product',
					{
						lastSavedTime: Date.now(),
						lastResetTime: null,
						selectedProductId: selectedProduct?.id ?? productsToIds[uuidsToProducts[selectedProduct.uuid]] ?? null,
					},
					{ inherit: true },
				)
			}
		}
	}

	function formatSpecificationForSave(spec) {
		return {
			id: spec.id ?? undefined,
			active: true,
			analysisOptionId: spec.analysisOption.id,
			boundaryType: spec.boundaryType,
			choice: spec.choice,
			constraint: spec.constraint,
			plantId: spec.plantId,
			productBatchId: spec.productBatchId,
			productId: spec.productId,
			requiredAnalysisOptionId: spec.requiredAnalysisOption?.id,
			requiredChoice: spec.requiredChoice,
			requiredConstraint: spec.requiredConstraint,
			severityClassId: spec.severityClass?.id,
		}
	}

	function updateProduct(product: ProductNode | null) {
		if (!product) {
			return
		}
		if (!productHasUniqueName(product)) {
			return mediator.call('showMessage', {
				heading: translate('product.duplicateNameError', 'Duplicate Name Error'),
				message: translate('product.duplicateNameErrorMessage', 'Sibling products cannot have the same name.'),
				type: 'danger',
				time: false,
			})
		}
		if (product.id) {
			productCrudStore.update(product)
		} else {
			productCrudStore.create(product)
		}

		productsTree = upsertIntoTree(productsTree, product, 'uuid', 'parentProductUuid')
	}

	async function onNewProduct(target: 'TOP' | 'PARENT' | 'SIBLING' | 'CHILD') {
		if (!selectedProduct) {
			target = 'TOP'
		}
		// Get new parent product (null for top-level products)
		let newParent: ProductNode | null = null
		switch (target) {
			case 'TOP':
				break
			case 'PARENT':
			case 'SIBLING':
				newParent = selectedProduct?.parent ?? null
				break
			case 'CHILD':
				newParent = selectedProduct ?? null
				break
			default:
				throw new Error(`Invalid value for 'target': ${target} When calling onNewProduct`)
		}
		// "copy" the selected product, or the template if no product is selected
		const emptyProduct: Readonly<ProductNode> = Object.freeze({
			id: null,
			uuid: uuid(),
			name: '',
			barcodeFormat: '',
			category: '',
			description: '',
			productType: 'PRODUCT',
			active: true,
			inUseAtPlantIDs: [],
			itemNumber: '',
			supplierItemNumber: '',
			parentProductId: null,
			parentProductUuid: null,
			tags: [],
			attachments: [],
			imageAttachments: [],
			attachmentCount: 0,
			thumbnailPath: '',
			inUse: true,
			children: [],
			parent: null,
			unit: '',
			unitConversion: 1,
		})

		const newProduct = copyProduct(selectedProduct ?? emptyProduct, newParent, true)
		// Move stuff around if we're inserting as parent of selected product
		if (target === 'PARENT' && selectedProduct) {
			// Get parent's children (now this products's children)
			newProduct.children = newParent?.children.map(child => cutProductAndChildren(child, newProduct)) ?? [cutProductAndChildren(selectedProduct, newProduct)]
			// Clear newParent's children (newProduct will be added later)

			if (newParent) {
				newParent.children = []
			} else {
				// If newParent is null, we're inserting at the top level, so remove selectedProduct
				productsTree = removeFromTree(productsTree, selectedProduct, 'uuid', 'parentProductUuid', false)
			}
			// Add all freshly moved children to crud store (only top layer's parent has changed)
			// Should keep all "new" children in the "created" object
			newProduct.children.forEach(child => productCrudStore.update(child))
		}
		// Make unique after we possibly move children around
		const uniqueProduct = makeProductUnique(newProduct)

		// Assing Product or Ingredient based on selectedProductTypeFilter
		uniqueProduct.productType = selectedProductTypeFilter

		// Insert new product into tree
		productsTree = upsertIntoTree(productsTree, uniqueProduct, 'uuid', 'parentProductUuid')

		// Insert new product into crud store
		productCrudStore.create(uniqueProduct)
		await tick()
		selectedProduct = uniqueProduct
		selectedSpecification = null
		selectedProductSpecifications = []
		if (newParent) {
			theTable?.expandRow(newParent.uuid)
		}
		await tick()
		const newRow = document.querySelector(`tr[data-id="${uniqueProduct.uuid}"]`)
		if (newRow) {
			newRow.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
		}
		await onProductSelect(uniqueProduct)
	}

	function makeProductUnique(product: ProductNode, siblings: Array<ProductNode> = productsTree) {
		product.name = getDuplicateName(product, siblings, 'name')
		return product
	}

	function cutProductAndChildren(product: ProductNode, newParent: ProductNode | null) {
		product.parentProductId = newParent?.id ?? null
		product.parentProductUuid = newParent?.uuid ?? null
		product.parent = newParent

		return product
	}

	/**
	 * Used for both the copy/paste feature, and inserting new products
	 * @param product The product to copy
	 * @param parentProduct The new parent product that the copied product will be inserted into
	 * @param asNewLocation Whether to copy the parent's tags into the new product (used when inserting new, not when copy/pasting)
	 */
	function copyProduct(product: ProductNode, parentProduct: ProductNode | null, asNewLocation = false) {
		// remove parent and children to avoid circular references inside of klona
		const { parent, children, ...productToCopy } = product

		const newProduct: ProductNode = {
			...klona(productToCopy),
			id: null,
			uuid: uuid(),
			attachmentCount: 0,
			attachments: [],
			children: new Array<ProductNode>(),
			parentProductId: parentProduct?.id ?? null,
			parentProductUuid: parentProduct?.uuid ?? null,
			parent: parentProduct,
			inUseAtPlantIDs: selectedPlant ? [selectedPlant.id] : [],
			// copy some stuff from the parent when inserting a new product
			tags: asNewLocation ? mergeTags(product.tags ?? [], parentProduct?.tags ?? []) : mergeTags(product.tags, []),
		}

		// Track tags to add to the new Product
		newProduct.tags.forEach(tag => {
			tagAddRemoveStore.add(newProduct.uuid, tag.uuid)
		})

		// Track plants to add to the new Product
		newProduct.inUseAtPlantIDs.forEach(plantId => {
			//Since the plantUuid is just the ID stringified we can just use the ID
			inUseAtPlantIDsAddRemoveStore.add(newProduct.uuid, plantId.toString())
		})
		return newProduct
	}

	function mergeTags(targetTags: Array<MetaTag>, parentTags: Array<MetaTag>) {
		const uuids = new Set<string>(targetTags.concat(parentTags).map(tag => tag.uuid))
		return tags.filter(tag => uuids.has(tag.uuid))
	}

	function copyProductAndChildren(product: ProductNode, parentProduct: ProductNode | null) {
		const newProduct = copyProduct(product, parentProduct)
		if (Array.isArray(product.children)) {
			newProduct.children = product.children.map(child => {
				return copyProductAndChildren(child, newProduct)
			})
		}
		return newProduct
	}

	async function paste(atTopLevel?: boolean) {
		const clipboardValue = clipboard // can't type narrow a $store, so assign it to a const instead
		if (!clipboardValue) {
			return
		}

		// On first cut -> paste, don't clone, just move
		const newParent = atTopLevel ? null : (selectedProduct ?? null)

		// Make sure we're not pasting into the tree being cut BEFORE we do any cutting/copying
		if (isCut && newParent && uuidInTree(newParent.uuid, clipboardValue)) {
			return alert(translate('product.cannotPasteIntoTreeBeingCut', 'Cannot paste into the tree being cut. Select another area, or use the copy operation.'))
		}

		// remove from old product if cutting
		if (isCut) {
			productsTree = removeFromTree(productsTree, clipboardValue, 'uuid', 'parentProductUuid', false)
		}

		// THEN we can cut/copy
		const productToPaste = isCut ? cutProductAndChildren(clipboardValue, newParent) : copyProductAndChildren(clipboardValue, newParent)

		if (!newParent) {
			// top level
			productToPaste.parentProductId = null
			productToPaste.parentProductUuid = null
			productToPaste.name = getDuplicateName(productToPaste, productsTree, 'name')
		} else if (selectedProduct) {
			//below selected product
			productToPaste.name = getDuplicateName(productToPaste, selectedProduct.children, 'name')
		}

		// reinsert into tree
		productsTree = upsertIntoTree(productsTree, productToPaste, 'uuid', 'parentProductUuid')

		// Only first paste after cut should be a cut
		isCut = false

		productCrudStore.create(productToPaste)
		productsTree = productsTree
		await tick()
		if (productToPaste.parentProductUuid) {
			theTable?.expandRow(productToPaste.parentProductUuid)
		}
		document.querySelector(`tr[data-id="${productToPaste.uuid}"]`)?.scrollIntoView({ behavior: 'smooth', block: 'center' })
		await onProductSelect(productToPaste)
	}

	function uuidInTree(uuid: string, tree: ProductNode) {
		if (tree.uuid === uuid) {
			return true
		}
		if (Array.isArray(tree.children)) {
			return tree.children.some(child => uuidInTree(uuid, child))
		}
		return false
	}

	async function deleteProduct(product: ProductNode, deleteChildren: boolean = false) {
		if (!product) {
			return
		}

		let productIds = product.id ? [product.id] : []
		let productsToDelete = [product]
		if (deleteChildren) {
			productIds = [...productIds, ...getChildrenIds(product)]
			productsToDelete = [...productsToDelete, ...getAllChildren(product)]
		}

		function doTheDelete() {
			// Mark for deletion
			productsToDelete.forEach(product => productCrudStore.delete(product))
			// Remove from tree
			productsTree = removeFromTree(productsTree, product, 'uuid', 'parentProductUuid', deleteChildren)
			// Set parent IDs to null and set in update map
			if (!deleteChildren) {
				product.children.forEach(child => {
					child.parentProductId = product?.parent?.id ?? null
					productCrudStore.update(child)
				})
			}
			// Update the selected product so we don't have a deleted product "selected" in the UI
			onProductSelect(product.parent ?? null)
		}

		if (product.id) {
			const productRefCounts = await checkProductReferenceCounts(productIds)

			// If we are deleting products we cannot delete products attached to samples
			const safe = productRefCounts.sampleCount === 0

			let message = ''
			if (safe) {
				if (deleteChildren) {
					//No samples but deleting children
					message = translate(
						'product.safeConfirmDeleteProductAndChildren',
						`This product and all its children will be deleted. Are you sure you want to delete this product?
Referenced by: Samples: {{sampleCount}}; Analysis Thresholds: {{analysisCount}}; Product Batches: {{productBatchCount}}.
After saving this cannot be undone.`,
						{
							sampleCount: productRefCounts.sampleCount,
							analysisCount: productRefCounts.analysisCount,
							productBatchCount: productRefCounts.productBatchCount,
						},
					)
				} else {
					//No samples and not deleting children
					message = translate(
						'product.safeConfirmDeleteProductNoChildren',
						`Are you sure you want to delete this product?
This product is referenced by: Samples: {{sampleCount}}; Analysis Thresholds: {{analysisCount}}; Product Batches: {{productBatchCount}}; and has {{childProductCount}}.
After saving this cannot be undone.`,
						{
							sampleCount: productRefCounts.sampleCount,
							analysisCount: productRefCounts.analysisCount,
							productBatchCount: productRefCounts.productBatchCount,
							childProductCount: product.children.length,
						},
					)
				}
			} else {
				//Samples exist therefore cannot delete
				message = translate(
					'product.unsafeConfirmDeleteProduct',
					`This product is referenced by: Samples: {{sampleCount}}; Analysis Thresholds: {{analysisCount}}; Product Batches: {{productBatchCount}}; and has {{childProductCount}}.
A product cannot be deleted while any samples are attached to it.
Either delete said samples or have an administrator perform this operation.`,
					{
						sampleCount: productRefCounts.sampleCount,
						analysisCount: productRefCounts.analysisCount,
						productBatchCount: productRefCounts.productBatchCount,
						childProductCount: product.children.length,
					},
				)
			}
			if (safe && confirm(message)) {
				doTheDelete()
			} else if (!safe) {
				alert(message)
			}
		} else if (confirm(translate('product.confirmDeleteUnsavedProduct', 'Are you sure you want to delete this product? This cannot be undone.'))) {
			doTheDelete()
		}
	}

	function getAllChildren(product: ProductNode) {
		const children = new Array<ProductNode>()
		if (Array.isArray(product.children)) {
			product.children.forEach(child => {
				children.push(child)
				getAllChildren(child).forEach(child => children.push(child))
			})
		}
		return children
	}

	function getChildrenIds(product: ProductNode) {
		const childrenIds = new Array<number>()
		if (Array.isArray(product.children)) {
			product.children.forEach(child => {
				if (child.id) {
					childrenIds.push(child.id)
				}
				getChildrenIds(child).forEach(id => childrenIds.push(id))
			})
		}
		return childrenIds
	}

	async function addFilesToSelectedProduct({ detail: filesToAdd }: { detail: BaseAttachmentFile[] }) {
		if (selectedProduct) {
			const productId = selectedProduct.id
			const productUuid = selectedProduct.uuid
			const metaAttachments: ProductAttachment[] = filesToAdd.map(file => ({ ...file, productId, productUuid, path: file.path ?? '', uuid: file.uuid ?? '' }))
			metaAttachments.forEach(file => attachmentCrudStore.create(file))
			selectedProduct.attachments.push(...metaAttachments)
			selectedProduct.imageAttachments.push(...metaAttachments.filter(file => file.mimeType?.startsWith('image/')))
			selectedProduct.thumbnailPath = selectedProduct.imageAttachments[0].path
			selectedProduct.attachmentCount += filesToAdd.length
			selectedProduct = selectedProduct
			productsTree = productsTree
			productCrudStore.update(selectedProduct)
		}
	}

	function removeFilesFromSelectedProduct({ detail: filesToDelete }: { detail: BaseAttachmentFile[] }) {
		if (selectedProduct) {
			const productId = selectedProduct.id
			const productUuid = selectedProduct.uuid
			filesToDelete.forEach(file => attachmentCrudStore.delete({ ...file, productId, productUuid, path: file.path ?? '', uuid: file.uuid ?? '' }))
			if (selectedProduct) {
				selectedProduct.attachments = selectedProduct.attachments.filter(attachment => !filesToDelete.some(file => file.uuid === attachment.uuid)) ?? []
				selectedProduct.imageAttachments = selectedProduct.imageAttachments.filter(attachment => !filesToDelete.some(file => file.uuid === attachment.uuid)) ?? []
			}
			selectedProduct.thumbnailPath = selectedProduct.imageAttachments[0]?.path ?? null
			selectedProduct.attachmentCount -= filesToDelete.length
			selectedProduct = selectedProduct
			productsTree = productsTree
			productCrudStore.update(selectedProduct)
		}
	}

	function productHasUniqueName(selectedProduct: ProductNode | null): boolean {
		if (!selectedProduct) {
			return true
		}

		if (!selectedProduct.parentProductUuid) {
			// If the product is top level, it can't have a duplicate name with other top level products
			// In this case the productsTree array is all the top level products
			const names = productsTree.map(product => product.name)
			return names.length === new Set(names).size
		}
		const children = selectedProduct.children
		const childrenNames = children.map(child => child.name)
		return childrenNames.length === new Set(childrenNames).size
	}

	async function onProductSelect(product: ProductNode | null, force = false) {
		if (!force && selectedProduct?.uuid === product?.uuid) {
			return
		}
		selectedProductSpecifications = []
		selectedProduct = product
		// put the product in the url so it stays selected on refresh
		asr.go(null, { selectedProductId: product?.id ?? null }, { inherit: true })

		if (!product) {
			selectedProductSpecifications = []
			return
		}

		// We use this cache to prevent uuids from changing between product selections
		if (specificationCache.has(product.uuid)) {
			selectedProductSpecifications = specificationCache.get(product.uuid)
			return
		}

		if (!product.id) {
			// If the product is new, we don't need to load the specifications but we can initialize its cache entry
			specificationCache.set(product.uuid, [])
			return
		}

		try {
			if (!selectedPlant) {
				mediator.call('showMessage', {
					type: 'danger',
					heading: translate('product.errorLoadingSpecificationsHeading', 'No Plant Selected'),
					message: translate('product.errorLoadingSpecificationsMessage', 'Plant selection is required to load product specifications.'),
					time: false,
				})
				throw new Error('No plant selected')
			}
			const { data } = await loadProductSpecificationsQuery.fetch({
				variables: {
					filter: {
						plantId: selectedPlant.id,
						productIds: [product.id],
						hasProductBatch: false,
					},
				},
			})
			if (!data?.analysisOptionChoices.data) {
				selectedProductSpecifications = []
			}
			selectedProductSpecifications =
				data?.analysisOptionChoices.data.map(spec => ({
					...spec,
					productUuid: product.uuid,
					productBatchUuid: null,
					uuid: uuid(),
				})) ?? []
			specificationCache.set(product.uuid, selectedProductSpecifications)
		} catch (error) {
			console.error('Error loading product specifications:', error)
			selectedProductSpecifications = []
		}

		// Update tag UUIDs to match master list
		if (product?.tags.some(tag => tag.id && !tag.uuid)) {
			product.tags = product.tags.reduce((acc, tag) => {
				if (tag.uuid !== '') {
					acc.push(tag)
				} else if (tag.id) {
					const matchingTag = tags.find(t => t.id === tag.id)
					if (matchingTag) {
						acc.push({
							...tag,
							uuid: matchingTag.uuid,
						})
					}
				}
				return acc
			}, new Array<MetaTag>())
		}

		await tick()

		document.getElementById(`product-name-input-${product.uuid}`)?.focus()
	}

	onMount(() => {
		if (selectedProduct) {
			onProductSelect(selectedProduct, true)
		}
	})

	const hasTagChanges = $derived(!!$tagCrudStore && tagCrudStore.hasChanges())
	const hasProductChanges = $derived(!!$productCrudStore && productCrudStore.hasChanges())
	const hasAttachmentChanges = $derived(!!$attachmentCrudStore && attachmentCrudStore.hasChanges())
	const hasSpecificationChanges = $derived(!!$specificationCrudStore && specificationCrudStore.hasChanges())
	const hasBatchChanges = $derived(!!$batchCrudStore && batchCrudStore.hasChanges())
	// const hasUnsavedChanges = $derived(untrack(() => hasUnsavedChanges) || hasTagChanges || hasProductChanges || hasAttachmentChanges || hasSpecificationChanges || hasBatchChanges || hasBatchSpecificationChanges)
	$effect(() => {
		hasUnsavedChanges = hasTagChanges || hasProductChanges || hasAttachmentChanges || hasSpecificationChanges || hasBatchChanges || hasBatchSpecificationChanges
	})
	const selectedRowIds = $derived(selectedProduct ? [selectedProduct.uuid] : [])

	let selectedProductImages = $derived(selectedProduct?.attachments.map(({ path }) => path) ?? [])
	let updatedProductUuids = $derived(getUpdatedProductUuidSet($productCrudStore, $attachmentCrudStore, $specificationCrudStore, $batchCrudStore))

	$effect(() => {
		$saveResetProps = {
			save,
			resetHref: asr.makePath(null, { lastResetTime: Date.now() }, { inherit: true }),
			disabled: !hasUnsavedChanges,
		}
	})

	export function canLeaveState() {
		return !hasUnsavedChanges
	}
</script>

<div class="form-row">
	<div
		class="col-12 d-flex"
		class:col-xl-9={!!selectedProduct}
	>
		<div class="card mb-1 w-100">
			<div class="card-header d-flex h5 justify-content-between border-radius-2rem">
				<h5 class="card-title mb-2">{translate('common:products', 'Products')}</h5>
			</div>
			<div class="card-body flex-grow-1">
				<Table
					tree
					responsive
					stickyHeader
					filterEnabled
					columnHidingEnabled
					parentClass="mh-40vh"
					{columns}
					{currentPageRows}
					rows={productsTree}
					{selectedRowIds}
					rowSelectionIdProp="uuid"
					filterPlaceholder={translate('product.filterProductAndChildren', 'Filter Products and Children')}
					bind:this={theTable}
				>
					{#snippet header()}
						<div class="form-row">
							<div class="col-12 col-md-3">
								<div
									class="mr-2"
									style="min-width: 300px;"
								>
									<SiteAutocomplete
										label={translate('common:plant', 'Plant')}
										options={plants}
										bind:value={selectedPlant}
										on:change={() => {
											asr.go('app.product-management.product', { plantId: selectedPlant.id }, { inherit: true })
										}}
									/>
								</div>
							</div>
							<div class="col-12 mb-2">
								<div class="d-flex flex-row">
									<div>
										{#each productTypes as type}
											<div class="form-check d-flex align-items-center mr-2">
												<input
													class="form-check-input"
													type="radio"
													name={selectedProductTypeFilter}
													value={type.value}
													id="selected-product-{type.value}"
													bind:group={selectedProductTypeFilter}
													onchange={() => {
														asr.go('app.product-management.product', { selectedProductTypeFilter, selectedProductId: null }, { inherit: true })
													}}
												/>
												<label
													class="form-check-label"
													for="selected-product-{type.value}"
												>
													{type.label}
												</label>
											</div>
										{/each}
									</div>
									<div class="row">
										<div class="col">
											<div class="form-check">
												<Checkbox
													inline
													label={translate('product.showInactiveProducts', 'Show Inactive Products')}
													bind:checked={showInactive}
													on:change={() => {
														asr.go('app.product-management.product', { showInactive }, { inherit: true })
													}}
												/>
											</div>
											<div class="form-check">
												<Checkbox
													inline
													label={translate('product.showProductsNotInUse', `Show Products Not In Use at {{plantCode}}`, { plantCode: selectedPlant?.code })}
													bind:checked={showUnused}
													on:change={() => {
														asr.go('app.product-management.product', { showUnused }, { inherit: true })
													}}
												/>
											</div>
										</div>
										<div class="col-auto align-self-end form-check">
											<Checkbox
												label={translate('product.showThumbnails', 'Show Thumbnails')}
												bind:checked={$showImageThumbnails}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					{/snippet}
					{#snippet body({ rows })}
						{#if rows.length > 0}
							{#each rows as row (row.uuid)}
								<TreeRow
									idProp="uuid"
									parentIdProp="parentProductUuid"
									property="name"
									node={row}
									rowClick={context => {
										onProductSelect(context.node)
									}}
								>
									{#snippet first({ node })}
										<span
											class:text-primary={node.children.length}
											title={isCut
												? translate('product.cutProductIconTitle', 'This product has been cut, and will be moved when pasted.')
												: translate('product.copyProductIconTitle', 'This product has been copied, and will be duplicated when pasted.')}
										>
											{#if copiedProductUuid === node.uuid}
												<Icon
													fixedWidth
													icon={isCut ? 'scissors' : 'copy'}
												/>
											{/if}
										</span>
									{/snippet}
									{#snippet children({ node })}
										{@const isDirty = updatedProductUuids.has(node.uuid)}
										{@const deleted = $productCrudStore && productCrudStore.isDeleted(node)}

										<Td property="dirty">
											{#if !!isDirty}
												<Icon
													fixedWidth
													icon="save"
												/>
											{:else if !!deleted}
												<Icon
													fixedWidth
													icon="trash"
												/>
											{/if}
										</Td>
										<Td
											stopPropagation
											property="attachmentCount"
											title={translate('product.imageThumbnailTdTitle', 'Click to view image attachments at the selected product.')}
										>
											<ImageThumbnail
												showImageCount
												noImagePath="images/noimage.jpg"
												fileCount={node.imageAttachments.length ?? 0}
												thumbnailFile={{ path: node.thumbnailPath ?? '' }}
												showThumbnail={$showImageThumbnails}
												on:click={async () => {
													await onProductSelect(node)
													currentPhotoIndex = 0
													await tick()
													showImageViewer = true
												}}
											/>
										</Td>
										<Td property="active">
											<Icon
												fixedWidth
												icon={node.active ? 'check' : 'xmark'}
												class={node.active ? 'text-success' : 'text-danger'}
											/>
										</Td>
										<Td property="inUse">
											<Icon
												fixedWidth
												icon={node.inUse ? 'check' : 'xmark'}
												class={node.inUse ? 'text-success' : 'text-danger'}
											/>
										</Td>
									{/snippet}
								</TreeRow>
							{:else}
								<tr>
									<td
										class="text-center"
										colspan={columns.length}
									>
										{translate('product.noProductsMatchingTheCurrentFilter', 'No Products Matching the Current Filter')}
									</td>
								</tr>
							{/each}
						{:else}
							<tr>
								<td
									class="text-center"
									colspan={columns.length}
								>
									{translate('product.noProducts', 'No Products! Click "New Product" to add one.')}
								</td>
							</tr>
						{/if}
					{/snippet}
				</Table>
			</div>

			<CardFooter>
				<Dropdown
					split
					outline
					size="sm"
					color="success"
					iconClass="plus"
					title={translate('product.newProductButtonTitle', 'Add a new product as a sibling of the selected product.')}
					disabled={!canEditGlobalFields()}
					on:click={() => onNewProduct('SIBLING')}
				>
					{translate('product.newProductButton', 'New Product')}
					{#snippet dropdownItems()}
						<button
							class="dropdown-item"
							type="button"
							disabled={!canEditGlobalFields()}
							onclick={() => onNewProduct('TOP')}
						>
							<Icon
								fixedWidth
								class="mr-1"
								icon="plus"
							/>
							{translate('product.newTopLevelProductButton', 'New Top-Level Product')}
						</button>
						<h6 class="dropdown-header">{translate('product.relativeToSelectedProductDropdownTitle', 'Relative to Selected Product')}</h6>
						<button
							class="dropdown-item"
							type="button"
							disabled={!selectedProduct || !canEditGlobalFields()}
							onclick={() => onNewProduct('PARENT')}
						>
							<Icon
								fixedWidth
								class="fa-rotate-180 mr-1"
								icon="turn-down-right"
							/>
							{translate('product.aboveParentButton', 'Above (Parent)')}
						</button>
						<button
							class="dropdown-item"
							type="button"
							disabled={!selectedProduct || !canEditGlobalFields()}
							onclick={() => onNewProduct('SIBLING')}
						>
							<Icon
								fixedWidth
								class="mr-1"
								icon="down"
							/>
							{translate('product.nextToSiblingButton', 'Next to (Sibling)')}
						</button>
						<button
							class="dropdown-item"
							type="button"
							disabled={!selectedProduct || !canEditGlobalFields()}
							onclick={() => onNewProduct('CHILD')}
						>
							<Icon
								fixedWidth
								class="mr-1"
								icon="turn-down-right"
							/>
							{translate('product.belowChildButton', 'Below (Child)')}
						</button>
					{/snippet}
				</Dropdown>
				<Button
					outline
					size="sm"
					iconClass="cut"
					title={translate('product.cutProductButtonTitle', 'Cut selected product to clipboard; the first paste will move the product, subsequent pastes will copy it.')}
					disabled={!selectedProduct || !canEditGlobalFields()}
					on:click={() => {
						if (selectedProduct) {
							isCut = true
							clipboard = selectedProduct
							copiedProductUuid = selectedProduct.uuid
							mediator.call('showMessage', {
								heading: translate('product.copiedToClipboardMessageHeading', `Product "{{- name}}" Copied to Clipboard`, { name: selectedProduct.name }),
								message: translate('product.copiedToClipboardCutPasteMessage', 'You can now paste it elsewhere.'),
								type: 'info',
							})
						}
					}}
					>{translate('product.cutButton', 'Cut')}
				</Button>
				<Button
					outline
					size="sm"
					iconClass="copy"
					title={translate('product.copyProductButtonTitle', 'Copy selected product to clipboard; you can then paste it elsewhere.')}
					disabled={!selectedProduct || !canEditGlobalFields()}
					on:click={() => {
						if (selectedProduct) {
							isCut = false
							clipboard = copyProductAndChildren(selectedProduct, null)
							copiedProductUuid = selectedProduct.uuid
							mediator.call('showMessage', {
								heading: translate('product.copiedToClipboardMessageHeading', `Product "{{name}}" Copied to Clipboard`, { name: selectedProduct.name }),
								message: translate('product.copiedToClipboardCopyPasteMessage', 'You can now paste a copy of it elsewhere.'),
								type: 'info',
							})
						}
					}}
					>{translate('product.copyButton', 'Copy')}
				</Button>
				<Dropdown
					split
					outline
					size="sm"
					iconClass="paste"
					disabled={!clipboard || !canEditGlobalFields()}
					title={translate('product.pasteProductButtonTitle', 'Paste the product from the clipboard.')}
					on:click={() => paste(!selectedProduct)}
				>
					{translate('product.paste', 'Paste')}
					{#snippet dropdownItems()}
						<h6 class="dropdown-header">{translate('product.pasteDropdownHeader', 'Paste "{{- product}}"...', { product: clipboard?.name })}</h6>
						<DropdownItem
							icon="plus"
							disabled={!clipboard || !canEditGlobalFields()}
							on:click={() => paste(true)}
							>{translate('product.pasteAtTheTopLevel', 'At the Top Level')}
						</DropdownItem>
						<DropdownItem
							icon="turn-down-right"
							disabled={!clipboard || !canEditGlobalFields()}
							on:click={() => paste()}
						>
							{translate('product.pasteBelowSelectedProduct', 'Below (child of) Selected Product')}
						</DropdownItem>
					{/snippet}
				</Dropdown>
				<Dropdown
					split
					outline
					size="sm"
					color="danger"
					iconClass="trash"
					title={translate('product.deleteProductButton', 'Delete the selected product.')}
					disabled={!selectedProduct || !canEditGlobalFields()}
					on:click={() => selectedProduct && deleteProduct(selectedProduct)}
				>
					{translate('product.deleteButton', 'Delete')}...
					{#snippet dropdownItems()}
						<h6 class="dropdown-header">{translate('product.deleteDropdownHeader', 'Delete')}...</h6>
						<DropdownItem
							icon="trash"
							disabled={!selectedProduct || !canEditGlobalFields()}
							on:click={() => selectedProduct && deleteProduct(selectedProduct)}
						>
							{translate('product.deleteSelectedProductOnlyButton', 'Selected Product Only')}
						</DropdownItem>
						<DropdownItem
							icon="turn-down-right"
							disabled={!selectedProduct || !canEditGlobalFields()}
							on:click={() => selectedProduct && deleteProduct(selectedProduct, true)}
						>
							{translate('product.deleteSelectedProductAndChildrenButton', 'Selected Product and Children')}
						</DropdownItem>
					{/snippet}
				</Dropdown>
				{#snippet right()}
					<Button
						outline
						size="sm"
						iconClass="conveyor-belt-boxes"
						title={translate('product.openProductBatchesButtonTitle', 'Open the product batches interface, where you can view and manage product batches, for the selected product.')}
						disabled={!selectedProduct}
						on:click={() => {
							if (selectedProduct) {
								productBatchesModal?.open(selectedProduct, {
									batchCrud: $batchCrudStore,
									specificationCrud: $specificationCrudStore,
								})
							}
						}}
					>
						{translate('product.openProductBatchesButton', 'Batches')}...
					</Button>
					<Button
						outline
						size="sm"
						iconClass="paperclip"
						title={translate('product.openAttachmentsButtonTitle', 'Open the attachments interface, where you can view and manage attachments, for the selected product.')}
						disabled={!selectedProduct}
						on:click={() => (showAttachmentsModal = true)}
					>
						{translate('product.openAttachmentsButton', 'Attachments')}...
					</Button>
				{/snippet}
			</CardFooter>
		</div>
	</div>
	{#if selectedProduct}
		<div
			class="col-xl-3 col-12 mb-1"
			class:d-none={!selectedProduct}
		>
			<div class="h-100 d-flex flex-column mt-2 mt-xl-0">
				<CollapsibleCard
					entireHeaderToggles
					bodyShown={expandedCard === 'Details'}
					cardClass={expandedCard === 'Details' ? 'flex-grow-1' : ''}
					cardStyle="border-bottom-left-radius: 0px; border-bottom-right-radius: 0px;"
					bodyClass="d-flex flex-column"
					cardHeaderClass="card-header d-flex justify-content-between h5"
					on:show={() => (expandedCard = 'Details')}
					on:hide={() => (expandedCard = 'Tags')}
				>
					<svelte:fragment slot="cardHeader">
						<h5 class="card-title mb-2">{translate('product.detailsCardTitle', 'Details')}</h5>
					</svelte:fragment>
					{@const deleted = productCrudStore.isDeleted(selectedProduct)}
					<fieldset class="d-flex flex-column flex-grow-1">
						<div>
							<Checkbox
								inline
								label={translate('product.activeCheckboxLabel', 'Active')}
								disabled={deleted || !canEditGlobalFields(selectedProduct.id)}
								bind:checked={selectedProduct.active}
								on:change={() => updateProduct(selectedProduct)}
							/>
							<Checkbox
								inline
								disabled={deleted || !hasPermission('PRODUCT_CAN_CHANGE_IN_USE', selectedPlant?.id)}
								label={translate('product.inUseAtCheckboxLabel', `In Use at {{- plantName}}`, { plantName: selectedPlant?.code })}
								bind:checked={selectedProduct.inUse}
								on:change={() => {
									if (selectedProduct?.inUse) {
										selectedProduct.inUseAtPlantIDs.push(selectedPlant?.id)
										inUseAtPlantIDsAddRemoveStore.add(selectedProduct.uuid, selectedPlant?.uuid)
									} else if (selectedProduct && !selectedProduct.inUse) {
										selectedProduct.inUseAtPlantIDs = selectedProduct.inUseAtPlantIDs.filter(id => id !== selectedPlant?.id)
										inUseAtPlantIDsAddRemoveStore.remove(selectedProduct.uuid, selectedPlant?.uuid)
									}

									updateProduct(selectedProduct)
								}}
							/>
						</div>
						<div>
							<Input
								id="product-name-input-{selectedProduct.uuid}"
								label={translate('product.nameInputLabel', 'Name')}
								required
								autofocus
								disabled={deleted || !canEditGlobalFields(selectedProduct.id)}
								bind:value={selectedProduct.name}
								on:change={() => updateProduct(selectedProduct)}
								validation={{
									validator: () => {
										const isUnique = productHasUniqueName(selectedProduct)
										let translatedValidation = ''

										if (!isUnique) {
											translatedValidation = translate('product.nameMustBeUnique', 'Name must be unique.')
										}
										const translatedvalidationString = isString(translatedValidation) ? translatedValidation : ''
										return isUnique ? true : translatedvalidationString
									},
								}}
							/>
							<Autocomplete
								canAddNew
								emptyValue={''}
								label={translate('product.categoryInputLabel', 'Category')}
								options={productCategories}
								getLabel={category => category ?? ''}
								disabled={deleted || !canEditGlobalFields(selectedProduct.id)}
								bind:value={selectedProduct.category}
								on:change={() => updateProduct(selectedProduct)}
							/>
						</div>
						<div class="flex-grow-1">
							<Textarea
								label={translate('product.descriptionLabel', 'Description')}
								labelParentClass="h-100 d-flex flex-column"
								class="flex-grow-1"
								disabled={deleted || !canEditGlobalFields(selectedProduct.id)}
								placeholder={translate('product.descriptionTitle', 'Enter a description (optional)')}
								bind:value={selectedProduct.description}
								on:change={() => updateProduct(selectedProduct)}
							/>
						</div>
						<Input
							label={translate('products.barcodeFormatLabel', 'Barcode Format')}
							title={translate('products.barcodeFormatTitle', "A regular expression representing the product's barcode format")}
							disabled={deleted || !canEditGlobalFields(selectedProduct.id)}
							bind:value={selectedProduct.barcodeFormat}
							on:change={() => updateProduct(selectedProduct)}
						>
							<svelte:fragment slot="append">
								<Button
									outline
									iconClass="gear"
									disabled={deleted || !canEditGlobalFields(selectedProduct.id)}
									on:click={() => selectedProduct && barcodeFormatModal?.open(selectedProduct.barcodeFormat)}
								></Button>
							</svelte:fragment>
						</Input>
						<Input
							label={translate('products.itemNumberLabel', 'Item Number')}
							title={translate('products.itemNumberTitle', 'Internal identifier of this form factor of ingredient')}
							disabled={deleted || !canEditGlobalFields(selectedProduct.id)}
							bind:value={selectedProduct.itemNumber}
							on:change={() => updateProduct(selectedProduct)}
						></Input>
						<Input
							label={translate('products.supplierItemNumberLabel', 'Supplier Item Number')}
							title={translate('products.supplierItemNumberTitle', 'The unique identifier of the item from the manufacturer')}
							disabled={deleted || !canEditGlobalFields(selectedProduct.id)}
							bind:value={selectedProduct.supplierItemNumber}
							on:change={() => updateProduct(selectedProduct)}
						></Input>
						<Input
							label={translate('products.unit', 'Unit')}
							title={translate('products.unitTitle', 'What type of unit this product is tracked in. A product source may need converted to this product unit')}
							disabled={deleted || !canEditGlobalFields(selectedProduct.id)}
							bind:value={selectedProduct.unit}
							on:change={() => updateProduct(selectedProduct)}
						></Input>
						<Input
							label={translate('products.unitConversion', 'Unit Conversion')}
							title={translate('products.unitConversionTitle', 'A multiplier/ratio to be applied to units from this source to convert to parent product units')}
							disabled={deleted || !canEditGlobalFields(selectedProduct.id)}
							type="number"
							bind:value={selectedProduct.unitConversion}
							on:change={() => updateProduct(selectedProduct)}
						></Input>
					</fieldset>
				</CollapsibleCard>
				<CollapsibleCard
					entireHeaderToggles
					bodyShown={expandedCard === 'Tags'}
					cardClass="{expandedCard === 'Tags' ? 'flex-grow-1' : ''} border-top-0"
					cardStyle="border-top-left-radius: 0px; border-top-right-radius: 0px;"
					bodyClass="d-flex flex-column"
					cardHeaderClass="card-header d-flex justify-content-between h5"
					on:show={() => (expandedCard = 'Tags')}
					on:hide={() => (expandedCard = 'Details')}
				>
					<svelte:fragment slot="cardHeader">
						<h5 class="card-title mb-2">{translate('product.tagsCardTitle', 'Tags')}</h5>
						{@const theTags = selectedProduct?.tags ?? []}
						<div
							class="align-self-end mr-auto ml-2"
							style="font-size: initial;"
						>
							{#each theTags as tag, index}
								<ExpandableBadge
									disabled
									class={index < theTags.length - 1 ? 'mr-1' : ''}
									text={tag.name}
								/>
							{/each}
						</div>
					</svelte:fragment>
					{#if selectedProduct?.tags}
						<TagSelection
							entityType="PRODUCT"
							tableParentClass="mh-60vh"
							includeCard={false}
							title={selectedProduct?.name ?? ''}
							disabled={!selectedProduct || !canEditChoice(plantId) || !canEditGlobalFields(selectedProduct?.id)}
							bind:tags
							bind:tagsInUse={selectedProduct.tags}
							bind:tagCrudStore
							on:tagsInUseAdd={({ detail: tag }) => (selectedProduct?.uuid ? tagAddRemoveStore.add(selectedProduct.uuid, tag.uuid) : null)}
							on:tagsInUseRemove={({ detail: tag }) => (selectedProduct?.uuid ? tagAddRemoveStore.remove(selectedProduct.uuid, tag.uuid) : null)}
							on:tagsInUseChange={() => updateProduct(selectedProduct)}
						/>
					{/if}
				</CollapsibleCard>
			</div>
		</div>
	{/if}
</div>
{#if selectedProduct}
	<ConfigureSpecificationCard
		{plants}
		{plantId}
		{analyses}
		{analysesById}
		{canEditChoice}
		{selectedProduct}
		{severityClasses}
		{specificationCrudStore}
		canEditProduct={!!selectedProduct && !productCrudStore.isDeleted(selectedProduct) && canEditGlobalFields(selectedProduct?.id)}
		bind:selectedSpecification
		bind:specificationCache
		bind:specifications={selectedProductSpecifications}
	/>
{/if}
<Modal
	closeShown={false}
	cancelShown={false}
	title={translate('product.attachmentsModalTitle', '{{- productName}} Attachments', { productName: selectedProduct?.name })}
	backdropClickCancels={false}
	modalSize="xxl"
	bind:show={showAttachmentsModal}
	on:confirm={() => (showAttachmentsModal = false)}
>
	{#if selectedProduct}
		<Attachments
			hidePublicFeatures
			hideRankFeatures
			uploadDisabled={!canEditGlobalFields(selectedProduct.id)}
			modificationDisabled={!canEditGlobalFields(selectedProduct.id)}
			fileList={selectedProduct.attachments}
			on:filesAdded={addFilesToSelectedProduct}
			on:filesDeleted={removeFilesFromSelectedProduct}
		/>
	{/if}
</Modal>

<ImageViewer
	title={translate('products.imageViewerTitle', '{{- productName}} Images', { productName: selectedProduct?.name })}
	files={selectedProductImages}
	bind:currentPhotoIndex
	bind:show={showImageViewer}
/>

<BarcodeFormatModal
	bind:this={barcodeFormatModal}
	{productsList}
	setBarcodeFormat={format => {
		if (selectedProduct) {
			selectedProduct.barcodeFormat = format
			updateProduct(selectedProduct)
		}
	}}
></BarcodeFormatModal>

<ProductBatchesModal
	{plants}
	{plantId}
	{analyses}
	{analysesById}
	{canEditChoice}
	{severityClasses}
	canEditProduct={!!selectedProduct && !productCrudStore.isDeleted(selectedProduct) && canEditGlobalFields(selectedProduct?.id)}
	bind:this={productBatchesModal}
	on:confirm={({ detail: { batchCrud, specificationCrud } }) => {
		$batchCrudStore = batchCrud
		$specificationCrudStore = specificationCrud
		if (selectedProduct) {
			productCrudStore.update(selectedProduct)
		}
	}}
></ProductBatchesModal>
