<script lang="ts">
	import type { SvelteAsr, DomApi, Template, Mediator, i18n, ManifestVersion } from 'types/common'
	import type { AbstractStateRouter } from 'abstract-state-router'
	import type { Colors } from '@isoftdata/utility-bootstrap'

	import Sidebar, { Activity, type ActivityInput, type SidebarItemType } from '@isoftdata/svelte-sidebar'
	import Alert from '@isoftdata/svelte-alert'
	import Button from '@isoftdata/svelte-button'
	import Icon from '@isoftdata/svelte-icon'

	import session from 'stores/session'
	import { getContext, onDestroy } from 'svelte'
	import setUserSetting from 'utility/set-user-setting'
	import { booleanToString } from '@isoftdata/utility-boolean'
	import { localWritable } from '@macfja/svelte-persistent-store'
	import { minToMs } from 'utility/to-milliseconds'
	import { isManifest } from 'utility/check-manifest'
	import { isValidTimeZone } from 'utility/timezone'

	export let asr: SvelteAsr
	export let states: Array<SidebarItemType>
	export let sidebarExpanded: boolean

	const stateRouter = getContext<AbstractStateRouter<Template, DomApi>>('stateRouter')
	const mediator = getContext<Mediator>('mediator')
	const i18next = getContext<i18n>('i18next')

	let alertIsShown = false
	let stateIsChanging = false
	let destinationStateName = ''
	let alert: Alert
	let sidebar: Sidebar
	let timeZoneIsValid = isValidTimeZone($session.plant.timezone)

	$: setUserSetting({
		category: 'Global',
		name: 'Collapse Presage Web sidebar',
		settingType: 'INTERFACE_HISTORY',
		newValue: booleanToString(!sidebarExpanded),
	})

	stateRouter.on('stateChangeStart', state => {
		destinationStateName = state.name
		stateIsChanging = true
	})

	stateRouter.on('stateChangeEnd', () => {
		stateIsChanging = false
		destinationStateName = ''
	})

	stateRouter.on('stateChangeError', err => {
		const stateName = destinationStateName
		stateIsChanging = false
		destinationStateName = ''
		mediator.call('showMessage', { type: 'danger', time: false, heading: `Error: State change to ${stateName} failed.`, message: err.message })
	})

	const removeHideMessageProvider = mediator.provide('hideMessage', () => {
		alert.hide()
	})

	const removeShowMessageProvider = mediator.provide(
		'showMessage',
		(options: { type?: Colors; color?: Colors; time?: false | number; heading: string; message?: string; dismissable?: boolean } = { heading: '' }) => {
			const { type, color, time, dismissable, ...otherOptions } = options
			// ractive alert called it "type" but now it's "color" so we should support both. Same with "time" being false or a number
			alert?.show({
				color: color || type || 'secondary',
				time: time === false ? 0 : time,
				...otherOptions,
				dismissable: dismissable !== false, //if they don't pass a value for dismissable, make sure we default it to true
			})
		},
	)
	const recentActivity = localWritable<Record<number, Array<Activity>>>('recentActivity', {})
	const removeActivityProvider = mediator.provide('activity', (activity: ActivityInput) => {
		try {
			if (!$recentActivity) {
				$recentActivity = {}
			}
			// The store's serializer chokes on the Activity class for some reason, so spread it into a plain object
			$recentActivity[$session.userAccountId] = sidebar.newActivity(activity).map(a => ({ ...a }))
		} catch (e) {
			// This should only happen if the value is messed up somehow, so reset it
			console.error('Error adding activity to recentActivity store', e)
			$recentActivity = {}
		}
	})

	$: activeState = getActiveState(asr.getActiveState())
	function getActiveState(state: ReturnType<typeof asr.getActiveState>) {
		return {
			name: state.name,
			route: state.name,
			parameters: state.parameters as Record<string, string | undefined>,
		}
	}

	const checkUpdateInterval = import.meta.env.DEV
		? null
		: setInterval(
				() => {
					void fetch('manifest.json', { cache: 'no-store' })
						.then(response => response.json())
						.then(data => {
							if (isManifest(data) && data.client.version !== '__buildVersion__') {
								mediator.call('showMessage', {
									color: 'warning',
									time: 0,
									heading: 'An Update to Presage is Available',
									message: 'RELOAD_BUTTON',
								})
								// Don't show more than once
								if (checkUpdateInterval) {
									clearInterval(checkUpdateInterval)
								}
							}
						})
				},
				minToMs(2), // Every 2 minutes
			)

	onDestroy(() => {
		removeActivityProvider()
		removeShowMessageProvider()
		removeHideMessageProvider()
		if (checkUpdateInterval) {
			clearInterval(checkUpdateInterval)
		}
	})
</script>

<Sidebar
	bind:this={sidebar}
	{activeState}
	buildRoute={asr.makePath}
	items={states}
	logoImageUrl="./images/presage_horizontal.svg"
	collapsedLogoImageUrl="./images/presage_white.svg"
	configurationItem={{ name: i18next.t('sidebar.configuration', 'Configuration'), icon: 'gear', route: 'app.configuration', parameters: {} }}
	userAccountItem={{
		route: 'app.configuration.user',
		parameters: {},
		userName: $session.userName,
		email: $session.user?.workEmail,
		firstName: $session.user?.firstName,
		lastName: $session.user?.lastName,
	}}
	bind:expanded={sidebarExpanded}
	recentActivity={Array.isArray($recentActivity?.[$session.userAccountId]) ? $recentActivity?.[$session.userAccountId] : []}
	on:logout={() => {
		asr.go('login')
	}}
>
	<div
		slot="top"
		class="px-2 d-flex justify-content-between border-bottom"
	>
		<p
			style="font-size: smaller;"
			class="mb-0 text-ellipsis text-muted font-italic w-50"
			title={`${$session.plant.code} - ${$session.plant.name}`}
		>
			{$session.plant.code}
		</p>
		<p
			style="font-size: smaller;"
			class="mb-0 text-ellipsis text-muted font-italic w-50 text-right"
			class:text-wrap={!timeZoneIsValid}
			title={timeZoneIsValid ? $session.plant.timezone : `Invalid Time Zone: ${$session.plant.timezone}`}
		>
			{#if timeZoneIsValid}
				{$session.plant.timezone}
			{:else}
				<span class="text-danger mr-1">Invalid Time Zone: <span class="text-nowrap">{$session.plant.timezone}</span></span>
			{/if}
		</p>
	</div>
	<uiView
		id="main-app"
		role="main"
		class="pl-0 pr-0"
	></uiView>
</Sidebar>

<div class="d-flex justify-content-center">
	<Alert
		bind:this={alert}
		bind:shown={alertIsShown}
		style="margin-bottom: .5rem; position:fixed; bottom: 0px; left: .5rem; box-shadow: 0 20px 40px -6px grey; z-index: {alertIsShown ? '1100' : '-1'};"
		class="mr-2"
		let:message
	>
		<div class="alert-padding">
			{#if message === 'RELOAD_BUTTON'}
				<Button
					outline
					color="secondary"
					style="overflow-wrap: break-word;"
					class="w-100"
					on:click={() => window.location.reload()}>Click Here to Update</Button
				>
			{:else}
				{#each message.split('\n') as line}
					<p>{line}</p>
				{/each}
			{/if}
		</div>
	</Alert>
</div>

{#if stateIsChanging}
	<div class="spinner">
		<Icon
			isLoading
			size="10x"
		/>
	</div>
{/if}

<style>
	div :global(.alert div.alert-padding p:not(:last-child)) {
		margin-bottom: 0.25rem !important;
	}
</style>
