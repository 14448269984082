import type { AppContext } from 'types/common'

import component from 'components/analysis-management/options/Options.svelte'
import { loadOptionsData } from 'utility/analysis-management-helper'

export default function ({ stateRouter }: AppContext) {
	stateRouter.addState({
		name: 'app.product-management.recipes.options',
		route: ':selectedAnalysisId/:selectedAnalysisUuid',
		defaultParameters: {
			selectedAnalysisId: null,
			selectedAnalysisUuid: null,
		},
		template: component,
		resolve(_, parameters) {
			return loadOptionsData(parameters)
		},
	})
}
