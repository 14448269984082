import component from './AlertSubscription.svelte'
import { AppContext, DefaultParameters, ResolveParameters } from 'types/common'
import { stringToBoolean } from '@isoftdata/utility-string'
import { getSession } from 'stores/session'
import { graphql, ALERT_SUBSCRIPTIONStore, UserAccounts$result } from '$houdini'
import { buildTranslatedHelpers, buildMap, locationsQuery } from 'utility/alert-subscription-helper'

import type { LocationData } from 'utility/alert-subscription-helper'

type AlertSubscriptionParameters = {
	showOnlyActive: string
	showOnlyMine: string
}

const gqlPagninationAllPages = {
	pagination: {
		pageSize: 0,
		pageNumber: 1,
	},
}

//#region GraphQL
const userAccountsQuery = graphql(`
	query UserAccounts($filter: UserFilter, $pagination: PaginatedInput) {
		userAccounts(filter: $filter, pagination: $pagination) {
			data {
				id
				name
				firstName
				lastName
				workEmail
				authorizedSites: authorizedPlants {
					id
				}
				userGroups {
					id
				}
			}
		}
	}
`)
//#endregion

export default ({ mediator, stateRouter, hasPermission, i18next }: AppContext) => {
	stateRouter.addState({
		name: 'app.configuration.alert-subscription',
		route: 'alert-subscription',
		querystringParameters: ['showOnlyMine', 'showOnlyActive'],
		defaultParameters: {
			showOnlyMine: (): string => `${!hasPermission('ALERT_SUBSCRIPTIONS_CAN_MANAGE_SUBSCRIPTIONS')}`,
			showOnlyActive: 'true',
		} satisfies DefaultParameters<AlertSubscriptionParameters>,
		template: component,
		async resolve(data, parameters: ResolveParameters<AlertSubscriptionParameters>) {
			const showOnlyActive = stringToBoolean(parameters.showOnlyActive)
			const showOnlyMine = stringToBoolean(parameters.showOnlyMine)
			const session = getSession()

			const { buildAlertSubscriptionForViewStateFromApi } = buildTranslatedHelpers(i18next.t)

			const permissions = {
				canOpenScreen: hasPermission('ALERT_SUBSCRIPTIONS_CAN_OPEN_SCREEN', session.siteId),
				canManageAlertSubscriptions: hasPermission('ALERT_SUBSCRIPTIONS_CAN_MANAGE_SUBSCRIPTIONS', session.siteId),
			}

			//Ensure that if they don't have permission to view everyone's alerts, they can't see them
			if (!showOnlyMine && !permissions.canManageAlertSubscriptions) {
				throw {
					redirectTo: {
						name: null,
						params: {
							...parameters,
							showOnlyMine: true,
						},
					},
				}
			}

			async function loadUserAccounts(): Promise<UserAccounts$result['userAccounts']['data']> {
				const { data } = await userAccountsQuery.fetch({
					variables: {
						filter: {
							activeOnly: true,
							authorizedPlants: {
								ids: session.authorizedPlantIDs,
							},
						},
						...gqlPagninationAllPages,
					},
				})

				return data?.userAccounts?.data ?? []
			}

			const userAccounts = await loadUserAccounts()

			const { data: alertSubscriptionData } = await new ALERT_SUBSCRIPTIONStore().fetch({
				variables: {
					...gqlPagninationAllPages,
					analysesFilter: {
						activeOnly: true,
					},
					alertSubscriptionFilter: {
						active: showOnlyActive ? true : null,
						userAccountIds: showOnlyMine ? [session.userAccountId] : userAccounts.map(userAccount => userAccount.id),
					},
					productFilter: {
						activeOnly: true,
					},
				},
			})

			const alertSubscriptions =
				alertSubscriptionData?.alertSubscriptions
					.filter(subscription => subscription.type !== 'SUPPLIER_DOCUMENT') //Currently This screen doesn't deal with Supplier Documents, so filter those out as early as possible
					.map(alertSubscriptionFromApi => {
						return buildAlertSubscriptionForViewStateFromApi(alertSubscriptionFromApi, alertSubscriptionData?.phoneCarriers ?? [], alertSubscriptionData?.alertTemplates ?? [])
					}) ?? []

			const plantIds = alertSubscriptions.reduce((acc: number[], alertSubscription): number[] => {
				if (alertSubscription.filters.plantId && !acc.includes(alertSubscription.filters.plantId)) {
					return [...acc, alertSubscription.filters.plantId]
				} else {
					return acc
				}
			}, [])

			let locations: LocationData[] = []
			if (plantIds.length) {
				const { data: locationData } = await locationsQuery.fetch({
					variables: {
						filter: {
							activeOnly: true,
							plantIds,
						},
						pagination: {
							pageSize: 0,
							pageNumber: 1,
						},
					},
					policy: 'CacheOrNetwork',
				})
				locations = locationData?.locations?.data ?? []
			}

			return {
				investigationRuleMap: buildMap(alertSubscriptionData?.investigationTriggers.data ?? [], 'id'),
				workOrderTypeMap: buildMap(alertSubscriptionData?.workOrderTypes?.data ?? [], 'id'),
				alertTemplateMap: buildMap(alertSubscriptionData?.alertTemplates ?? [], 'id'),
				scheduleMap: buildMap(alertSubscriptionData?.schedules.data ?? [], 'id'),
				analysisMap: buildMap(alertSubscriptionData?.analyses?.data ?? [], 'id'),
				productMap: buildMap(alertSubscriptionData?.products?.data ?? [], 'id'),
				groupMap: buildMap(alertSubscriptionData?.groups?.data ?? [], 'id'),
				plantMap: buildMap(alertSubscriptionData?.plants?.data ?? [], 'id'),
				investigationRules: alertSubscriptionData?.investigationTriggers?.data ?? [],
				workOrderTypes: alertSubscriptionData?.workOrderTypes?.data ?? [],
				alertTemplates: alertSubscriptionData?.alertTemplates ?? [],
				phoneCarriers: alertSubscriptionData?.phoneCarriers ?? [],
				schedules: alertSubscriptionData?.schedules.data ?? [],
				analyses: alertSubscriptionData?.analyses?.data ?? [],
				products: alertSubscriptionData?.products?.data ?? [],
				plants: alertSubscriptionData?.plants?.data ?? [],
				groups: alertSubscriptionData?.groups?.data ?? [],
				userAccountMap: buildMap(userAccounts, 'id'),
				locationsMap: buildMap(locations, 'id'),
				userAccounts: userAccounts ?? [],
				alertSubscriptions,
				showOnlyActive,
				showOnlyMine,
				permissions,
				session,
			}
		},
	})
}
