<script lang="ts">
	import type { i18n } from 'types/common'
	import type { SvelteAsr } from 'client/types/common'
	import { onMount, type ComponentProps } from 'svelte'
	import type { Reports$result } from '$houdini'
	import { graphql, GenerateCrystalReportStore } from '$houdini'
	import { ReportViewerPreview } from '@isoftdata/svelte-report-viewer'
	import Button from '@isoftdata/svelte-button'
	import ReportJobModal from 'components/ReportJobModal.svelte'
	import { getContext } from 'svelte'
	const { t: translate } = getContext<i18n>('i18next')

	export let asr: SvelteAsr
	export let selectedReportId: number | null
	export let reportList: Reports$result['reports']['data']
	export let parameters: any
	export let preview: boolean = false

	let reportViewerPreview: ReportViewerPreview
	let reportJobModal: ReportJobModal | undefined = undefined
	let allowPrint = false

	const generateCrystalReport: GenerateCrystalReportStore = graphql`
		mutation GenerateCrystalReport($reportJob: GenerateCrystalReportInput!) {
			generateCrystalReport(reportJob: $reportJob) {
				data
				mimeType
			}
		}
	`

	async function generatePdfPreview({ name, type, parameters }: Parameters<ComponentProps<ReportViewerPreview>['generatePdfPreview']>[0]) {
		allowPrint = false
		const { data } = await generateCrystalReport.mutate({
			reportJob: {
				name,
				parameters: Object.entries(parameters).map(([key, value]) => ({ key, value })),
				type,
				id: selectedReportId,
			},
		})
		if (!data) {
			throw new Error('No data returned from generateCrystalReport')
		}
		allowPrint = true
		return data.generateCrystalReport
	}

	function onPrintClick() {
		const report = reportList.find(r => r.id === selectedReportId)
		if (report && selectedReportId) {
			reportJobModal?.open(
				{
					name: report.name,
					type: report.type,
					reportId: selectedReportId,
					parameters: parameters.reduce((acc: Record<string, string>, param) => {
						acc[param.name] = param.value
						return acc
					}, {}),
				},
				{},
			)
		}
	}

	onMount(() => {
		if (preview) {
			reportViewerPreview.doPreview()
			asr.go(null, { preview: undefined }, { inherit: true })
		}
	})
</script>

{#if selectedReportId}
	<ReportViewerPreview
		bind:this={reportViewerPreview}
		bind:selectedReportId
		{generatePdfPreview}
		{parameters}
		{reportList}
	>
		<svelte:fragment slot="previewFooter">
			<Button
				outline
				size="sm"
				iconClass="print"
				disabled={!selectedReportId || !allowPrint}
				on:click={() => onPrintClick()}>{translate('common:print', 'Print')}</Button
			>
		</svelte:fragment>
	</ReportViewerPreview>
{/if}

<ReportJobModal
	showPreview={false}
	bind:this={reportJobModal}
/>
