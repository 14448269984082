<script
	lang="ts"
	generics="Item, ListKey extends string"
>
	import Select from '@isoftdata/svelte-select'
	import Input from '@isoftdata/svelte-input'
	import Button from '@isoftdata/svelte-button'

	import { v4 as uuid } from '@lukeed/uuid'
	import { watch } from 'runed'
	import type { ConditionalKeys, Promisable, ValueOf } from 'type-fest'
	import type { EntityOptGroupResult } from 'utility/grouped-entity-loader'
	import { getEventValue } from '@isoftdata/browser-event'
	import type { i18n } from 'i18next'
	import { getContext } from 'svelte'

	type ValueType = string | number | null
	type ItemKey = ConditionalKeys<Item, ValueType>

	type State = {
		enum: number
		icon: 'asterisk' | 'list'
		id: string
		title: string
	}

	type WildSelectProps = {
		options: Promisable<EntityOptGroupResult<Item>> | Promisable<Array<Item>>
		label?: string
		disabled?: boolean
		isLoading?: boolean
		labelProp?: ItemKey
		selectLabelProp?: ItemKey
		inputLabelProp?: ItemKey
		valueProp?: ItemKey
		selectValueProp?: ItemKey
		inputValueProp?: ItemKey
		useOptGroup?: boolean
		selectValue?: ValueType
		inputValue?: ValueType
		emptyText?: string
		emptyValue?: ValueType
		id?: string
	}

	type Option = {
		label: string
		value: string
	}

	type OptGroup = {
		optGroupLabel: string
		options: Option[]
	}

	let {
		options: maybePromiseOptions,
		labelProp,
		valueProp,
		isLoading = false,
		useOptGroup = false,
		id = uuid(),
		emptyValue = null,
		selectValue = $bindable(),
		inputValue = $bindable(),
		inputLabelProp,
		inputValueProp,
		selectLabelProp,
		selectValueProp,
		label = '',
		emptyText = `Select ${label}`,
		disabled = false,
	}: WildSelectProps = $props()

	const states = Object.freeze({
		select: {
			enum: 1,
			icon: 'asterisk' as const,
			id: `select-${id}`,
			title: 'Click to allow typing to wildcard search',
		} satisfies State,
		input: {
			enum: 2,
			icon: 'list' as const,
			id: `input-${id}`,
			title: 'Click to select from a list of options',
		} satisfies State,
	})
	const { t: translate } = getContext<i18n>('i18next')

	let options: EntityOptGroupResult<Item> | Array<Item> = $state(useOptGroup ? {} : [])
	// This is not a prop b/c of a bug with syncing value, selectValue, and inputValue that I don't feel like solving. Just use inputValue/selectValue
	let value = $state(emptyValue)
	let inputValueInternal: ValueType | null = $state(emptyValue)
	let selectValueInternal: ValueType | null = $state(emptyValue)
	let currentState: State = $state(states.select)

	const listId = $derived(`list-${id}`)
	const computedInputLabelProp = $derived(inputLabelProp || labelProp)
	const computedSelectLabelProp = $derived(selectLabelProp || labelProp)
	const computedInputValueProp = $derived(inputValueProp || valueProp)
	const computedSelectValueProp = $derived(selectValueProp || valueProp)
	const inputOptions: Record<string | number, ValueType> = $derived.by(() => {
		// make it an array if it's not already
		// It shouldn't matter that we're losing the keys since those are just for the optgroup
		const arrayOptions = options ? (options instanceof Array ? options : Object.values(options)) : []
		const labelProp = computedInputLabelProp
		const valueProp = computedInputValueProp

		if (!useOptGroup) {
			return (arrayOptions as Array<Item>).reduce((acc: Record<string | number, ValueType>, option) => {
				const label = (option as Record<ItemKey, any>)[labelProp!]
				const value = (option as Record<ItemKey, any>)[valueProp!]
				if (value) {
					acc[value] = label
				}
				return acc
			}, {})
		}
		// We can't use optgroups in a datalist, so just ignore them I guess
		return (arrayOptions as Array<ValueOf<EntityOptGroupResult<Item>>>).reduce((totalList: Record<string | number, ValueType>, group) => {
			return {
				...totalList,
				...group.options.reduce((acc: Record<string | number, ValueType>, option) => {
					if (valueProp && labelProp) {
						const value = option[valueProp] as ValueType
						const label = option[labelProp] as ValueType
						if (typeof value === 'string' || typeof value === 'number') {
							acc[value] = label
						}
					}
					return acc
				}, {}),
			}
		}, {})
	})
	const isSelect = $derived(currentState.enum === states.select.enum)
	const isInput = $derived(currentState.enum === states.input.enum)
	const selectOptions = $derived.by(() => {
		const labelProp = computedSelectLabelProp
		const valueProp = computedSelectValueProp

		// make it an array if it's not already
		// It shouldn't matter that we're losing the keys since those are just for the optgroup
		const arrayOptions = options ? (options instanceof Array ? options : Object.values(options)) : []

		if (!useOptGroup) {
			return (arrayOptions as Array<Item>).map(
				(option): Option => ({
					label: option[labelProp!] as string,
					value: option[valueProp!] as string,
				}),
			)
		}

		return (arrayOptions as Array<ValueOf<EntityOptGroupResult<Item>>>).map(
			(optGroup): OptGroup => ({
				optGroupLabel: optGroup.optGroupLabel,
				options: optGroup.options.map(opt => ({
					label: opt[labelProp!] as string,
					value: opt[valueProp!] as string,
				})),
			}),
		)
	})
	// Used to get the corresponding label/value for the current selection when switching states
	const allOptions: Array<Option> = $derived(
		useOptGroup
			? selectOptions.reduce((acc: Array<Option>, optGroupOrOption) => {
					if ('options' in optGroupOrOption) {
						return acc.concat(optGroupOrOption.options as Array<Option>)
					}
					return acc
				}, [])
			: (selectOptions as Array<Option>),
	)
	watch(
		() => maybePromiseOptions,
		newOptions => {
			if (newOptions instanceof Promise) {
				options = useOptGroup ? {} : []
				newOptions.then(resolvedOptions => {
					options = resolvedOptions
				})
			} else {
				options = newOptions as Awaited<EntityOptGroupResult<Item> | Array<Item>>
			}
		},
	)
	// Update selectValue/inputValue when value changes
	watch(
		() => value,
		currentValue => {
			const setSelectValue = isSelect && (currentValue === emptyValue || allOptions.some(({ value, label }) => currentValue === value || currentValue === label))
			if (setSelectValue) {
				selectValue = currentValue ?? emptyValue
			} else {
				inputValue = currentValue ?? emptyValue
			}
		},
		{
			lazy: true,
		},
	)
	function isValidValue(val: ValueType | undefined): val is ValueType {
		return val !== emptyValue && (!!val || val === '' || val === 0)
	}
	// update selectValueInternal/inputValueInternal and currentState when selectValue/inputValue changes
	watch(
		[() => selectValue, () => inputValue],
		([selectVal, inputVal], [prevSelectVal, prevInputVal]) => {
			const selectChanged = (selectVal ?? emptyValue) !== (prevSelectVal ?? emptyValue)
			const inputChanged = (inputVal ?? emptyValue) !== (prevInputVal ?? emptyValue)
			// This never came up in testing, but I'll leave it here just in case
			if (selectChanged && isValidValue(selectVal) && inputChanged && isValidValue(inputVal)) {
				console.error('Both select and input changed at the same time, this should not happen', { selectVal, inputVal, prevSelectVal, prevInputVal })
			}

			selectValueInternal = selectVal ?? emptyValue
			inputValueInternal = inputVal ?? emptyValue

			// If we're passed a new value for a certain state, change to that state so it shows in the UI
			if (selectChanged && isValidValue(selectVal)) {
				currentState = states.select
			} else if (inputChanged && isValidValue(inputVal)) {
				currentState = states.input
			}
		},
		{
			lazy: true,
		},
	)

	function stateChange() {
		const prevValue = value

		if (isSelect) {
			currentState = states.input
			inputValue = (allOptions.find(option => option.value === prevValue)?.label as ValueType) ?? emptyValue
			selectValue = emptyValue
			value = inputValue
		} else {
			currentState = states.select
			selectValue = (allOptions.find(option => option.label === prevValue)?.value as ValueType) ?? emptyValue
			inputValue = emptyValue
			value = selectValue
		}

		document.getElementById(CSS.escape(currentState.id))?.focus()
	}
</script>

<!--
@component
	An abomination, but I didn't want to rethink every input on the SH search page before getting it to work,
	so this is a straight port of the Ractive version. There are TypeScript sins within, but I just wanted to get it working so it can be replaced later.

	Therefore, please don't use this anywhere else.

	Original Discription:

	The goal of this component is to function as an Select in most instances,
	but still give you the ability to fuzzy search with wildcard by turning into an Input with a datalist.

	The 'options' attribute should be an object, or an array of objects.
 -->

{#if isSelect}
	<Select
		{label}
		{isLoading}
		id={states.select.id}
		disabled={disabled || isLoading}
		{emptyValue}
		{emptyText}
		bind:value={selectValueInternal}
		on:change={() => {
			selectValue = selectValueInternal ?? emptyValue
			value = selectValue
		}}
	>
		<svelte:fragment slot="append">
			{@render switchButton()}
		</svelte:fragment>
		{#if useOptGroup}
			{#each selectOptions as Array<OptGroup> as { optGroupLabel, options }}
				<optgroup label={optGroupLabel}>
					{#each options as { value, label }}
						<option {value}>{label}</option>
					{/each}
				</optgroup>
			{/each}
		{:else}
			{#each selectOptions as Array<Option> as { value, label }}
				<option {value}>{label}</option>
			{/each}
		{/if}
	</Select>
{:else if isInput}
	<Input
		id={states.input.id}
		list={listId}
		value={inputValueInternal}
		{isLoading}
		{disabled}
		placeholder={translate('wildSelect.inputPlaceholder', 'Enter a {{- label}} name, or use * to wildcard', { label })}
		{label}
		on:change={event => {
			inputValueInternal = getEventValue(event)
			inputValue = inputValueInternal ?? emptyValue
			value = inputValue
		}}
	>
		<svelte:fragment slot="append">
			{@render switchButton()}
		</svelte:fragment>
	</Input>
	<datalist id={listId}>
		{#each Object.entries(inputOptions) as [value, label]}
			<option {value}>{label}</option>
		{/each}
	</datalist>
{/if}

{#snippet switchButton()}
	<Button
		outline
		size="sm"
		{disabled}
		iconClass={currentState.icon}
		on:click={stateChange}
		title={currentState.title}
	></Button>
{/snippet}
